import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CreateClaimIconService {
  render() {
    return `
    <svg width="35" height="39" viewBox="0 0 35 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.8379 25.3709L20.3015 24.8359H19.3457L19.8379 25.3709Z" fill="white"/>
<path d="M2.16211 1.68945V37.3195H22.2847V32.2407C22.2847 32.0196 22.3774 31.8056 22.5272 31.6558C22.6842 31.4989 22.8981 31.4133 23.1121 31.4133H28.4121V20.1714L20.9865 28.6099C20.7011 28.9309 20.3017 29.1164 19.8594 29.1164C19.4386 29.1164 19.032 28.9381 18.7538 28.6313L15.2728 24.8365H5.21509V22.3684H22.4416V22.4184L23.9111 20.7492H5.21509V18.2811H25.2949V19.1799L28.4121 15.6347V1.68945H2.16211ZM25.2878 16.6477H5.21509V14.1796H25.2949V16.6477H25.2878ZM25.2878 12.5532H8.13968V10.0852H25.2878V12.5532Z" fill="white"/>
<path d="M28.412 31.406H23.1192C22.8981 31.406 22.6841 31.4916 22.5343 31.6486C22.3773 31.8055 22.2917 32.0195 22.2917 32.2335V37.3123H2.16202V1.68936H28.412V15.6275L30.074 13.7372V0.854789C30.074 0.633661 29.9884 0.419666 29.8315 0.26987C29.6745 0.112941 29.4605 0.0273438 29.2465 0.0273438H1.32744C1.10632 0.0273438 0.892322 0.112941 0.742526 0.26987C0.585597 0.426799 0.5 0.640794 0.5 0.854789V38.1469C0.5 38.368 0.585597 38.582 0.742526 38.7318C0.899455 38.8887 1.11345 38.9743 1.32744 38.9743H24.1535C24.3746 38.9743 24.5815 38.8887 24.7384 38.7318L27.3063 36.1852L29.8243 33.653C29.9813 33.496 30.0669 33.282 30.0669 33.0681V18.2811L28.412 20.1713V31.406ZM23.9466 37.3479V33.0681H28.3763L23.9466 37.3479Z" fill="#274BDB"/>
<path d="M23.9463 37.3482L28.376 33.0684H23.9463V37.3482Z" fill="white"/>
<path d="M25.2877 10.084H8.13965V12.5521H25.2877V10.084Z" fill="#16C072"/>
<path d="M25.2946 14.1797H5.21484V16.6478H25.2946V14.1797Z" fill="#16C072"/>
<path d="M5.21484 18.2734V20.7415H23.9037L25.2875 19.1722V18.2734H5.21484Z" fill="#16C072"/>
<path d="M5.21484 22.3672V24.8353H15.2726L14.6734 24.1861L13.9315 23.3801C13.8103 23.2446 13.7889 23.0448 13.8816 22.8879C13.9601 22.7595 14.0956 22.6882 14.2383 22.6882L14.3381 22.7024L18.1544 23.644C18.2328 23.6654 18.3042 23.7082 18.3612 23.7653L19.3456 24.8424H20.3014L22.4342 22.4171V22.3672H5.21484Z" fill="#16C072"/>
<path d="M34.361 12.7823L32.7275 11.3414C32.649 11.2772 32.5563 11.2344 32.4564 11.2344H32.4279C32.3209 11.2415 32.2139 11.2914 32.1426 11.377L30.074 13.731L28.4119 15.6284L25.2947 19.1736L23.9109 20.7429L22.4415 22.412L20.3087 24.8373L19.8379 25.3723L19.3457 24.8373L18.3613 23.7602C18.3043 23.7031 18.2329 23.6603 18.1545 23.6389L14.3382 22.6973L14.2384 22.6831C14.0957 22.6831 13.9602 22.7615 13.8817 22.8828C13.789 23.0397 13.8104 23.2395 13.9317 23.375L14.6664 24.1739L15.2656 24.823L18.7465 28.6178C19.0318 28.9246 19.4313 29.1029 19.8522 29.1029C20.2944 29.0958 20.701 28.9103 20.9792 28.5964L28.4119 20.1722L30.074 18.2819L34.3966 13.3672C34.5464 13.196 34.5322 12.9321 34.361 12.7823Z" fill="#274BDB"/>
</svg>`;
  }
}
