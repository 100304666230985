import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class CloseIconService {

   render() {
    return `<svg width="14" height="14" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.8095 5.14111L9.91106 0.252051C9.97981 0.170801 9.922 0.0473633 9.81575 0.0473633H8.56888C8.49544 0.0473633 8.42512 0.0801757 8.37669 0.136426L4.99388 4.16924L1.61106 0.136426C1.56419 0.0801757 1.49388 0.0473633 1.41888 0.0473633H0.172C0.0657503 0.0473633 0.00793765 0.170801 0.0766877 0.252051L4.17825 5.14111L0.0766877 10.0302C0.061287 10.0483 0.0514069 10.0704 0.04822 10.094C0.0450331 10.1176 0.0486732 10.1415 0.0587085 10.1631C0.0687439 10.1846 0.0847529 10.2029 0.104835 10.2156C0.124916 10.2283 0.148227 10.235 0.172 10.2349H1.41888C1.49231 10.2349 1.56263 10.2021 1.61106 10.1458L4.99388 6.11299L8.37669 10.1458C8.42356 10.2021 8.49388 10.2349 8.56888 10.2349H9.81575C9.922 10.2349 9.97981 10.1114 9.91106 10.0302L5.8095 5.14111Z" fill="#DB2838"/>
</svg>`;
  }
}
