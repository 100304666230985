import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class DeleteListIconService {

    render() {
        return `<svg width="17" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.76172 2.23633H4.60547C4.69141 2.23633 4.76172 2.16602 4.76172 2.08008V2.23633H10.6992V2.08008C10.6992 2.16602 10.7695 2.23633 10.8555 2.23633H10.6992V3.64258H12.1055V2.08008C12.1055 1.39062 11.5449 0.830078 10.8555 0.830078H4.60547C3.91602 0.830078 3.35547 1.39062 3.35547 2.08008V3.64258H4.76172V2.23633ZM14.6055 3.64258H0.855469C0.509766 3.64258 0.230469 3.92188 0.230469 4.26758V4.89258C0.230469 4.97852 0.300781 5.04883 0.386719 5.04883H1.56641L2.04883 15.2637C2.08008 15.9297 2.63086 16.4551 3.29688 16.4551H12.1641C12.832 16.4551 13.3809 15.9316 13.4121 15.2637L13.8945 5.04883H15.0742C15.1602 5.04883 15.2305 4.97852 15.2305 4.89258V4.26758C15.2305 3.92188 14.9512 3.64258 14.6055 3.64258ZM12.0137 15.0488H3.44727L2.97461 5.04883H12.4863L12.0137 15.0488Z" fill="#777777"/>
</svg>`;
    }
}
