import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WarrantyClaimService } from './warranty-claim.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class S3UploadService {
  constructor(private http: HttpClient , private warrantyClaimService: WarrantyClaimService) {}

    getSignedUrl(fileName: string, fileType: string, claimId, isDownload){  
    return this.warrantyClaimService.postS3Bucket({ fileName, fileType , claimId, isDownload}) ;
  }

   uploadFile(url: string, file: File): Observable<any> {
     // Create a new observable from the fetch API
    return new Observable(observer => {
      const formData = new FormData();
      formData.append('file', file);

      fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Upload failed');
        }
        return response.text();
      })
      .then(data => {
        observer.next(data);
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
      });
    });
  }

  getFiles(url: string, file: File): Observable<any>{
    return new Observable(observer => {
      const formData = new FormData();
      formData.append('file', file);
      fetch(url, {
        method: 'GET',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Upload failed');
        }
        return response.text();
      })
      .then(data => {
        observer.next(data);
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
      });
    });
  }
}
