export const WARRANTY_PERMISSIONS = {
    // view
    getBYDWarrantyJobOrders: "getBYDWarrantyJobOrders",
    getBYDWarrantyOrderDetails: "getBYDWarrantyOrderDetails",
    getBYDWarrantyVehicleDetailsByRegoVin: "getBYDWarrantyVehicleDetailsByRegoVin",
    getBYDWarrantyBookingDetails: "getBYDWarrantyBookingDetails",
    getBYDWarrantyBookings: "getBYDWarrantyBookings",
    getBYDWarrantyLabourHrsUnitPrice: "getBYDWarrantyLabourHrsUnitPrice",
    getBYDWarrantySparePart: "getBYDWarrantySparePart",
    getBYDWarrantyOtherExpensesDetails: "getBYDWarrantyOtherExpensesDetails",
    getBYDWarrantyWarrantyClaims: "getBYDWarrantyWarrantyClaims",
    getBYDWarrantyWarrantyClaim: "getBYDWarrantyWarrantyClaim",
    getBYDWarrantyWarrantyClaimReports: "getBYDWarrantyWarrantyClaimReports",
    getBYDWarrantyWarrantyClaimSettlementList: "getBYDWarrantyWarrantyClaimSettlementList",

    // create
    createBYDWarrantyVehicleSeries: "createBYDWarrantyVehicleSeries",
    createBYDWarrantyJobOrder: "createBYDWarrantyJobOrder",
    createBYDWarrantyWarrantyGetSignature: "createBYDWarrantyWarrantyGetSignature",
    createBYDWarrantyWarrantyServiceClaimAttchament: "createBYDWarrantyWarrantyServiceClaimAttchament",
    createBYDWarrantyServiceClaim: "createBYDWarrantyServiceClaim",
    createBYDWarrantyUploadAttachment: "createBYDWarrantyUploadAttachment",
    createBYDWarrantyWarrantyClaim: "createBYDWarrantyWarrantyClaim",

    // update
    updateBYDWarrantyJobOrder: "updateBYDWarrantyJobOrder",
    updateBYDWarrantyWarrantyClaim: "updateBYDWarrantyWarrantyClaim",

    // delete
    removeBYDWarrantyJobOrder: "removeBYDWarrantyJobOrder",
    removeBYDWarrantyWarrantyClaim: "removeBYDWarrantyWarrantyClaim",
    removeBYDWarrantyWarrantyClaimAttachment: "removeBYDWarrantyWarrantyClaimAttachment",

}