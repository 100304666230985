import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CloseDisableIconService {

    render() {
        return `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.2108 10.1411L16.0814 4.33535C16.163 4.23887 16.0944 4.09229 15.9682 4.09229H14.4876C14.4003 4.09229 14.3168 4.13125 14.2593 4.19805L10.2422 8.98701L6.22515 4.19805C6.16948 4.13125 6.08599 4.09229 5.99693 4.09229H4.51626C4.39009 4.09229 4.32144 4.23887 4.40308 4.33535L9.27368 10.1411L4.40308 15.9469C4.38479 15.9684 4.37306 15.9947 4.36927 16.0227C4.36549 16.0506 4.36981 16.0791 4.38173 16.1047C4.39365 16.1303 4.41266 16.1519 4.4365 16.167C4.46035 16.1821 4.48803 16.1901 4.51626 16.1899H5.99693C6.08413 16.1899 6.16763 16.151 6.22515 16.0842L10.2422 11.2952L14.2593 16.0842C14.315 16.151 14.3985 16.1899 14.4876 16.1899H15.9682C16.0944 16.1899 16.163 16.0434 16.0814 15.9469L11.2108 10.1411Z" fill="#9E9E9E"/>
</svg>`;
    }
}