import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SortArrowServiceIconService {

  render() {
    return `<svg width="17" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.25837 6.91359L11.1357 8.9995C11.4253 9.32126 11.1969 9.83398 10.764 9.83398L7.0094 9.83398C6.57651 9.83398 6.34817 9.32126 6.63775 8.9995L8.51507 6.91359C8.71369 6.6929 9.05974 6.6929 9.25837 6.91359Z" fill="#274BDB"/>
<path d="M8.51507 18.0864L6.63775 16.0005C6.34817 15.6787 6.57651 15.166 7.0094 15.166H10.764C11.1969 15.166 11.4253 15.6787 11.1357 16.0005L9.25837 18.0864C9.05974 18.3071 8.71369 18.3071 8.51507 18.0864Z" fill="#274BDB"/>
</svg>
    `;
  }
}