import {
  Component,
  Input,
  Output,
  OnInit,
  Renderer2,
  EventEmitter,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { NbSidebarService, NbStepperComponent  } from "@nebular/theme";
import { UserQuery } from "../../../@core/akita-stores/stores/user/user.query";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { StepperDataService } from "../../../@core/utils/stepper-data.service";

@Component({
  selector: "ngx-manage-job_order-layout",
  styleUrls: ["./manage-job_order.layout.scss"],
  template: `
    <nb-card>
      <nb-card-body>
        <span class="headingTitle">
        <nb-icon (click)="backToDashboard()" style="cursor: pointer;"
         class="mr-1" icon="arrow-left" pack="mo-icons"></nb-icon>
         {{ isJobOrderEditable==true ? 'Create' : 'Edit' }} Job Order
          <hr />  
        </span>

        <nb-stepper
          orientation="horizontal"
          [linear]="true"
          id="job-order-stepper"
          #stepper
        >
          <nb-step
            [stepControl]="isVehicleAvailable"
            label="Vehicle Details"
          >
            <ng-template #labelOne>First step</ng-template>
            <vehicle-details #stepOne (isVehicleAvailable)=setisVehicleAvailable($event) ></vehicle-details>
          </nb-step>

          <nb-step
            [stepControl]="isOperationCodeAvailable"
            label="Operation Code Information"
          >
            <ng-template #labelTwo>Second step</ng-template>
            <job-operation-code-info #stepTwo (isOperationCodeAvailable)="setisOperationCodeAvailable($event)"> </job-operation-code-info>
          </nb-step>

          <nb-step
            [stepControl]="isJobSparePartsAvailable"
            label="Spare Parts Information"
          >
            <ng-template #labelThree>Third step</ng-template>
            <job-order-spareparts-code #stepThree (isJobSparePartsAvailable)="setisJobSparePartsAvailable($event)"> </job-order-spareparts-code>
          </nb-step>

          <nb-step
            [stepControl]="isSundryInfoAvailable"
            label="Sundry Information"
          >
            <ng-template #labelFour>Fourth step</ng-template>
            <sundry-info #stepFive  (isSundryAvailable)="setIsSundryAvailable($event)"></sundry-info>
          </nb-step>
      
        </nb-stepper>
      </nb-card-body>
    </nb-card>
  `,
})
export class ManageJobOrderComponent implements OnInit, OnDestroy {
 @ViewChild('stepper', { static: false }) stepper: NbStepperComponent;
  isLogin$ = this.userQuery.isLogin$;
  public isSparePartAvailable: boolean = true;
  public isSundryInfoAvailable: boolean = true;
  public isOperationCodeAvailable : boolean = true;
  public isJobSparePartsAvailable :boolean = true;
  public isVehicleAvailable :boolean = true;
  public isJobOrderEditable :boolean = true;
  stepIndexValue : any;
  activeStepStr: string;

  constructor(
    private sidebarService: NbSidebarService,
    private renderer: Renderer2,
    private userQuery: UserQuery,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activateRoute:ActivatedRoute,
    private shareStepperData: StepperDataService
  ) {}

  ngOnInit() {
  this.activateRoute.queryParams.subscribe((params) => {
    this.isJobOrderEditable=params['isJobOrderEditable']? params['isJobOrderEditable']: true;
    this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {}

  finish() {
    this.router.navigate(["/"]);
  }

  backToDashboard(){
    if((this.isVehicleAvailable == false || this.isVehicleAvailable == true) && parseInt(localStorage.getItem("JobOrderCurrentStep")) == 0)
      {
         let selctionTab 
       if(this.isJobOrderEditable == true){
        selctionTab = "bookingListTab"
       }else{
        selctionTab = "jobOrderListTab"
       }
      this.shareStepperData.setActiveTab(selctionTab);
      this.router.navigate(['/pages/warranty/warranty-job-order'])
    }
    
    if(parseInt(localStorage.getItem("JobOrderCurrentStep")) > 0){
      this.stepper.previous();
       this.stepIndexValue =this.stepper.selectedIndex
    localStorage.setItem("JobOrderCurrentStep", this.stepIndexValue);
    }
  }

  toggle() {
    this.sidebarService.toggle(true, "sidebar-bookings");
  }

  setisVehicleAvailable(value): void{
    this.isVehicleAvailable = value;
    this.cdr.detectChanges();
    if(this.isVehicleAvailable == false){
      this.stepper.next()
      this.saveCurrentStep()
    }
}
  setIsSparePartsAvailable(value): void {
    this.isSparePartAvailable = value;
    this.cdr.detectChanges();
    if(this.isSparePartAvailable == false){
      this.stepper.next()
      this.saveCurrentStep()
    }
  }

  setisJobSparePartsAvailable(value) : void{
     this.isJobSparePartsAvailable = value;
     this.cdr.detectChanges();
     if(this.isJobSparePartsAvailable == false){
      this.stepper.next()
      this.saveCurrentStep()
     }
  }

  setisOperationCodeAvailable(value){
    this.isOperationCodeAvailable = value;
    this.cdr.detectChanges();
    if(this.isOperationCodeAvailable == false){
      this.stepper.next()
      this.saveCurrentStep()
    }
  }

  setIsSundryAvailable(value){
    this.isSundryInfoAvailable = value;
    this.cdr.detectChanges();
    if(this.isSundryInfoAvailable == false){
      this.stepper.next()
      this.saveCurrentStep()
    }
  }

  saveCurrentStep() {
     this.stepIndexValue =this.stepper.selectedIndex
    localStorage.setItem("JobOrderCurrentStep", this.stepIndexValue);
  }
}