import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EditFormIconService {
  render() {
    return `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M3.16699 3.59945C2.95703 3.59945 2.75567 3.68285 2.6072 3.83132C2.45873 3.97979 2.37533 4.18115 2.37533 4.39111V15.4744C2.37533 15.6844 2.45873 15.8858 2.6072 16.0342C2.75567 16.1827 2.95703 16.2661 3.16699 16.2661H14.2503C14.4603 16.2661 14.6617 16.1827 14.8101 16.0342C14.9586 15.8858 15.042 15.6844 15.042 15.4744V11.2469C15.042 10.8097 15.3964 10.4553 15.8337 10.4553C16.2709 10.4553 16.6253 10.8097 16.6253 11.2469V15.4744C16.6253 16.1043 16.3751 16.7084 15.9297 17.1538C15.4843 17.5992 14.8802 17.8494 14.2503 17.8494H3.16699C2.5371 17.8494 1.93301 17.5992 1.48761 17.1538C1.04221 16.7084 0.791992 16.1043 0.791992 15.4744V4.39111C0.791992 3.76122 1.04221 3.15713 1.48761 2.71173C1.93301 2.26634 2.5371 2.01611 3.16699 2.01611H7.39449C7.83172 2.01611 8.18616 2.37055 8.18616 2.80778C8.18616 3.24501 7.83172 3.59945 7.39449 3.59945H3.16699Z" fill="url(#paint0_linear_498_21979)"/>
           <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6905 0.664491C13.9997 0.355326 14.501 0.355326 14.8101 0.664491L17.9768 3.83116C18.286 4.14032 18.286 4.64158 17.9768 4.95074L10.0601 12.8674C9.91165 13.0159 9.71029 13.0993 9.50033 13.0993H6.33366C5.89643 13.0993 5.54199 12.7448 5.54199 12.3076V9.14095C5.54199 8.93099 5.6254 8.72962 5.77387 8.58116L13.6905 0.664491ZM7.12533 9.46887V11.516H9.17241L16.2974 4.39095L14.2503 2.34387L7.12533 9.46887Z" fill="url(#paint1_linear_498_21979)"/>
           <defs>
           <linearGradient id="paint0_linear_498_21979" x1="0.791992" y1="9.93278" x2="16.6253" y2="9.93278" gradientUnits="userSpaceOnUse">
           <stop stop-color="#DE2C40"/>
           <stop offset="0.479167" stop-color="#DE142A"/>
           <stop offset="1" stop-color="#AB1021"/>
           </linearGradient>
           <linearGradient id="paint1_linear_498_21979" x1="5.54199" y1="6.76595" x2="18.2087" y2="6.76595" gradientUnits="userSpaceOnUse">
           <stop stop-color="#DE2C40"/>
           <stop offset="0.479167" stop-color="#DE142A"/>
           <stop offset="1" stop-color="#AB1021"/>
           </linearGradient>
           </defs> </svg>`;
  }
}
