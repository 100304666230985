import { Component, EventEmitter, OnInit, Optional, Output, TemplateRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NbDialogRef, NbDialogService, NbTabsetComponent } from '@nebular/theme';
import { WarrantyClaimService } from '../../../@core/akita-stores/stores/warranty-claim/warranty-claim.service';
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { async } from "rxjs/internal/scheduler/async";
import { StepperDataService } from "../../../@core/utils/stepper-data.service";
import { take } from "rxjs/operators";
import { ConfigService } from "../../../@core/akita-stores/stores/config/config.service";

interface syndryType {
  code: number;
  otherExpenses: any;
  amount: number;
  codeId:number;
  inUse:boolean;
  id:number;
  expensesCategories:any;
  otherFeeAmount:number;
}

@Component({
  selector: "sundry-info",
  templateUrl: "./sundry-info.component.html",
  styleUrls: ["./sundry-info.component.scss"],
})
export class SundryInfoComponent implements OnInit {
  expenseEditID: number = 0;
  stepFiveForm: FormGroup;
  addButton :boolean = false
  isEdit: boolean = false;
  sundryList: boolean = false
  codeName: number ;
  displayedColumns: string[] = ["tableRowNumber","code", "otherExpenses", "sundryAmountDetails", "sundryAction"];
  dataSource: syndryType[] = [];
  otherExpensInfo: string[] = [];
  otherExpenseFilter;
  isEditSundry:boolean=false;
  isSundry:boolean=false;
  @ViewChild('discardDialog', { static: false })  discardDialog: TemplateRef<any>;
  @ViewChild('discardDialogjobOrder', { static: false })  discardDialogjobOrder: TemplateRef<any>;
  @ViewChild('discardDialogJobOrderForm', { static: false })  discardDialogJobOrderForm: TemplateRef<any>;
  @ViewChild('discardDialogJobOrderUpdateForm', { static: false })  discardDialogJobOrderUpdateForm: TemplateRef<any>;
  @Output() isSundryAvailable = new EventEmitter<boolean>();
  vehicleDetails: any;
  jobOperationDetails: any;
  jobOrderDetails: any;
  jobOrderSpareDetails: any;
  jobOrder:any;
  jobOrdersVehicleDetail:any;
  jobOrdersOperation:any;
  jobOrdersSparePart:any;
  codeId:number;
  jobOrderId:number;
  disabledRow;
  jobOrdersExpenses=[];
  @ViewChild('tabset', { static: false }) tabset: NbTabsetComponent;
  isCreatedClaim:boolean=false;
  selectedJobOrderID:number;
  inUse:boolean=false;
  isJobOrderEditable:boolean=true;

  constructor(private fb: FormBuilder,@Optional() private dialogRef:NbDialogRef<any>, private dialogService:NbDialogService, private warrantySundryCodeService : WarrantyClaimService,
  private router:Router, private snackBar: MatSnackBar,private shareDataService:StepperDataService, private shareStepperData: StepperDataService,private activateRoute: ActivatedRoute,
  private configService: ConfigService) {
    this.stepFiveForm = new FormGroup({
      expenseInfo: new FormControl(""),
      codeValue: new FormControl(""),
      sundryAmount: new FormControl(""),
    });
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
      this.isJobOrderEditable=params['isJobOrderEditable'];
    });
    this.warrantySundryCodeService.getSundryCodeInfo().pipe().subscribe((x:any)=>{
    this.otherExpensInfo =  x.results
    this.otherExpenseFilter = this.otherExpensInfo.slice();
   })

   this.shareDataService.stepperFormValueShare.subscribe((res)=>{
      this.vehicleDetails = res.vehicleDetailsFormValue;
      this.jobOrder=res.jobOrder;
      this.jobOrdersVehicleDetail=res.jobOrdersVehicleDetail;
      this.jobOrdersOperation=res.jobOrdersOperation;
      this.jobOrdersSparePart=res.jobOrdersSparePart;
      if(this.jobOrder.jobOrderId){
        this.getJobOrderById(this.jobOrder.jobOrderId);
        this.selectedJobOrderID = this.jobOrder.jobOrderId
      }
  })

  }

  getJobOrderById(id) {
    this.warrantySundryCodeService.getClaimDetailsById(id).pipe().subscribe((res:any)=>{
      this.dataSource = res.jobOrdersExpensesDetails;
   })
  }

  addSundryInfo() {
    if(this.stepFiveForm.valid){ 
    const sundryInfo = {
       otherExpenses: this.otherExpenseFilter.find(((record: any)=> {
        return record.id == this.stepFiveForm.get('expenseInfo').value;
      })),
      code: this.stepFiveForm.get("codeValue").value,
      amount: this.stepFiveForm.get("sundryAmount").value,
      codeId: this.codeId,
      inUse:false,
      id:null,
      expensesCategories:null,
      otherFeeAmount:null,
    }

     if (this.isEdit == true ) {
       this.dataSource[this.expenseEditID] = sundryInfo
      this.expenseEditID = 0 
      this.isEdit = false
      this.isEditSundry=false;
      this.isSundry=false;
      this.showSuccess('Sundry Information Updated Successfully', "\u2705");
    }
    else{
        this.dataSource.push(sundryInfo);
    }
    this.dataSource = [...this.dataSource];
    this.isEdit = false;
    this.stepFiveForm.get("expenseInfo").clearValidators();
    this.stepFiveForm.get("sundryAmount").clearValidators();
    this.stepFiveForm.reset();
    }
  }
  
  editExpenses(index,elements) { 
    this.isEditSundry=true;
    this.isSundry=true;
    this.expenseEditID = index
    
    const element = this.dataSource[index];
     this.isEdit = true;
     if(element.otherExpenses){
    this.stepFiveForm.get('expenseInfo').patchValue(element.otherExpenses.id)
    this.stepFiveForm.get('codeValue').patchValue(element.code)
    this.stepFiveForm.get('sundryAmount').patchValue(element.amount)
    this.disabledRow = elements;
     }else{
    this.stepFiveForm.get('expenseInfo').patchValue(element.expensesCategories.id)
    this.stepFiveForm.get('codeValue').patchValue(element.expensesCategories.code)
    this.stepFiveForm.get('sundryAmount').patchValue(element.otherFeeAmount)
    this.disabledRow = elements;
     }

  }

  deleteExpenses(i,element) {
    this.dialogRef = this.dialogService.open(this.discardDialog);
    this.codeName = element.code
     this.dialogRef.onClose.subscribe(result => {
     if(result === 'done' ){
      this.dataSource.splice(i, 1);
       this.dataSource = [...this.dataSource];
       this.isEdit = false;
        this.showSuccess('Sundry Information Deleted Successfully', "\u2705");
     }
   })
  }
  
   isRowDisabled(row:syndryType): boolean {
    return this.disabledRow === row;
  }

  onSelect(event){
   
    this.stepFiveForm.get("codeValue").patchValue(this.otherExpenseFilter.find((record: any)=> {
        return record.id ==event.value;
      }).code)
    this.codeId=event.value
    if(event.value === null && event.value === undefined){
        this.addButton = false
    }else{
      this.addButton = true
    }
  }

  isFormValid(): boolean {
    const sundryInfo = {
       otherExpenses: this.stepFiveForm.get("expenseInfo").value,
       amount: this.stepFiveForm.get("sundryAmount").value,
    };

    return (sundryInfo.otherExpenses && sundryInfo.amount) ? false : true;
  }

  numberOnly(event: KeyboardEvent): boolean {
  const allowedKeys = [ 8, 9, 13, 37, 38, 39, 40, 46, 110, 190 ];
  if (
    (event.keyCode >= 48 && event.keyCode <= 57) ||  
    (event.keyCode >= 96 && event.keyCode <= 105) ||
    allowedKeys.includes(event.keyCode)
  ) {
    if (event.keyCode === 110 || event.keyCode === 190) {
      const input = event.target as HTMLInputElement;
      const currentValue = input.value;
      if (currentValue.includes('.')) {
        event.preventDefault(); 
        return false;
      }
    }
    return true;
  }
  event.preventDefault();
  return false;
}

   setFormValid():void {
      this.isSundryAvailable.emit(false);
      if(this.jobOrder.jobOrderId){
        this.dialogRef = this.dialogService.open(this.discardDialogJobOrderUpdateForm);
        this.selectedJobOrderID = this.jobOrder.jobOrderId
      }else{
        this.dialogRef = this.dialogService.open(this.discardDialogjobOrder);
      }
      this.dialogRef.onClose.subscribe(result => {
      if(result === 'done' ){
      }
   })
  }

  closeJobOrder(){
      this.dialogRef.close()
      this.dialogRef = this.dialogService.open(this.discardDialogJobOrderForm);
      this.dialogRef.onClose.subscribe(result => {
      if(result === 'done' ){
      }
   })
  }

   closeButton(): void {
   this.dialogRef.close()
  }

  delete(): void {
       this.dialogRef.close("done");
  }

  saveAndExitJobOrder(){

    let jobOrderData = sessionStorage.getItem('jobOrdersOperation');
    this.jobOrdersOperation = JSON.parse(jobOrderData);
    let jobOrdersExpenses=[];
    let jobOrdersObj={};
    this.shareDataService.stepperFormValueShare.subscribe((res) => {
      this.jobOrdersOperation = res.jobOrdersOperation;  
       });
  for(let i=0; i<this.dataSource.length; i++){
     if(this.dataSource[i].inUse!==true){
    jobOrdersObj= {
      expensesId: this.dataSource[i].id || 0,
      jobOrderId:this.jobOrder.jobOrderId || null,
      otherItemCodeId:this.dataSource[i].otherExpenses? this.dataSource[i].otherExpenses.id : this.dataSource[i].expensesCategories.id,
      otherFeeAmount: this.dataSource[i].amount ?this.dataSource[i].amount : this.dataSource[i].otherFeeAmount,
    }
    jobOrdersExpenses.push(jobOrdersObj);
  }
}
      this.configService.setLoading(true);
      this.dialogRef.close()
      if(this.jobOrder.jobOrderId){
        this.warrantySundryCodeService.updateJobOrder({jobOrder: this.jobOrder, jobOrdersVehicleDetail: this.jobOrdersVehicleDetail, jobOrdersOperation: this.jobOrdersOperation, jobOrdersSparePart:this.jobOrdersSparePart, jobOrdersExpenses:jobOrdersExpenses}).subscribe((res:any) => {
        this.isCreatedClaim=true;
        if(res){
          this.configService.setLoading(false);
        this.showSuccess('Changes in the job order saved successfully', "\u2705");
        sessionStorage.removeItem('jobOrdersOperation');
        }},(error)=>{
           this.configService.setLoading(false);
        })
      }else{
        this.warrantySundryCodeService.createJobOrder({jobOrder: this.jobOrder, jobOrdersVehicleDetail: this.jobOrdersVehicleDetail, jobOrdersOperation: this.jobOrdersOperation, jobOrdersSparePart:this.jobOrdersSparePart, jobOrdersExpenses:jobOrdersExpenses}).subscribe((res:any) => {
          this.jobOrderId=res.JobOrder.entityObject.Id;
          this.isCreatedClaim=true;
           if(res.JobOrder){
          this.configService.setLoading(false);
        this.showSuccess('Job Order '+`${this.jobOrderId}`+' created successfully.', "\u2705");
        sessionStorage.removeItem('jobOrdersOperation');
           }
      },(error)=>{
           this.configService.setLoading(false);
        })
    }
      setTimeout(()=>{
        this.configService.setLoading(true)
         this.shareDataService.setActiveTab("jobOrderListTab");
        this.router.navigate(["/pages/warranty/warranty-job-order"]);
         this.configService.setLoading(false)
      },1000)
  }

  saveAndCreateClaim(){
       let saveJobOrdersExpens=[];
       let jobOrderData = sessionStorage.getItem('jobOrdersOperation');
       this.jobOrdersOperation = JSON.parse(jobOrderData);
       let jobOrdersObj={};
      for(let i=0; i<this.dataSource.length; i++){
        jobOrdersObj= {
          expensesId: this.dataSource[i].id || 0,
          jobOrderId:this.jobOrder.jobOrderId || null,
          otherItemCodeId:this.dataSource[i].codeId,
          otherFeeAmount: this.dataSource[i].amount,
        }
        saveJobOrdersExpens.push(jobOrdersObj);
      }
      this.configService.setLoading(true);
      this.dialogRef.close()
     this.warrantySundryCodeService.createJobOrder({jobOrder: this.jobOrder, jobOrdersVehicleDetail: this.jobOrdersVehicleDetail, jobOrdersOperation: this.jobOrdersOperation, jobOrdersSparePart:this.jobOrdersSparePart, jobOrdersExpenses:saveJobOrdersExpens}).subscribe((res:any) => {
        this.jobOrderId=res.JobOrder.entityObject.Id;
        this.isCreatedClaim=true;
        this.configService.setLoading(false);
      this.showSuccess('Job Order '+`${this.jobOrderId}`+' created successfully.', "\u2705");
      setTimeout(()=>{
         this.router.navigate(["/pages/warranty/warranty-claim/claim-form"],{ queryParams: { id: this.jobOrderId } });
      },500)
    }, err => {
    });
  }

  jobOrderDiscard(){
    this.showSuccess('Job Order discarded.', "\u2705");
    setTimeout(()=>{
        this.router.navigate(["/pages/warranty/warranty-job-order"]);
      },1500)
  }

  showSuccess(message: string,content) {
    this.snackBar.open(content,message,{
      duration: 1500,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: ['custom-snackbar']
    });
  }

  onSubmit() {
   let jobOrdersObj={};
  for(let i=0; i<this.dataSource.length; i++){
    jobOrdersObj= {
      otherItemCodeId:this.dataSource[i].codeId,
      otherFeeAmount: this.dataSource[i].amount,
    }
    this.jobOrdersExpenses.push(jobOrdersObj);
  }
 }

  backToListOrder() {
    this.shareStepperData.setActiveTab("jobOrderListTab");
    this.router.navigate(["/pages/warranty/warranty-job-order"]);
  }
 
}
