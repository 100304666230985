import { Component, EventEmitter, OnInit, Optional, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { MatDialog } from '@angular/material';
import { MatTableDataSource } from "@angular/material/table";
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { WarrantyClaimService } from '../../../@core/akita-stores/stores/warranty-claim/warranty-claim.service';
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute, Router } from '@angular/router';
import { StepperDataService } from "../../../@core/utils/stepper-data.service";

interface sparePartsInfo {
  id: any;
  isMainPart: any;
  partsCodeValue: number;
  name: string;
  quantity: number;
  amount: number;
  partsHandlingAmount: number;
  faultParts: boolean;
  partQuantity:number;
  sparePart:any;
}

@Component({
  selector: "spareparts-code",
  templateUrl: "./spareparts-code.component.html",
  styleUrls: ["./spareparts-code.component.scss"],
})
export class SparepartsCodeComponent implements OnInit {
  @Output() isSparePartsAvailable = new EventEmitter<boolean>();
  editPosition: number = 0;
  stepFourForm: FormGroup;
  dataSource: sparePartsInfo[] = [];
  totalValue:boolean = false;
  isEditSpare:boolean = false
  partsCode: string[] = [];
  partsCodeFilter : string[]= [];
  partsAmount:number = 0;
  selectedIndex: number   = 0;
  selectedFaultPart: boolean;

   @ViewChild('discardDialog', { static: false })  discardDialog: TemplateRef<any>;  
    selection = new SelectionModel<sparePartsInfo>(true, []);
    sparePartsId: number;
    jobOrder: any;
    claimInfoObj: any;
    claimsOperationObj: any;
    isClaimEditable: string;
  constructor(private dialogService: NbDialogService,@Optional() private dialogRef: NbDialogRef<any>, 
  private warrantyClaimService: WarrantyClaimService,private activateRoute:ActivatedRoute, private shareStepperData: StepperDataService, private router : Router) {
    this.stepFourForm = new FormGroup({
      sparePartsCode: new FormControl("",),
      partsName: new FormControl(""),
      sparePartsAmount: new FormControl(""),
      discountedAmount: new FormControl(""),
      partsQuantity:new FormControl("",)
    });
  }

  displayedColumns: string[] = [
    "checkSelect",
    "position",
    "partsCodeValue",
    "name",
    "quantity",
    "faultParts"
  ];

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
        this.sparePartsId =+params['id'];
        this.isClaimEditable=params['isClaimEditable'];
        if(this.isClaimEditable == 'false'){
         this.getClaimDetailsByFromList(this.sparePartsId)
        }else{
          this.getClaimDetails(this.sparePartsId)
        }
    });

    this.shareStepperData.claimStepperFormValueShare.subscribe((res)=>{
      this.jobOrder = res.jobOrder;
      this.claimInfoObj=res.claim;
      this.claimsOperationObj=res.claimsOperation;
  });
  }
    getClaimDetails(id){
    this.warrantyClaimService.getClaimDetailsById(id).pipe().subscribe((res:any)=>{
    const sparePartDetails=  res;
    this.dataSource =sparePartDetails.jobOrdersSparePartDetails;
    });
  }

  getClaimDetailsByFromList(id){
    this.warrantyClaimService.getClaimDetailsByIdFromList(id).pipe().subscribe((res : any)=>{
      const sparePartDetails = res;
      this.dataSource = sparePartDetails.claimSparePartDetails
      this.dataSource = [...this.dataSource]
      if(this.isClaimEditable) {
        this.dataSource.forEach(row => this.selection.select(row));
        
        }
    })
  }

  valueChange(quantityChange:number){
      this.stepFourForm.get("sparePartsAmount").patchValue(this.partsAmount * quantityChange);
      let totalDiscount = this.getTotalDiscount();
      if(this.editPosition > 0) {
      totalDiscount = totalDiscount - this.dataSource[this.editPosition - 1].partsHandlingAmount;
    }
      if(totalDiscount < 80) {
        const sparePartsAmount = this.stepFourForm.get("sparePartsAmount").value;
      this.stepFourForm.get("discountedAmount").patchValue(sparePartsAmount * 0.1);
    }
  }

  onCheckboxChange(index: number): void {
    for (let i=0; i<this.dataSource.length; i++){
      if(this.selectedIndex === i ){
         this.dataSource[i].faultParts=true;
      }else{
         this.dataSource[i].faultParts=false;
      }
    }
  }
  
  getAmount(id){
    this.warrantyClaimService.getPartsAmountInfo(id).pipe().subscribe((x:any)=>{
    this.partsAmount =  x.results[0].amount
    this.stepFourForm.get("sparePartsAmount").patchValue(this.partsAmount);
     if(this.getTotalDiscount() < 80) {
      this.stepFourForm.get("discountedAmount").patchValue(this.partsAmount * 0.1);
    }
   })
  }

  onSelect(event) {
    this.stepFourForm.get('partsName').patchValue(event.value.description)
    this.stepFourForm.get("partsQuantity").patchValue(1);
    this.getAmount(event.value.id)
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.forEach(row => this.selection.select(row));
  }

  handleMasterCheckBox(event,index) {
     this.dataSource.forEach((item) => {
    item.isMainPart = false;
  });
    this.dataSource[index].isMainPart = event.checked;
  }

  addSpareParts() {
   if(this.stepFourForm.valid){
      const record = {
      partsCodeValue: this.stepFourForm.get("sparePartsCode").value,
      name: this.stepFourForm.get("partsName").value,
      quantity: this.stepFourForm.get('partsQuantity').value,
      amount: this.stepFourForm.get('sparePartsAmount').value,
      faultParts: true,
      partsHandlingAmount: this.stepFourForm.get('discountedAmount').value,
    };

    let totalAmount = this.getTotalDiscount();
    if(this.editPosition === 0) {
      totalAmount = totalAmount + this.stepFourForm.get('discountedAmount').value;
    }
    if(this.editPosition > 0) {
      totalAmount = totalAmount - this.dataSource[this.editPosition - 1].partsHandlingAmount;
    }
    if(totalAmount > 80) {
      record.partsHandlingAmount = 80 - this.getTotalDiscount();
    }else {
      if(this.editPosition > 0) {
        record.partsHandlingAmount = (record.partsHandlingAmount + totalAmount) > 80 ? 80 - totalAmount : record.partsHandlingAmount;
      }
    }

      if(this.editPosition > 0) {
        this.editPosition = 0;
      }
      this.dataSource = [...this.dataSource];
      this.totalValue = true;
      this.stepFourForm.get("sparePartsCode").clearValidators();
      this.stepFourForm.get("partsQuantity").clearValidators();
      this.stepFourForm.reset()
    }
  }

  editSparePart(index: number) {
  this.editPosition = index + 1;
  const element = this.dataSource[index];
  this.stepFourForm.get("sparePartsCode").patchValue(element.partsCodeValue);
   this.stepFourForm.get("partsName").patchValue(element.name);
   this.stepFourForm.get("partsQuantity").patchValue(element.quantity);
   this.stepFourForm.get("sparePartsAmount").patchValue(element.amount);
   this.stepFourForm.get("discountedAmount").patchValue(element.partsHandlingAmount);
  }

   getTotalAmount() {
    return this.dataSource
      .map((t) => t.amount)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotalDiscount(){
     return this.dataSource
      .map((e) => e.partsHandlingAmount)
      .reduce((accm, discount) => accm + discount, 0);
  }

  deleteSparePart(i) {
   this.dialogRef = this.dialogService.open(this.discardDialog);
   this.dialogRef.onClose.subscribe(result => {
  if(result === 'done' ){
   this.dataSource.splice(i, 1)
  this.dataSource = [...this.dataSource];
    }
   })
  }

  numberOnly(event): boolean {
    if(!((event.keyCode > 95 && event.keyCode < 106)
      || (event.keyCode > 47 && event.keyCode < 58) 
      || event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 110)) {
        return false;
    }
  }

  closeButton(): void {
   this.dialogRef.close()
  }

  delete(): void {
    this.dialogRef.close("done");
  }

  onSubmit() {
    this.setFormValid(); 
    const sparePartClaim=[];
    let sparePartClaimObj : any ={};
    for(let i=0; i<this.selection.selected.length; i++){
      if(this.isClaimEditable == 'false'){
        sparePartClaimObj.sparePartId  = this.selection.selected[i].id
      }
    sparePartClaimObj= {
    partCodeId: this.selection.selected[i].sparePart.id,
    partQuantity:this.selection.selected[i].partQuantity,
    totalAmount: this.selection.selected[i].sparePart.amount,
    handlingAmount:this.partsAmount,
    isMainPart:this.selection.selected[i].isMainPart,
    SparePartId: this.selection.selected[i].id
  }
    sparePartClaim.push(sparePartClaimObj);
 }
      this.isSparePartsAvailable.emit(false); 
    this.shareStepperData.saveStepValueObject({claim:this.claimInfoObj, claimsOperation:this.claimsOperationObj,
    claimsSparePart: sparePartClaim
    })
}
  
  isFormValid: boolean = false;

setFormValid(): void {
  // Check if there is at least one main part selected, but skip if dataSource is empty
  const hasMainPart = this.dataSource.length === 0 || this.selection.selected.some(item => item.isMainPart === true);

  // Form is valid if the selected items are greater than or equal to 0, a main part is selected, and fault part is selected
  this.isFormValid = (this.selection.selected.length >= 0) && hasMainPart && (this.selectedFaultPart !== null);

  // Emit whether spare parts are available based on form validity
  this.isSparePartsAvailable.emit(!this.isFormValid);
}


handlePartSelection(element: any, event: any): void {
  if (event.checked) {
    this.selection.select(element);
  } else {
    this.selection.deselect(element);
  }
}

   backToClaimList(){
    this.router.navigate(['/pages/warranty'])
  }
}