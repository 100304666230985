import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { WarrantyClaimService } from '../../../@core/akita-stores/stores/warranty-claim/warranty-claim.service';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { StepperDataService } from "../../../@core/utils/stepper-data.service";
import { WARRANTY_PERMISSIONS } from "../../../constants/warranty-permission";
import { NgxPermissionsService } from "ngx-permissions";


@Component({
  selector: 'claim-info',
  templateUrl: './claim-info.component.html',
  styleUrls: ['./claim-info.component.scss'],
})
export class ClaimInfoComponent implements OnInit {
  public warrantyPermission = WARRANTY_PERMISSIONS;
  stepTwoForm: FormGroup;
  claim = 0;
  length = 0;
  pageSize = 5;
  pageIndex = 0;
  claimTypes$;
  jobOrderID: number;
  jobOrderDetails;
  problemClassificationTypes: string[] = [];
  claimTypeDescription: string[] = [];
  rescueValue: string[] = [];
  public filterList: any;
  public claimFilter: any;
  public rescueFilter: any;
  @Output() isClaimInfoAvailable = new EventEmitter<boolean>();
  jobOrderSparePartDetails: any = [];
  isClaimEditable: string;
  isTechCodeSelected: boolean = true;
  isInputRequired: boolean = false;
  constructor(
    private fb: FormBuilder,
    private warrantyClaimInfo: WarrantyClaimService,
    private router: Router,
    private shareStepperData: StepperDataService,
    private activateRoute: ActivatedRoute,
    private ngxPermissionsService: NgxPermissionsService
  ) {
    this.stepTwoForm = new FormGroup({
      jobType: new FormControl(''),
      problemClassification: new FormControl('', Validators.required),
      causeOfFailure: new FormControl('', Validators.required),
      remark: new FormControl(''),
      claimType: new FormControl('', Validators.required),
      technicalProjectCode: new FormControl(''),
      whetherToRescue: new FormControl('', Validators.required),
      faultDescription: new FormControl('', Validators.required),
      repaireMeasure: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
      this.jobOrderID = +params['id'];
      this.isClaimEditable = params['isClaimEditable'];
      if (this.isClaimEditable == 'true') {
        this.getJobOrderById(this.jobOrderID);
      }

      this.warrantyClaimInfo
        .getClaimTypes()
        .pipe()
        .subscribe((x: any) => {
          this.claimTypeDescription = x.results;
        });

      this.warrantyClaimInfo
        .getProblemClassification()
        .pipe()
        .subscribe((x: any) => {
          this.problemClassificationTypes = x.results;
          this.filterList = this.problemClassificationTypes.slice();
          if (this.isClaimEditable == 'false') {
            this.getClaimDetailsByFromList(this.jobOrderID);
          }
        });

      this.warrantyClaimInfo
        .getRescueStatus()
        .pipe()
        .subscribe((x: any) => {
          this.rescueValue = x.results;
        });
    });
  }

  onClearClaimType(event: any) {
    this.stepTwoForm.patchValue({
      claimType: '',
    });
  }

  onClearProblemCassification(event: any) {
    this.stepTwoForm.patchValue({
      problemClassification: '',
    });
  }

  onClearwhetherToRescue(event: any) {
    this.stepTwoForm.patchValue({
      whetherToRescue: '',
    });
  }

  onDropdownChange(e) {
    this.isTechCodeSelected = e.value !== 5;
    this.isInputRequired = e.value === 5;
  }

  getJobOrderById(id) {
    this.warrantyClaimInfo
      .getClaimDetailsById(id)
      .pipe()
      .subscribe((x: any) => {
        const operationDetails = x;
        this.jobOrderDetails = operationDetails;
      });
  }

  getClaimDetailsByFromList(id) {
    this.warrantyClaimInfo
      .getClaimDetailsByIdFromList(id)
      .pipe()
      .subscribe((res: any) => {
        const claimDetails = res;
        this.stepTwoForm.get('jobType').patchValue(claimDetails.jobType);

        const claimTypeId: any = this.claimTypeDescription.find(
          (object: any) => {
            if (object.description === claimDetails.claimType.description) {
              return object.id;
            }
          }
        );

        if (claimTypeId) {
          this.stepTwoForm.get('claimType').patchValue(claimTypeId.id);
          if (this.stepTwoForm.get('claimType').value === 5) {
            this.isTechCodeSelected =
              this.stepTwoForm.get('claimType').value !== 5;
          }
        }

        const problemClassificationID: any = this.filterList.find(
          (object: any) => {
            if (object.description === claimDetails.problemType.description) {
              return object.id;
            }
          }
        );

        if (problemClassificationID) {
          this.stepTwoForm
            .get('problemClassification')
            .patchValue(problemClassificationID.id);
        }

        const whetherToRescueId: any = this.rescueValue.find((object: any) => {
          if (object.description === claimDetails.rescue.description) {
            return object.id;
          }
        });

        if (whetherToRescueId) {
          this.stepTwoForm
            .get('whetherToRescue')
            .patchValue(whetherToRescueId.id);
        }

        this.stepTwoForm
          .get('causeOfFailure')
          .patchValue(claimDetails.damReason);
        this.stepTwoForm
          .get('faultDescription')
          .patchValue(claimDetails.damDescription);
        this.stepTwoForm.get('remark').patchValue(claimDetails.remark);
        this.stepTwoForm
          .get('repaireMeasure')
          .patchValue(claimDetails.repairMeasure);
        this.stepTwoForm
          .get('technicalProjectCode')
          .patchValue(claimDetails.techCode);
      });
  }

  stepTwoSubmit() {
    let claimObj: any = {};
    if (this.isClaimEditable == 'false') {
      claimObj.ClaimId = this.jobOrderID;
    }

    claimObj.JobOrderNumber = this.jobOrderID;
    claimObj.dealerCodeId = 1;
    claimObj.claimTypeId = this.stepTwoForm.get('claimType').value;
    claimObj.problemTypeId = this.stepTwoForm.get(
      'problemClassification'
    ).value;
    claimObj.rescueTypeId = this.stepTwoForm.get('whetherToRescue').value;
    claimObj.damDescription = this.stepTwoForm.get('faultDescription').value;
    claimObj.damReason = this.stepTwoForm.get('causeOfFailure').value;
    claimObj.repairMeasure = this.stepTwoForm.get('repaireMeasure').value;
    claimObj.remark = this.stepTwoForm.get('remark').value;
    claimObj.ClaimStatusId = null;
    claimObj.ClaimStatusRemark = null;
    claimObj.PreApprovalStatus = null;
    claimObj.PreApprovalStatusRemark = null;
    claimObj.techCode = this.isTechCodeSelected
      ? null
      : this.stepTwoForm.get('technicalProjectCode').value;

    if (this.stepTwoForm.valid) {
      this.isClaimInfoAvailable.emit(false);
      this.shareStepperData.saveStepValueObject({ claim: claimObj });
    } else {
      this.isClaimInfoAvailable.emit(true);
    }
  }

  backToClaimList() {
    const hasCreateClaimPermission = this.ngxPermissionsService.getPermission(WARRANTY_PERMISSIONS.createBYDWarrantyWarrantyClaim);
    if (hasCreateClaimPermission) {
      this.router.navigate(['/pages/warranty/warranty-claim']);
    }
    else {
      this.router.navigate(['/pages/warranty']);
    }
  }
}