import { Component, Input, Output, OnInit, Renderer2, EventEmitter, OnDestroy } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { UserQuery } from '../../../@core/akita-stores/stores/user/user.query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-manage-warranty-layout',
  styleUrls: ['./manage-warranty.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-column id="box-main-warranty" [nbSpinner]="(isLogin$ | async) == false" class="p-3 m-3">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class ManageWarrantyLayoutComponent implements OnInit, OnDestroy {
  isLogin$ = this.userQuery.isLogin$;

  constructor(
    private sidebarService: NbSidebarService,
    private userQuery: UserQuery,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  toggle() {
    this.sidebarService.toggle(true, 'sidebar-bookings');
  }

  
}
