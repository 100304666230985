import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbProgressBarModule,
  NbThemeModule,
  NbRouteTabsetModule,
  NbTabsetModule,
  NbCardModule,
  NbListModule,
  NbInputModule,
  NbToggleModule,
  NbSpinnerModule,
  NbTooltipModule,
  NbDatepickerModule,
  NbStepperModule,
  NbAccordionModule,
  NbCalendarModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import { NgxMaskModule } from 'ngx-mask';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';

import {
  FooterComponent,
  HeaderComponent,
  LayoutDirectionSwitcherComponent,
  SearchInputComponent,
  SwitcherComponent,
  CustomTableComponent,
  SidebarCustomerComponent,
  ServiceCardComponent,
  ReceiptComponent,
  SidebarBookingsComponent,
  MoCalendarComponent,
  SidebarBookingComponent,
  MoTagComponent,
  ActivityLogComponent,
  LogbookServiceComponent,
  SpecialOfferComponent,
  FreeBeverageComponent,
  ImageComponent,
  EditBookingDlgComponent,
  EditCustomerDlgComponent,
  GeneralDialogComponent,
  SelectVehicleComponent,
  SearchByRegoComponent,
  SearchVehicleManualComponent,
  StatusProgressComponent,
  CheckInDlgComponent,
  EntityTreeComponent,
  CustomChartComponent,
  MultipleSelect,
  DateRangePicker,
  KpiReportExportPreviewComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  VehicleStrPipe,
  FilterJobsPipe,
  KmServicePipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  ManageBookingsLayoutComponent,
  ManageBeveragesLayoutComponent,
  ManageWarrantyLayoutComponent,
  ManageClaimLayoutComponent,
  ManageClaimlistLayoutComponent,
  ManageJobOrderComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
  MatInputModule,
  MatFormFieldModule,
  MatMenuModule,
  MatNativeDateModule,
  MatIconModule,
  MatCheckboxModule,
  MatSelectModule,
  MatBottomSheetModule,
  MatAutocompleteModule,
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  MatTreeModule,
  MatRadioModule,
  MatChipsModule,
  MatListModule,
  MatDividerModule,
  MatExpansionModule,
  MatCardModule,
  MatSnackBarModule,
  MatTooltipModule,
} from '@angular/material';
import { NgxMultiLineEllipsisModule } from 'ngx-multi-line-ellipsis';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BookingCalendarComponent } from './components/sidebar-bookings/booking-calendar/booking-calendar.component';
import { BookingStatsComponent } from './components/sidebar-bookings/booking-stats/booking-stats.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PaymentMethodDialogComponent } from './components/payment-method-dialog/payment-method-dialog.component';
import { CardDetailsDialogComponent } from './components/card-details-dialog/card-details-dialog.component';
import { PaymentResultDialogComponent } from './components/payment-result-dialog/payment-result-dialog.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AddSignatureDialogComponent } from './components/add-signature-dialog/add-signature-dialog.component';
import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { InputAmountPopupComponent } from './components/input-amount-popup/input-amount-popup.component';
import { ReceiptNotedComponent } from './components/receipt-noted/receipt-noted.component';
import { BookingCancelDialogComponent } from './components/booking-cancel-dialog/booking-cancel-dialog.component';
import { SidebarBeveragesComponent } from './components/sidebar-beverages/sidebar-beverages.component';
import { BeverageCalendarComponent } from './components/sidebar-beverages/beverage-calendar/beverage-calendar.component';
import { ServiceRecommendationsComponent } from './components/service-recommendations/service-recommendations.component';
import { AddServiceRecommendationDlgComponent } from './components/service-recommendations/add-service-recommendation-dlg/add-service-recommendation-dlg.component';
import {
  AlphanumericDirective,
  DisableControlDirective,
  NoProtocolDirective,
  FileDragNDropDirective,
} from './directives';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UploadControlComponent } from './components/upload-control/upload-control.component';
import { BeaconPipe } from './pipes/beacon.pipe';
import { RoleListManagementComponent } from './components/role-list-management/role-list-management.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { PermissionListManagementComponent } from './components/permission-list-management/permission-list-management.component';
import { RoleRenameDlgComponent } from './components/role-list-management/role-rename-dlg/role-rename-dlg.component';
import { SelectServiceCenterDlgComponent } from './components/select-service-center-dlg/select-service-center-dlg.component';
import { AssignRoleDlgComponent } from './components/assgin-role-dlg/assign-role-dlg.component';
import { RouterModule } from '@angular/router';
import { SidebarWarrantyComponent } from './components/sidebar-warranty/sidebar-warranty.component';
import { NewClaimFormComponent } from './components/new-claim-form/new-claim-form.component';
import { ClaimInfoComponent } from './components/claim-info/claim-info.component';
import { OperationCodeInfoComponent } from './components/operation-code-info/operation-code-info.component';
import { SparepartsCodeComponent } from './components/spareparts-code/spareparts-code.component';
import { SundryInfoComponent } from './components/sundry-info/sundry-info.component';
import { WarrantyAttchmentComponent } from './components/warranty-attchment/warranty-attchment.component';
import { MatTableModule } from '@angular/material/table';
import { MatSelectFilterModule } from 'mat-select-filter';
import { VehicleDetailsComponent } from './components/vehicle-details/vehicle-details.component';
import { JobOperationCodeInfoComponent } from './components/job-operation-code-info/job-operation-code-info.component';
import { JobOrderSparepartsCodeComponent } from './components/job-order-spareparts-code/job-order-spareparts-code.component';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { SundryInfoClaimComponent } from './components/sundry-info-claim/sundry-info-claim.component';
import { CustomYearPickerComponent } from './components/custom-year-picker/custom-year-picker.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbProgressBarModule,
  NgxDatatableModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbCardModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  NbListModule,
  NbInputModule,
  NbToggleModule,
  NbSpinnerModule,
  NbTooltipModule,
  NbDatepickerModule,
  NbStepperModule,
  NbAccordionModule,
  NbCalendarModule,
  MatSnackBarModule
];
const COMPONENTS = [
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  CustomTableComponent,
  SidebarCustomerComponent,
  ServiceCardComponent,
  ReceiptComponent,
  ManageBookingsLayoutComponent,
  ManageWarrantyLayoutComponent,
  ManageClaimLayoutComponent,
  ManageJobOrderComponent,
  ManageClaimlistLayoutComponent,
  ManageBeveragesLayoutComponent,
  SidebarBookingsComponent,
  MoCalendarComponent,
  SidebarBookingComponent,
  BookingCalendarComponent,
  BookingStatsComponent,
  LogbookServiceComponent,
  SpecialOfferComponent,
  FreeBeverageComponent,
  MoTagComponent,
  ActivityLogComponent,
  SidebarBeveragesComponent,
  ImageComponent,
  EditBookingDlgComponent,
  EditCustomerDlgComponent,
  GeneralDialogComponent,
  SelectVehicleComponent,
  SearchByRegoComponent,
  SearchVehicleManualComponent,
  ReceiptNotedComponent,
  StatusProgressComponent,
  CheckInDlgComponent,
  EntityTreeComponent,
  ServiceRecommendationsComponent,
  AddServiceRecommendationDlgComponent,
  UploadControlComponent,
  CustomChartComponent,
  MultipleSelect,
  DateRangePicker,
  KpiReportExportPreviewComponent,
  RoleListManagementComponent,
  PermissionListManagementComponent,
  SidebarWarrantyComponent,
  NewClaimFormComponent,
  ClaimInfoComponent,
  OperationCodeInfoComponent,
  SparepartsCodeComponent,
  SundryInfoComponent,
  WarrantyAttchmentComponent,
  VehicleDetailsComponent,
  JobOperationCodeInfoComponent,
  JobOrderSparepartsCodeComponent,
  SundryInfoClaimComponent,
  CustomYearPickerComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  VehicleStrPipe,
  FilterJobsPipe,
  KmServicePipe,
  BeaconPipe,
];
const MAT = [
  MatMenuModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatIconModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSelectModule,
  MatBottomSheetModule,
  MatAutocompleteModule,
  MatTreeModule,
  MatChipsModule,
  MatListModule,
  MatDividerModule,
  MatExpansionModule,
  MatTableModule,
  MatSelectFilterModule,
  MatTooltipModule
];
const ENTRY_COMPONENTS = [
  MoCalendarComponent,
  PaymentMethodDialogComponent,
  CardDetailsDialogComponent,
  PaymentResultDialogComponent,
  AddSignatureDialogComponent,
  EditBookingDlgComponent,
  EditCustomerDlgComponent,
  GeneralDialogComponent,
  BookingDetailsComponent,
  InputAmountPopupComponent,
  SelectVehicleComponent,
  BookingCancelDialogComponent,
  CheckInDlgComponent,
  ServiceRecommendationsComponent,
  AddServiceRecommendationDlgComponent,
  KpiReportExportPreviewComponent,
  RoleRenameDlgComponent,
  SelectServiceCenterDlgComponent,
  AssignRoleDlgComponent,
];
const DECLARATIONS = [
  SidebarBookingComponent,
  PaymentMethodDialogComponent,
  InputAmountPopupComponent,
  CardDetailsDialogComponent,
  PaymentResultDialogComponent,
  AddSignatureDialogComponent,
  BookingDetailsComponent,
  BookingCancelDialogComponent,
  SidebarBeveragesComponent,
  BeverageCalendarComponent,
  AddServiceRecommendationDlgComponent,
  RoleRenameDlgComponent,
  SelectServiceCenterDlgComponent,
  AssignRoleDlgComponent,
];
const DIRECTIVES = [
  DisableControlDirective,
  AlphanumericDirective,
  NoProtocolDirective,
  FileDragNDropDirective,
];

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    ...NB_MODULES,
    ...MAT,
    NgxMultiLineEllipsisModule,
    SlickCarouselModule,
    MatInputModule,
    MatFormFieldModule,
    NgxMultiLineEllipsisModule,
    ReactiveFormsModule,
    FormsModule,
    LazyLoadImageModule,
    NgxMaskModule.forRoot({ validation: true }),
    SignaturePadModule,
    NgxCleaveDirectiveModule,
    NgxMatDatetimePickerModule,
    NgxPermissionsModule.forChild(),
    ClickOutsideModule,
    RouterModule.forChild([]),
  ],
  exports: [CommonModule, RouterModule, ...PIPES, ...MAT, ...COMPONENTS, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES, ...DECLARATIONS],
  entryComponents: [...ENTRY_COMPONENTS],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
   
  ],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]
        ).providers,
      ],
    };
  }
}
