import { Injectable } from '@angular/core';
import { ServiceCentersStore } from './service-centers.store';
import { of } from 'rxjs';
import * as _ from 'lodash';
import { API_PATH } from '../../../../constants/api-urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { saveTimezone, saveCurrentCentre, saveCurrentEntity } from '../../storage';
import { SessionQuery } from '../../stores/session/session.query';
import { environment } from '../../../../../environments/environment';
import { APIService } from '../../../utils/api.service';

@Injectable({ providedIn: 'root' })
export class ServiceCentersService {

  constructor(
    private serviceCentersStore: ServiceCentersStore,
    private http: HttpClient,
    private sessionQuery: SessionQuery,
  ) { }

  getServiceCenterByAPI(isSelectDetail = true, selectEntityLogin?, pageSize?) {
    let header = new HttpHeaders();
    if (selectEntityLogin) {
        header = header.set('Entity-Context', selectEntityLogin.toString());
    } 
    else {
        header = null;
    }

    return this.http.get(API_PATH.entities.serviceCentres + (pageSize || ''), { headers : header }).pipe(
      map((res: any) => res.results),
      tap((centers: any) => {
        let timezone = environment.defaultTimezone;
        let entityId: any = this.sessionQuery.getValue().entityId || null;
        // no centers
        if (!centers || !centers[0]) {
          this.saveDataCenter(timezone, entityId);
          return;
        }
        for (let i = 0; i < centers.length; ++i) {
          centers[i].isSelected = false;
        }
        this.serviceCentersStore.set(centers);
        let centerSelected = centers.filter(center => center.entityId === this.sessionQuery.getValue().entityId);
        // no center selected
        if (!centerSelected || !centerSelected[0]) {
          this.saveDataCenter(timezone, entityId);
          return;
        }
        // exist center selected
        entityId = centerSelected[0].entityId;
        if (centerSelected[0].addresses && centerSelected[0].addresses[0].address.others) {
          timezone = centerSelected[0].addresses[0].address.others.timezone;
        }
        this.saveDataCenter(timezone, entityId);
        this.selectServiceCenter(centerSelected[0].id);
        // if (!isSelectDetail) {
        //   this.selectServiceCenter(centerSelected[0].id);
        // } else {
        //   let res = _.clone(centerSelected[0]);
        //   res['isSelected'] = true;
        //   this.serviceCentersStore.update(res.id, res);
        // }
      })
    );
  }

  saveDataCenter(timezone: string, entityId: number) {
    saveTimezone(timezone);
    saveCurrentCentre(entityId);
    saveCurrentEntity(entityId);
    this.sessionQuery.updateEntityId(entityId);
  }

  getServiceCenterDetailByAPI(id?: any) {
    return this.http.get(API_PATH.entities.serviceCentres + `/${id}?openingDays=7`).pipe(
      tap((res: any) => {
        // this.configService.setExpressServiceJobs(2).subscribe(); // min cylinder = 2
        this.serviceCentersStore.update(null, { isSelected: false });
        res['isSelected'] = true;
        this.serviceCentersStore.update(res.id, res);
        // Call API to get extra options base on Entity-Id of Service center
        // this.extraOptionsService.getExtraOptions();
        // this.beveragesService.getBeverages();
        // this.configService.setExtraService();
      })
    );
  }

  getServiceCenters(isSelectDetail = true, selectEntityLogin?, pageSize?) {
    return this.getServiceCenterByAPI(isSelectDetail, selectEntityLogin, pageSize);
  }

  selectServiceCenter(i: Number) {
    // this.serviceCentersStore.update(null, { isSelected: false });
    // const detail = await this.getServiceCenterDetailByAPI(i).toPromise();
    // detail['isSelected'] = true;
    // return this.serviceCentersStore.update(i, detail);
    return this.getServiceCenterDetailByAPI(i).subscribe();
  }

  getListServiceCenter() {
    return this.http.get(API_PATH.entities.serviceCentres).pipe(map((res: any) => res.results));
  }

  getBeaconsInCenter() {
    return this.http.get(API_PATH.entities.serviceCentresBeacons).pipe(map((res: any) => res.results));
  }

  updateBeacon(beaconId, payload) {
    const url = APIService.prepareURI(API_PATH.entities.serviceCentresBeacon, { beaconId });
    return this.http.put(url, payload);
  }

  getBeacons() {
    return this.http.get(API_PATH.entities.beacons).pipe(map((res: any) => res.results));
  }

  getPublicHoliday(state: string, year: string) {
    return this.http.get(API_PATH.entities.publicHolidays + `?state=${state}&year=${year}`).pipe(map((res: any) => _.get(res, 'results') || []));
  }

  getCentreByState(state: string) {
    return this.http.get(API_PATH.entities.centreByState + `?state=${state}`).pipe(map((res: any) => res || {}));
  }

  assignPublicHoliday(payload: any) {
    return this.http.post(API_PATH.entities.publicHolidays, payload);
  }
}
