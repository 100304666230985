import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class AttachmentIconService {

    render() {
        return `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.7266 22.1416C18.2494 22.1416 22.7266 17.6644 22.7266 12.1416C22.7266 6.61875 18.2494 2.1416 12.7266 2.1416C7.20371 2.1416 2.72656 6.61875 2.72656 12.1416C2.72656 17.6644 7.20371 22.1416 12.7266 22.1416Z" stroke="url(#paint0_linear_493_32950)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.7266 8.1416V16.1416" stroke="url(#paint1_linear_493_32950)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.72656 12.1416H16.7266" stroke="url(#paint2_linear_493_32950)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_493_32950" x1="2.72656" y1="12.1416" x2="22.7266" y2="12.1416" gradientUnits="userSpaceOnUse">
<stop stop-color="#DE2C40"/>
<stop offset="0.479167" stop-color="#DE142A"/>
<stop offset="1" stop-color="#AB1021"/>
</linearGradient>
<linearGradient id="paint1_linear_493_32950" x1="12.7266" y1="12.1416" x2="13.7266" y2="12.1416" gradientUnits="userSpaceOnUse">
<stop stop-color="#DE2C40"/>
<stop offset="0.479167" stop-color="#DE142A"/>
<stop offset="1" stop-color="#AB1021"/>
</linearGradient>
<linearGradient id="paint2_linear_493_32950" x1="8.72656" y1="12.6416" x2="16.7266" y2="12.6416" gradientUnits="userSpaceOnUse">
<stop stop-color="#DE2C40"/>
<stop offset="0.479167" stop-color="#DE142A"/>
<stop offset="1" stop-color="#AB1021"/>
</linearGradient>
</defs>
</svg>`;
    }
}
