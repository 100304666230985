import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit ,Output,ViewChild} from '@angular/core';
import { AbstractControl,FormControl,FormControlDirective,FormGroup,PatternValidator,Validators } from '@angular/forms';
import { MatDateFormats } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter,MAT_DATE_LOCALE,MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import * as _moment from 'moment';
import { DateFormatPipe } from 'angular2-moment';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { ActivatedRoute, Router } from '@angular/router';
import { WarrantyClaimService } from '../../../@core/akita-stores/stores/warranty-claim/warranty-claim.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import { StepperDataService } from '../../../@core/utils/stepper-data.service';
import { formatDate } from '@angular/common';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';

const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY h:mm A',
  },
  display: {
    dateInput: 'DD-MM-YYYY h:mm A',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'DD-MM-YYYY h:mm A',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: NGX_MAT_DATE_FORMATS,useValue: CUSTOM_DATE_FORMATS },
    DateFormatPipe],
})
export class VehicleDetailsComponent implements OnInit, OnDestroy {
  vehicleDetailsForm: FormGroup
  @ViewChild('picker',{ static: false }) picker: any;
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1
  public stepMinute = 1
  public color: ThemePalette = 'accent';
  private subscriptions = new Subscription();
  private keyupSubject = new Subject<string>();
  vehicleId;
  vehicleDeatils: string[] = [];
  vehicleSeriesId;
  jobOrderSettlementTime;
  jobOrderCreatedTime;
  rego:any;
  vin:any;
  engineNumber:any;
  vehicleModel:any;
  totalMileage:any;
  vehicleInfoData:any;
  maxCreatedTime;
  jobOrder={};
  jobOrdersVehicleDetail={};
  currentTime:Date=new Date();
  isValid: boolean = false;
  public min: Date = new Date(2000, 1, 1);
  public max: Date = new Date();
  @Output() isVehicleAvailable = new EventEmitter<boolean>();
  isJobOrderEditable:any;
  createdOn: any;
  createdBy: any;
  bookingVehicleSeries: any;
  vehicleInput: boolean = true;
  vehicleSeriesInfo: any;

  constructor(private activateRoute: ActivatedRoute,private router: Router,private warrantyClaimService: WarrantyClaimService,
    private http: HttpClient,private shareStepperData: StepperDataService, private cd: ChangeDetectorRef,private configService: ConfigService
  ) {
    this.vehicleDetailsForm = new FormGroup({
      rego: new FormControl(""),
      vin: new FormControl(""),
      vehicleSeries: new FormControl(""),
      brand: new FormControl(""),
      engineNumber: new FormControl(""),
      vehicleModel: new FormControl(""),
      totalMileage: new FormControl("", Validators.required),
      jobOrderCreatedTime: new FormControl("",[Validators.required, this.validateTypeDate ]),
      jobOrderSettlementTime: new FormControl(""),
      electricMileage : new FormControl("")
    });
    this.subscriptions.add(
    this.keyupSubject.pipe(
      debounceTime(1000), 
      switchMap(value => this.warrantyClaimService.getVehicleSeries({seriesName : value }))
    ).subscribe((res: any) => {
      const seriesInfo = res;
      this.vehicleSeriesId = seriesInfo.id;
      if (seriesInfo.description) {
        this.vehicleDetailsForm.get('vehicleSeries').setValue(seriesInfo.description);
      }
    })
  );
  }

  ngOnInit() {
     const now = moment().format('YYYY-MM-DD HH:mm:ss');
     this.vehicleDetailsForm.patchValue({
       jobOrderCreatedTime: now,
       jobOrderSettlementTime: now,
     });
    this.maxCreatedTime=this.jobOrderSettlementTime
    this.activateRoute.queryParams.subscribe((params) => {
      this.vehicleId = +params['id'];
      this.isJobOrderEditable=params['isJobOrderEditable'];
      if(this.isJobOrderEditable == 'false'){
        this.getJobOrderById(this.vehicleId);
      }else{
        this.getVehicleDetails(this.vehicleId);
      }
    });
  }


   ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  validateTypeDate(control: AbstractControl) {
    const value = control.value;
    //min date 01/01/1850 and max date today
    if (value == null || value == '' || value <= '1850-01-01' || value > (new Date())) {
      return { required: true };
    } else {
      return null;
    }
  }

  numberOnly(event): boolean {
    if(!((event.keyCode > 95 && event.keyCode < 106)
      || (event.keyCode > 47 && event.keyCode < 58) 
      || event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 110)) {
        return false;
    }
  }

  getVehicleDetails(id) {
    this.configService.setLoading(true);
    this.warrantyClaimService.getBookingDetailsById(id).pipe().subscribe((res: any) => {
      const vehicleDeatils = res.vehicle;
      const bookingId = res.bookingId;
      this.bookingVehicleSeries = vehicleDeatils.seriesCode
      let   filterObj = {
        regoNo: vehicleDeatils.rego ? vehicleDeatils.rego: '',
        vinNo:vehicleDeatils.vin ? vehicleDeatils.vin: '',
        bookingId: bookingId ? bookingId : ''
      }
      this.getVehicleInfo(filterObj)
      this.configService.setLoading(false);
   } )
  }
 
  getVehicleInfo(filterObj) {
    this.configService.setLoading(true);
    this.warrantyClaimService.getVehicleDetails(filterObj).subscribe((vehicleInfo) => {
      const seriesInfo = vehicleInfo;
      // if(seriesInfo){this.isJobOrderEditable.emit(false); }
      this.vehicleInfoData = vehicleInfo;
      this.vehicleSeriesInfo = seriesInfo.seriesCode

         if((this.vehicleSeriesInfo == null || this.vehicleSeriesInfo == undefined) && (this.isJobOrderEditable !== 'false')){
        this.vehicleInput = false
      }else{
        this.vehicleInput = true
      }
    
      this.vehicleDetailsForm.get('rego').setValue(seriesInfo.licenseNo);
      this.vehicleDetailsForm.get('vin').setValue(seriesInfo.vin);;
      this.vehicleDetailsForm.get('brand').setValue(seriesInfo.brandName);
      this.vehicleDetailsForm.get('vehicleModel').setValue(seriesInfo.modelName);
      this.vehicleDetailsForm.get('vehicleSeries').setValue(seriesInfo.seriesCode)
      this.vehicleDetailsForm.get('engineNumber').setValue(seriesInfo.engineNo);
      this.vehicleDetailsForm.get('totalMileage').setValue(seriesInfo.odometer);
      this.vehicleDetailsForm
        .get('electricMileage')
        .setValue(seriesInfo.evMileage);
      this.vehicleSeriesId = seriesInfo.vehicleSeriesId
    })
     this.configService.setLoading(false);
   }

  getJobOrderById(id) {
    this.warrantyClaimService.getClaimDetailsById(id).pipe().subscribe((res:any)=>{
      const seriesInfo=  res;
      this.vehicleInfoData = res.jobOrdersVehicleDetail;
      this.vehicleDetailsForm.get('rego').setValue(seriesInfo.jobOrdersVehicleDetail.rego);
      this.vehicleDetailsForm.get('vin').setValue(seriesInfo.jobOrdersVehicleDetail.vin);
      this.vehicleDetailsForm.get('brand').setValue(seriesInfo.jobOrdersVehicleDetail.brand.description);
      this.vehicleDetailsForm.get('vehicleModel').setValue(seriesInfo.jobOrdersVehicleDetail.modell.description);
      this.vehicleDetailsForm.get('vehicleSeries').setValue(seriesInfo.jobOrdersVehicleDetail.vehicle.code)
      this.vehicleDetailsForm.get('engineNumber').setValue(seriesInfo.jobOrdersVehicleDetail.engineNumber);
      this.vehicleDetailsForm.get('totalMileage').setValue(seriesInfo.jobOrdersVehicleDetail.totalMileage);
      this.vehicleDetailsForm.get('jobOrderCreatedTime').setValue(seriesInfo.orderCreatedOn);
      this.vehicleDetailsForm.get('jobOrderSettlementTime').setValue(seriesInfo.orderSettlementTime);
      this.vehicleDetailsForm.get('electricMileage').setValue(seriesInfo.jobOrdersVehicleDetail.electricMileage);
      this.jobOrderCreatedTime=seriesInfo.orderCreatedOn;
      this.jobOrderSettlementTime=seriesInfo.orderSettlementTime;
      this.vehicleSeriesId = seriesInfo.jobOrdersVehicleDetail.vehicleSeriesId;
      this.createdOn=seriesInfo.createdOn;
      this.createdBy=seriesInfo.createdBy;
      this.isValid = true;
   })
  }

  vehicleSerieslocator(id :string){
    const vehicleObj ={
      seriesName: id
    };
     this.keyupSubject.next(id);
  }

  dateTimeFormate(e){
    this.jobOrderSettlementTime = e.target.value 
  }

  dateJobOrderCreated(e){
    this.jobOrderCreatedTime = e.target.value
    let selectedCreatedTime=this.vehicleDetailsForm.get('jobOrderCreatedTime').value.toISOString()
    this.currentTime.setMinutes(this.currentTime.getMinutes() + 1);
    let currentTimeCheck:any=moment(this.currentTime).toISOString();
    let selectedCreateDateTime = new Date(selectedCreatedTime);
    let currentDateTimeCheck = new Date(currentTimeCheck);
    if ((this.jobOrderCreatedTime.value !== null && (selectedCreateDateTime < currentDateTimeCheck)) && this.vehicleDetailsForm.get('totalMileage').value !== null) {
      this.isValid = true;
      this.cd.detectChanges();
    } else {
      this.isValid = false;
      this.cd.detectChanges();
    }

  }

  backToListOrder() {
    this.router.navigate(['/pages/warranty/warranty-job-order'])
  }

  backToJobListOrder(){
  {
    this.shareStepperData.setActiveTab("jobOrderListTab");
    this.router.navigate(["/pages/warranty/warranty-job-order"]);
  }
}

  onSubmit(){
    const createdTime = moment(this.jobOrderCreatedTime).format('YYYY-MM-DD HH:mm:ss');
    const settlementTime = moment(this.jobOrderSettlementTime).format('YYYY-MM-DD HH:mm:ss');
        this.jobOrder= {
              jobOrderId: this.vehicleInfoData ? this.vehicleInfoData.jobOrderId : null,
              bookingId: this.vehicleId,
              orderCreatedOn: createdTime,
              orderSettlementTime: settlementTime}
        this.jobOrdersVehicleDetail= {
              vehicleDetailsId:this.vehicleInfoData ? this.vehicleInfoData.id : null,
              jobOrderId: this.vehicleInfoData ? this.vehicleInfoData.jobOrderId : null,
              rego: this.vehicleDetailsForm.get('rego').value,
              vin: this.vehicleDetailsForm.get('vin').value,
              engineNumber: this.vehicleDetailsForm.get('engineNumber').value,
              totalMileage: this.vehicleDetailsForm.get('totalMileage').value,
              electricMileage:this.vehicleDetailsForm.get('electricMileage').value,
              brandId: this.vehicleInfoData.brandId,
              modelId: this.vehicleInfoData.modelId,
              VehicleSeriesId: this.vehicleInfoData.vehicleSeriesId ? this.vehicleInfoData.vehicleSeriesId : this.vehicleSeriesId,
              }
  this.shareStepperData.stepValue({vehicleSeriesId: this.vehicleSeriesId,jobOrder: this.jobOrder, jobOrdersVehicleDetail: this.jobOrdersVehicleDetail})
  this.isVehicleAvailable.emit(false)
  }

  isFormValid(): boolean {
    const vehicleInfo = {
      jobOrderCreatedTime: this.vehicleDetailsForm.get("jobOrderCreatedTime").value,
      totalMileage : this.vehicleDetailsForm.get("totalMileage").value
    };
    return (vehicleInfo.jobOrderCreatedTime && vehicleInfo.totalMileage) ? false : true;
  }
}