import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";

const ELEMENT_DATA: any[] = [
  {
    position: 1,
    code: "WSC2ER3504010GH0",
    description: "Replacement of brake pedal assembly",
    startTime: "2024-03-18 21:53:28",
    labourHours: "1",
  },
  {
    position: 2,
    code: "WSC2ER0000003PJ0",
    description: "Discharging and filling in differential oil",
    startTime: "2024-03-18 21:53:28",
    labourHours: "0.5",
  },
  {
    position: 3,
    code: "WSC2ER5614140GH0",
    description: "Replace spoiler right trim panel assembly",
    startTime: "2024-03-18 21:53:28",
    labourHours: "0.5",
  },
  {
    position: 4,
    code: "WSC2ER0000003PJ0",
    description: "Replacement of brake pedal assembly",
    startTime: "2024-03-16 23:53:28",
    labourHours: "1",
  },
];

const sourceData: any[] = [
  {
    position: 1,
    code: "11477559-00",
    name: "Replacement of brake pedal assembly",
    quantity: "1",
    amount: "$218.50",
  },
  {
    position: 2,
    code: "12011024-00",
    name: "Replacement of brake pedal assembly",
    quantity: "1",
    amount: "$218.50",
  },
];

const sundriesData: any[] = [];

@Component({
  selector: "new-claim-form",
  templateUrl: "./new-claim-form.component.html",
  styleUrls: ["./new-claim-form.component.scss"],
})
export class NewClaimFormComponent implements OnInit {
  stepOneForm: FormGroup;
  panalOpenState: boolean = false;
  operationDetails: boolean = false;
  selected = "";
  displayedColumns: string[] = [
    "position",
    "code",
    "description",
    "startTime",
    "labourHours",
  ];

  displayedColumns2: string[] = [
    "position",
    "code",
    "name",
    "quantity",
    "amount",
  ];

  displayedColumns3: string[] = ["position", "code", "otherExpenses", "amount"];

  dataSource = ELEMENT_DATA;

  dataSource2 = sourceData;

  dataSource3 = sundriesData;

  constructor(private fb: FormBuilder, private router: Router) {
    this.stepOneForm = this.fb.group({
      orderNumber: this.fb.control(""),
      repairType: this.fb.control(""),
      vin: this.fb.control(""),
      totalMileage: this.fb.control(""),
      engineNumber: this.fb.control(""),
      brand: this.fb.control(""),
      createdTime: this.fb.control(""),
      vehicleSeries: this.fb.control(""),
      vehicleModel: this.fb.control(""),
      attchement : this.fb.control("")
    });
  }

  roDetails: string[] = ["OPERATION CODES", "SPARE PARTS", "SUNDRIES"];

  orderNumber: any[] = ["OATESTWRO240300002", "Testing", "Testing", "Testing"];

  orderNumbers = this.orderNumber;
  public filterList = this.orderNumber.slice();

  ngOnInit() {}

  onSelect(rodetail: any) {
    this.stepOneForm.get("orderNumber").patchValue(rodetail.value);

    if (rodetail.value !== null) {
      this.operationDetails = true;
    } else {
      this.operationDetails = false;
    }
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.orderNumbers.filter((option) =>
      option.toLowerCase().startsWith(filter)
    );
  }

  backDashboard() {
    this.router.navigate(["./"]);
  }

  onSubmit() {}
}
