import { Component, Input, Output, OnInit, Renderer2, EventEmitter, OnDestroy } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { UserQuery } from '../../../@core/akita-stores/stores/user/user.query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-manage-claimlist-layout',
  styleUrls: ['./manage-claimlist.layout.scss'],
  template: `
  <nb-layout>
  <nb-layout-column class="colored-column-basic">
  <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>
</nb-layout>
  `,
})
export class ManageClaimlistLayoutComponent implements OnInit, OnDestroy {

  @Output() calendarChange:EventEmitter<any> = new EventEmitter();

  isLogin$ = this.userQuery.isLogin$;
  protected destroy$ = new Subject<void>();

  constructor(
    private sidebarService: NbSidebarService,
    private renderer: Renderer2,
    private userQuery: UserQuery,
  ) { }

  ngOnInit() {
 
  }

  ngOnDestroy() {

  }

  toggle() {
    this.sidebarService.toggle(true, 'sidebar-bookings');
  }

//   onCalendarChange(event) {
//     this.calendarChange.emit(event);
//   }
}
