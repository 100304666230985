import { Component, OnInit, Input } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import * as moment from 'moment-timezone';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { getTimezone } from '../../../@core/akita-stores/storage';
import * as _ from 'lodash';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {
  bookingActivities;
  statusRefundedBookingActivities;
  statusCompletedBookingActivities;
  arrStatus = [];
  bookingId;
  bookingStatus = [];
  activityLog$;
  bookingDetails;
  isBookingDetailsLoading = false;
  bookingUserId;
  bookingCustomerName;
  bookingPaymentId;
  activityLogLoading = true;
  timezone = getTimezone();
  @Input() types: string;

  private destroy$ = new Subject<void>();
  constructor(
    private bookingsSelectedQuery: BookingDetailsSelectedQuery,
    private bookingsService: BookingDetailsSelectedService,
    private route: ActivatedRoute,
    private toastrService: NbToastrService,
    private configService: ConfigService,
  ) {
    this.bookingId = this.route.snapshot.params.bookingId;
  }

  ngOnInit() {
    this.activityLog();

    this.configService.refreshBookings.pipe(takeUntil(this.destroy$)).subscribe(isRefresh => {
      if (!isRefresh) return;
      this.activityLog();
    });
  }

  activityLog() {
    if (this.types === 'booking') {
      this.isBookingDetailsLoading = true;
      this.activityLog$ = this.bookingsService.getStatusList().pipe(
        takeUntil(this.destroy$),
        tap(async (res: any) => {
          this.bookingStatus = res.results
          await this.getBookingDetails();

        this.bookingsService.getBookingActivities(this.bookingId).subscribe((val: any) => {
          this.bookingActivities = val.results.reduce((activities, item, itemIndex) => {
            const tags = (item.tags || '').split(',');
            const activity = {
              icon: 'file',
              name: "Admin",
              time: null,
              event: null,
              oldValue: null,
              newValue: item.data && item.data.newBookingStatusId ? this.getStatusCode(item.data.newBookingStatusId, this.bookingStatus) : null,
              attention: null,
              total: item,
              status: item.data && item.data.status ? item.data.status : '',
            }

            if (item.createdBy) {
              // Customer action (heavy)
              if (item.createdBy === this.bookingUserId) {
                activity.name = `Customer - ${this.bookingCustomerName}`;
              }
              else if (item.createdBy === 'guest') {
                // Customer create booking action (lite)
                if (
                  tags.includes('create') &&
                  tags.includes('booking') &&
                  tags.includes('status')
                ) {
                  activity.name = `Customer - ${this.bookingCustomerName}`;
                }
              }
              // User action
              else if (_.get(item, "createdUser.userId")) {
                activity.name = `${_.get(item, "createdUser.role")} - ${_.get(item, "createdUser.firstName")} ${_.get(item, "createdUser.lastName")}`;
              }
              // Default (no expected)
              else {
                activity.name = "Admin";
              }
            }

            // Create action
            if (tags.includes('create')) {
              activity.icon = 'file-plus';
              activity.time = moment.utc(item.createdAt).tz(this.timezone).format('DD/MM/YYYY - hh:mm A');
              // Create Booking
              if (tags.includes('booking')) {
                activity.event = 'Booking Created';
              }
              // Create Booking Payment
              if (tags.includes('booking') && tags.includes('bookingpayment')) {
                activity.event = 'Booking payment';
              }

              activities.push(activity);
            }

            // Update action
            else if (tags.includes('update')) {
              activity.icon = 'file';
              activity.time = moment.utc(item.updatedAt).tz(this.timezone).format('DD/MM/YYYY - hh:mm A');

              // Update Booking Status
              if (tags.includes('status')) {
                activity.oldValue = this.getStatusCode(item.data.oldBookingStatusId, this.bookingStatus);
                activity.newValue = this.getStatusCode(item.data.newBookingStatusId, this.bookingStatus);
                activity.event = activity.oldValue ? 'Status has been changed from' : 'Status change to';
                activities.push(activity);
              }

              // Update Appointment
              else if (tags.includes('appointment')) {
                activities.push({
                  ...activity,
                  icon: 'calendar',
                  event: 'Appointment change to',
                  attention: moment(_.get(item, 'data.newBookedAt')).format('DD/MM/YYYY - hh:mm A'),
                });
              }

              // Update Appointment Ref
              else if (tags.includes('appointmentRef')) {
                activities.push({
                  ...activity,
                  ...this.getDataValueChanged(item.data, 'AppointmentRef'),
                  event: 'Appointment Ref has been changed from',
                });
              }

              // Update Booking Admin Note
              else if (tags.includes('admin-booking-note')) {
                activities.push({
                  ...activity,
                  ...this.getDataValueChanged(item.data, 'AdminBookingNote'),
                  event: 'Booking Note has been changed from',
                });
              }

              // Update Booking Customer
              else if (tags.includes('customer')) {
                // Update Customer First Name
                if (tags.includes('firstName')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'FirstName'),
                    event: 'Customer First Name has been changed from',
                  });
                }

                // Update Customer Last Name
                if (tags.includes('lastName')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'LastName'),
                    event: 'Customer Last Name has been changed from',
                  });
                }

                // Update Customer Phone
                if (tags.includes('contact') && tags.includes('phone')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'Phone'),
                    event: 'Customer Phone has been changed from',
                  });
                }

                // Update Customer Email
                if (tags.includes('contact') && tags.includes('email')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'Email'),
                    event: 'Customer Email has been changed from',
                  });
                }

                // Update Customer Address
                if (tags.includes('address') && tags.includes('streetLine')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'StreetLine1'),
                    event: 'Customer Home Address has been changed from',
                  });
                }

                // Update Customer State
                if (tags.includes('state')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'State'),
                    event: 'Customer State has been changed from',
                  });
                }

                // Update Customer Post Code
                if (tags.includes('postCode')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'PostCode'),
                    event: 'Customer Post Code has been changed from',
                  });
                }

                // Update Customer Suburb
                if (tags.includes('suburb')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'Suburb'),
                    event: 'Customer Suburb has been changed from',
                  });
                }

                // return activities;
              }

              // Update Booking Vehicle
              else if (tags.includes('vehicle')) {
                // Update Vehicle Odometer
                if (tags.includes('odometer')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'Odometer'),
                    event: 'Vehicle Odometer has been changed from',
                  });
                }

                // Update Vehicle Model
                if (tags.includes('model')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'Model'),
                    event: 'Vehicle Model has been changed from',
                  });
                }

                // Update Vehicle Make 
                if (tags.includes('make')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'Make'),
                    event: 'Vehicle Make has been changed from',
                  });
                }

                // Update Vehicle Year
                if (tags.includes('year')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'Year'),
                    event: 'Vehicle Year has been changed from',
                  });
                }

                // Update Vehicle Variant
                if (tags.includes('variant')) {
                  activities.push({
                    ...activity,
                    ...this.getDataValueChanged(item.data, 'Variant'),
                    event: 'Vehicle Variant has been changed from',
                  });
                }

              }
            }
            
            return activities;
          }, []);


          this.activityLogLoading = false;

          this.statusRefundedBookingActivities = val.results.find(element => element.data.status == "refunded");
          this.statusCompletedBookingActivities = val.results.find(element => element.data.status == "completed");
          if (this.statusRefundedBookingActivities) {
            this.arrStatus.push(this.statusRefundedBookingActivities)
          }
          if (this.statusCompletedBookingActivities) {
            this.arrStatus.push(this.statusCompletedBookingActivities)
          }
        });
      }),
      catchError((err) => {
        this.activityLogLoading = false;
        this.isBookingDetailsLoading = false;
        return err;
      }),
    ).subscribe();
    }
  }

  getStatusCode(statusId: number, statusList: any) {
    let statusCode;
    for (let i = 0; i < statusList.length; i++) {
      if (statusId === statusList[i].id) {
        statusCode = statusList[i].description || statusList[i].code.split('-').join(' ');
      }
    }
    return statusCode;
  };

  private getDataValueChanged(data: any, field: string, placeholder = '-') {
    if (!data || typeof data !== 'object' || !field) return null;
    
    const oldValue = data[`old${field}`];
    const newValue = data[`new${field}`];
    
    return {
      oldValue: oldValue ? `"${oldValue}"` : placeholder,
      newValue: newValue ? `"${newValue}"` : placeholder,
    };
  }

  async getBookingDetails() {
    await this.bookingsService.getBookingDetails(this.bookingId).toPromise();
    this.isBookingDetailsLoading = false;
    this.bookingDetails = this.bookingsSelectedQuery.getValue();
    this.bookingPaymentId = _.get(this.bookingDetails, 'bookingPayments[0].id');
    this.bookingUserId = _.get(this.bookingDetails, "userId") || _.get(this.bookingDetails, 'customer.userId');
    this.bookingCustomerName = this.bookingDetails.customer.firstName + ' ' + this.bookingDetails.customer.lastName;
  };
  deleteLogActivities() {
    this.bookingsService.deleteBookingActivities(this.bookingId, this.bookingPaymentId).subscribe(() => {
      this.toastrService.success("Booking refunded successfully");
      this.activityLog()
    })
  }

  ngOnDestroy() {
    if (this.activityLog$) this.activityLog$.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
