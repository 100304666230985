import { Component,EventEmitter,OnInit,Optional,Output,TemplateRef,ViewChild } from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import { FormControl,FormGroup,Validators } from "@angular/forms";
import { WarrantyClaimService } from '../../../@core/akita-stores/stores/warranty-claim/warranty-claim.service';
import { NbDialogRef,NbDialogService } from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { StepperDataService } from "../../../@core/utils/stepper-data.service";
import { take } from "rxjs/operators";
import { SelectionModel } from "@angular/cdk/collections";
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';

interface jobOperationCodeInfoList {
  operationCode: string;
  codeName: string;
  operationFaultCode: string;
  processingCode :string;
  unitPrice : any;
  isClaimCreated:boolean;
}

@Component({
  selector: 'operation-code-info',
  templateUrl: './operation-code-info.component.html',
  styleUrls: ['./operation-code-info.component.scss'],
})
export class OperationCodeInfoComponent implements OnInit {
  displayedColumns: string[] = [
    'checkSelect',
    'tableSRNumber',
    'operationCode',
    'operationCodeName',
    'operationFaultCode',
    'processingCode',
    'unitPrice',
    'action',
  ];
  dataSource: any[] = [];
  public stepThreeForm: FormGroup;
  orderNumbers: string[] = [];
  codeFilter: string[] = [];
  unitPrice: any;
  faultCodeValue: string[] = [];
  processingCodeValue: string[] = [];
  faultFilter: string[] = [];
  processingFilter: string[] = [];
  operationCodeId: any;
  editPosition: number = 0;
  selectedIndex: number = 0;
  operationList: jobOperationCodeInfoList[] = [];
  @ViewChild('discardDialog', { static: false })
  discardDialog: TemplateRef<any>;
  jobOrderID: number;
  laborId;
  operationDetailsDialog: FormGroup;
  dialogValue: any;
  record: {
    operationCode: any;
    codeName: any;
    faultCode: any;
    processingCode: any;
    unitPrice: any;
  };
  unitPriceValue: any;
  jobOrder: any;
  claimInfoObj: any;
  isClaimCreated: boolean = true;
  faultcodeId: any;
  processingCodeId: any;
  @Output() isOperationCodeInfoAvailable = new EventEmitter<boolean>();
  selection: any = {};
  isClaimEditable: string;
  getLabourCodeId: any;
  processingCodeById: any;
  editOperationCodeId: any;
  checkSelect: boolean =false;
  constructor(
    private activateRoute: ActivatedRoute,
    private warrantyClaimService: WarrantyClaimService,
    private dialogService: NbDialogService,
    @Optional() private dialogRef: NbDialogRef<any>,
    private shareStepperData: StepperDataService,
    private configService: ConfigService,
    private router: Router
  ) {
    this.stepThreeForm = new FormGroup({
      operationCode: new FormControl(''),
      operationName: new FormControl(''),
      operationAmount: new FormControl(''),
      faultCode: new FormControl(''),
      processingCode: new FormControl(''),
      unitPrice: new FormControl(''),
    });

    this.operationDetailsDialog = new FormGroup({
      operationCodeValue: new FormControl(''),
      opeartionNameValue: new FormControl(''),
      operationFaultCodeValue: new FormControl(''),
      operationProcessingValue: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
      this.isClaimEditable = params['isClaimEditable'];
    });
    this.shareStepperData.claimStepperFormValueShare
      .pipe(take(1))
      .subscribe((res) => {
        this.claimInfoObj = res.claim;
        if (this.isClaimEditable == 'false') {
          this.jobOrderID = res.claim.JobOrderNumber;
          this.getClaimDetailsByFromList(this.jobOrderID);
        } else {
          this.activateRoute.queryParams.subscribe((params) => {
            this.jobOrderID = +params['id'];
            this.getOperationList(this.jobOrderID);
          });
        }       
      });
  }

  onClearOperationDetailsDialoge(event: any) {
    this.operationDetailsDialog.patchValue({
      operationFaultCodeValue: '',
    });
  }

  onClearOperationProcessingValue(event: any) {
    this.operationDetailsDialog.patchValue({
      operationProcessingValue: '',
    });
  }

  getOperationList(id) {
    this.warrantyClaimService
      .getClaimDetailsById(id)
      .pipe()
      .subscribe((x: any) => {
        const operationDetails = x;
        this.dataSource = operationDetails.jobOrdersOperationDetails;
      });
  }

  getClaimDetailsByFromList(id) {
    this.warrantyClaimService
      .getClaimDetailsByIdFromList(id)
      .pipe()
      .subscribe((res: any) => {
        const operationDetails = res;
        this.editOperationCodeId = operationDetails.claimOperationDetails[0].id;
        this.dataSource = operationDetails.claimOperationDetails;
        this.dataSource = [...this.dataSource];
        if(this.isClaimEditable == 'false') {
          this.selection = this.dataSource[0];
          this.checkSelect = true;
        }
        this.getLabourCodeId = res.id;
        this.dataSource.forEach((row) => {
          if (
            row.operationFaultCode.description !== null &&
            row.processingCode.description !== null
          ) {
            row.unitPrice = 115;
          } else {
            row.unitPrice = null;
          }
        });
      });
  }

  async editOperation(index: number, element) {
    this.editPosition = index;
    this.dialogRef = this.dialogService.open(this.discardDialog);
    this.dialogRef.onClose.subscribe(async (val) => {
      if (val === 'done') {
        const faultCodeValue = this.operationDetailsDialog.get(
          'operationFaultCodeValue'
        ).value;
        const processingValue = this.operationDetailsDialog.get(
          'operationProcessingValue'
        ).value;

        const record = {
          operationCode: this.operationDetailsDialog.value.operationCodeValue,
          operationCodeName:
            this.operationDetailsDialog.value.opeartionNameValue,
          operationFaultCode: this.faultFilter.reduce(
            (foundDescription, object: any) => {
              if (object.id == faultCodeValue) {
                return object.description;
              }
              return foundDescription;
            },
            null
          ),
          processingCode: this.processingFilter.reduce(
            (foundDescription, object: any) => {
              if (object.id == processingValue) {
                return object.description;
              }
              return foundDescription;
            },
            ''
          ),
          unitPrice: this.unitPrice,
          id: element.id || 0,
          labourCodeId: element.labourCode
            ? element.labourCode.id
            : element.labourCodeId
            ? element.labourCodeId
            : this.operationCodeId,
        };

        this.dataSource[this.editPosition] = record;
        this.dataSource = [...this.dataSource];
      } else if (val === 'close') {
        this.dialogRef.close();
      }
    });

    if (
      element.operationFaultCode &&
      element.processingCode &&
      this.isClaimEditable !== 'false'
    ) {
      this.operationDetailsDialog
        .get('operationCodeValue')
        .patchValue(element.operationCode);
      this.operationDetailsDialog
        .get('opeartionNameValue')
        .patchValue(element.operationCodeName);

      const result = await this.warrantyClaimService
        .getOperationFaultCodeInfo(element.labourCodeId)
        .toPromise();
      const processingCodeId = await this.processingFilter.reduce(
        (foundDescription, object: any) => {
          if (object.description === element.processingCode) {
            return object.id;
          }
          return foundDescription;
        },
        null
      );

      const operationFaultCodeId = await this.faultFilter.reduce(
        (foundDescription, object: any) => {
          if (object.description === element.operationFaultCode) {
            return object.id;
          }
          return foundDescription;
        },
        null
      );

      this.operationDetailsDialog
        .get('operationFaultCodeValue')
        .patchValue(operationFaultCodeId);
      this.operationDetailsDialog
        .get('operationProcessingValue')
        .patchValue(processingCodeId);
      this.dataSource = [...this.dataSource];
    } else if (this.isClaimEditable === 'false') {
      this.configService.setLoading(true);
      this.operationCodeId = element.labourCode.id;
      this.operationDetailsDialog
        .get('operationCodeValue')
        .patchValue(element.labourCode.code);
      this.operationDetailsDialog
        .get('opeartionNameValue')
        .patchValue(element.labourCode.description);
      try {
        await this.getFaultCode(this.operationCodeId);
        const result = await this.warrantyClaimService
          .getOperationFaultCodeInfo(this.operationCodeId)
          .toPromise();
        const faultCodeById: any = await this.faultFilter.find(
          (object: any) => {
            if (object.description === element.operationFaultCode.description) {
              return object.id;
            }
            return null;
          }
        );

        if (faultCodeById) {
          this.faultcodeId = faultCodeById.id;
          this.operationDetailsDialog
            .get('operationFaultCodeValue')
            .patchValue(this.faultcodeId);

          await this.getProcessingCode(this.faultcodeId);
          const result = await this.warrantyClaimService
            .getOperationProcessingCodeInfo(this.faultcodeId)
            .toPromise();
          const processingCodeById: any = await this.processingFilter.find(
            (object: any) => {
              if (object.description === element.processingCode.description) {
                return object.id;
              }
              return null;
            }
          );

          if (processingCodeById) {
            this.processingCodeById = processingCodeById.id;
            this.operationDetailsDialog
              .get('operationProcessingValue')
              .patchValue(this.processingCodeById);
            this.onSelectProcessingCode(this.processingCodeById);
            this.getLabourUnitPrice(1);
          }
        }
      } finally {
        this.configService.setLoading(false);
      }
    } else {
      this.operationDetailsDialog
        .get('operationCodeValue')
        .patchValue(element.labourCode.code);
      this.operationDetailsDialog
        .get('opeartionNameValue')
        .patchValue(element.labourCode.description);
      this.laborId = element.labourCode.id;
      this.getFaultCode(this.laborId);
    }
  }

  isOperationFaultCodeValid(): boolean {
    const record = {
      operationFaultCode: this.operationDetailsDialog.get(
        'operationFaultCodeValue'
      ).value,
    };
    return record.operationFaultCode ? false : true;
  }

  getFaultCode(id) {
    this.warrantyClaimService
      .getOperationFaultCodeInfo(id)
      .pipe()
      .subscribe((x: any) => {
        this.faultCodeValue = x.results;
        this.faultFilter = this.faultCodeValue.slice();
      });
  }

  onSelectFaultCode(event) {
    this.getProcessingCode(event.value);
    this.faultcodeId = event.value;
  }

  onCheckboxChange(element) {
    this.selection = element;
  }

  getProcessingCode(id) {
    this.warrantyClaimService
      .getOperationProcessingCodeInfo(id)
      .pipe()
      .subscribe((x: any) => {
        this.processingCodeValue = x;
        this.processingFilter = this.processingCodeValue.slice();
      });
  }

  onSelectProcessingCode(event) {
    this.getLabourUnitPrice(1); //To fetch the unit price, we are using one hardcoded dealerCodeId.
    this.processingCodeId = event.value;
  }

  getLabourUnitPrice(id) {
    this.warrantyClaimService
      .getOperationLabourUnitPriceInfo(id)
      .pipe()
      .subscribe((x: any) => {
        this.unitPrice = x.results[0].sysConfigValue;
      });
  }

  onSelect(event) {
    this.stepThreeForm.get('operationName').patchValue(event.value.description);
  }

  numberOnly(event): boolean {
    if (
      !(
        (event.keyCode > 95 && event.keyCode < 106) ||
        (event.keyCode > 47 && event.keyCode < 58) ||
        event.keyCode == 8 ||
        event.keyCode == 46 ||
        event.keyCode == 110
      )
    ) {
      return false;
    }
  }

  close(): void {
    this.dialogRef.close('close');
  }

  saveDialogDetails() {
    this.dialogRef.close('done');
    this.operationDetailsDialog.get('operationProcessingValue').setValue(' ');
    this.operationDetailsDialog.get('operationFaultCodeValue').setValue(' ');
  }

  onSubmit() { 
    const operationClaim = [];
    let operationObj: any = {};
    if (
      this.selection.operationCodeName !== undefined ||
      this.selection.labourCode.description !== null
    ) {
      if (this.isClaimEditable == 'false') {
        operationObj.operationId = this.editOperationCodeId;
      }

      operationObj.operationCodeId = this.laborId
        ? this.laborId
        : this.operationCodeId
        ? this.operationCodeId
        : this.selection.labourCode.id;
      operationObj.operationName =
        this.selection && this.selection.operationCodeName
          ? this.selection.operationCodeName
          : this.selection &&
            this.selection.operationCode &&
            this.selection.operationCode.description
          ? this.selection.operationCode.description
          : this.selection &&
            this.selection.labourCode &&
            this.selection.labourCode.description
          ? this.selection.labourCode.description
          : undefined;
      operationObj.amount = this.selection.unitPrice || 115;
      operationObj.faultCodeId = this.faultcodeId
        ? this.faultcodeId
        : this.selection &&
          this.selection.faultCode &&
          this.selection.faultCode.id
        ? this.selection.faultCode.id
        : this.selection &&
          this.selection.operationFaultCode &&
          this.selection.operationFaultCode.id
        ? this.selection.operationFaultCode.id
        : undefined;
      operationObj.operationUnitPriceCodeId = 1;
      operationObj.processingCodeId = this.processingCodeId
        ? this.processingCodeId
        : this.processingCodeById
        ? this.processingCodeById
        : this.selection.processingCode.id;
    }

    if (
      this.selection.operationFaultCode !== null &&
      this.selection.processingCode !== null &&
      (this.selection.operationCodeName !== undefined ||
        this.selection.labourCode.description !== null)
    ) {
      operationClaim.push(operationObj);
    } else {
      operationClaim.push();
    }
    if (
      operationClaim.length > 0 &&
      this.selection.operationFaultCode &&
      this.selection.processingCode
    ) {
      this.isOperationCodeInfoAvailable.emit(false);
    } else {
      this.isOperationCodeInfoAvailable.emit(true);
    }
    const claimsOperationData =
      this.isClaimEditable === 'false' ? operationObj : operationClaim;
    this.shareStepperData.saveStepValueObject({
      claim: this.claimInfoObj,
      claimsOperation: claimsOperationData,
    });
  }

  backToClaimList() {
    this.router.navigate(['/pages/warranty']);
  }
}
