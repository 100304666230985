import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { EditBookingDlgComponent } from '../edit-booking-dlg/edit-booking-dlg.component';
import { BookingDetailsSelectedQuery } from './../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { BookingDetails, TimeSlot } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { BookingDetailsSelectedStore } from './../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { CommonService } from '../../../@core/utils/common.service';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { ConfigQuery } from '../../../@core/akita-stores/stores/config/config.store';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatDatepickerInputEvent, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';
import { VehiclesService } from '../../../@core/akita-stores/entity-stores/vehicles/vehicles.service';
import { Subject } from 'rxjs';
import { ServiceCentersQuery } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.query';
import { ServiceCentersService } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.service';
import { BookingCancelDialogComponent } from '../booking-cancel-dialog/booking-cancel-dialog.component';
import { Beacon } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.store';
import { takeUntil, debounceTime, distinctUntilChanged, first, skip } from 'rxjs/operators';
import { getTimezone } from '../../../@core/akita-stores/storage';
import * as momentTimezone from 'moment-timezone';
import { ServiceRecommendationsComponent } from '../service-recommendations/service-recommendations.component';
import { PermissionsService } from './../../../@core/utils/permissions.service';
import { BOOKING_PERMISSIONS, CUSTOMER_PERMISSIONS, VEHICLE_PERMISSIONS } from '../../../constants';
import { BOOKING_STATUSES, STATUS_AFTER_CHECKED_IN } from '../../../constants/status';
import { BayService } from '../../../@core/akita-stores/entity-stores/bays/bays.service';
import { BookingListService } from '../../../@core/akita-stores/entity-stores/bookings/bookings.service';
import { SessionService } from '../../../@core/akita-stores/stores/session/session.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const timezone = getTimezone();
momentTimezone.tz.setDefault(timezone);

@Component({
  selector: 'sidebar-booking',
  templateUrl: './sidebar-booking.component.html',
  styleUrls: ['./sidebar-booking.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SidebarBookingComponent implements OnInit, OnDestroy {
  public tags = []

  @Input('action') action;
  isLiteBooking: boolean = false;
  bookingDetails: BookingDetails;
  customerDetailPath: string;
  vehicleDetailPath: string;
  isUneditableMode: boolean = true;
  statuses = [];
  showSelected: boolean = true;
  tagCheckIn = {};
  isAwait: boolean = true;
  timeSlotsAvaiable: any = [];
  odoInputTimeout: any;
  hasTimeAvaiable: boolean = false;
  bays: any = [];
  baySelected: any;
  model: {
    bay: any;
    dateBooking: any;
    timeBooking: any;
  };
  serviceTypeId;
  jobIds: any;
  isEditAppointment: boolean = false;
  isEditAppointmentRef: boolean = false;
  loadingAppointmentRef: boolean = false;
  appointmentRef = '';
  loadingAppointment: boolean = false;
  dateToday = new Date();
  dateBooking: string;
  odometer;
  bayBooking: string = '--';
  isPreferContactOnMobile: boolean = false;
  customerNote: string;
  keyupTimeout;
  beaconsInCenter: Beacon[] = [];
  beacons: Beacon[] = [];
  selectBeacons: Beacon[] = [];
  selectedBeaconId: number;
  beaconAssignedDate: string = '';
  loadingBeacon = true;
  protected destroy$ = new Subject<void>();
  serviceRecommendations = [];
  userHasPermissionToUpdateBooking: boolean;
  bookingPermissions = BOOKING_PERMISSIONS;
  vehiclePermissions = VEHICLE_PERMISSIONS;
  customerPermissions = CUSTOMER_PERMISSIONS;
  isDisabledStatusSelect: boolean = false;
  selectedBay = 0;
  getServiceRecommendations$;
  selectStatusTimeout$;
  isInvoiceDownloadable: boolean = false;
  bookingStatuses = BOOKING_STATUSES;
  hasFDService: boolean = false;
  isHeavyWorkflow: boolean = false;

  constructor(
    private router: Router,
    private dialogService: NbDialogService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private bookingDetailsQuery: BookingDetailsSelectedQuery,
    private bookingStore: BookingDetailsSelectedStore,
    private bookingDetailsService: BookingDetailsSelectedService,
    private configQuery: ConfigQuery,
    private bayService: BayService,
    private vehicleService: VehiclesService,
    private toastrService: NbToastrService,
    private configService: ConfigService,
    private serviceCentersQuery: ServiceCentersQuery,
    private serviceCentersService: ServiceCentersService,
    private bookingService: BookingDetailsSelectedService,
    private permissionsService: PermissionsService,
    private bookingListService: BookingListService,
    private sessionService: SessionService,
  ) {
    this.model = {
      bay: '',
      dateBooking: '',
      timeBooking: ''
    }
  }

  async getBays() {
    const centers = await this.serviceCentersService.getListServiceCenter().toPromise().catch(err => console.error(err));
    this.serviceCentersQuery.serviceCenterSeleted$.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      if (data && data[0]) {
        this.handleGetBays(data[0].id);
      } else if (centers && centers[0]) {
        this.handleGetBays(centers[0].id);
      } else {
        this.router.navigate(['pages/booking']);
      }
    })
  }

  handleGetBays(centerId: number) {
    this.bayService.getBays(centerId, this.bookingDetailsQuery.getValue().serviceType.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.bays = (_.get(res, "results") || []) || [];
        this.bookingDetails = this.bookingDetailsQuery.getValue();
        this.model.bay = _.get(this.bookingDetails, 'bay.id');
        this.handleLoadAppoiment();
      });
  }

  async ngOnInit() {
    this.userHasPermissionToUpdateBooking = await this.permissionsService.userHasPermissionsToUpdateBooking(false);
    this.serviceCentersQuery.serviceCenterSeleted$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(async (serviceCenterSelected: any) => {
      if (!_.get(serviceCenterSelected, 'length')) return;
      this.hasFDService = _.get(serviceCenterSelected, "[0].hasFlightDeck", false);
      this.isHeavyWorkflow = _.get(serviceCenterSelected, "[0].bookingServicesConfigs.useEraPower", false);
  
      if (this.isHeavyWorkflow && this.hasFDService) {
        this.getBays();
        await this.getBeacons();
      }
      this.bookingDetailsSelectedChange();
    });
  }

  async getBeacons() {
    this.beacons = await this.serviceCentersService.getBeaconsInCenter().pipe(first()).toPromise();
    this.getSelectBeacons(this.beacons);
  }

  getSelectBeacons(beacons: Beacon[]) {
    // this.selectBeacons = [];
    this.selectBeacons = beacons.filter(beacon => beacon.isActive);
  }

  bookingDetailsSelectedChange() {
    this.bookingDetailsQuery.bookingDetailsSelected$
      .pipe(
        skip(1), // this skip will ignore the initial value in store for correct bookingDetails
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy$))
      .subscribe(val => {
        this.bookingDetails = Object.assign({}, val);
        this.isLiteBooking = this.bookingService.isLiteBooking(this.bookingDetails);
        this.appointmentRef = this.bookingDetails.appointmentRef || '-';
        this.selectedBeaconId = this.bookingDetails.beacon ? this.bookingDetails.beacon.id : 0;
        this.handleSelectStatus();
        this.handleSelectBeacon();
        this.getRecommendations();
        this.isPreferContactOnMobile = this.bookingDetails.customerContact.contact.type === "phone" ? true : false;
        this.customerNote = this.bookingDetails.customerNote;
        this.odometer = _.cloneWith(this.bookingDetails.vehicle.odometer);
        this.configService.setInputVehicle({ odometer: this.odometer });
        this.isUneditableMode = this.commonService.isUneditableMode(this.bookingDetails) || !this.userHasPermissionToUpdateBooking;
        this.showSelected = false;
        this.handleStatusPayment();

        const customerId = _.get(this.bookingDetails, 'customer.id');
        this.customerDetailPath = customerId ? `/pages/customers/main-customer/customer-details/${customerId}` : '';
        const vehicleId = _.get(this.bookingDetails, 'vehicle.id');
        this.vehicleDetailPath = vehicleId ? `/pages/customers/main-customer/vehicles/${customerId}/vehicles-details/${vehicleId}` : '';

        try {
          this.cdr.detectChanges();
        } catch (e) { }

        this.bookingDetails.vehicle = Object.assign({}, this.bookingDetails.vehicle);

        if (this.bookingDetails.vehicle.odometer === null || this.bookingDetails.vehicle.odometer === undefined) {
          this.bookingDetails.vehicle.odometer = 0;
        };

        this.showSelected = true;
        if (this.bays.length > 0) this.handleLoadAppoiment();
      });
  }

  handleSelectBeacon() {
    if (this.bookingDetails && this.bookingDetails.beacon) {
      const findBeacon = this.selectBeacons.filter(beacon => beacon.id === this.bookingDetails.beacon.id);
      if (!findBeacon || findBeacon.length === 0) {
        const beaconActive = Object.assign({}, this.bookingDetails.beacon);
        beaconActive.isActive = true;
        this.selectBeacons.unshift(...[beaconActive]);
        // this.selectedBeacon = this.selectBeacons[0];
      } else {
        // this.selectedBeacon = findBeacon[0];
      }
      if (this.bookingDetails.beaconUpdatedAtUtc) {
        this.beaconAssignedDate = momentTimezone(this.bookingDetails.beaconUpdatedAtUtc + '+0000').format('hh:mm A DD/MM/YYYY');
      }
    }
    this.loadingBeacon = false;
  }

  async handleSelectStatus() {
    if (this.bookingDetails.id && this.bookingDetails.bookedAt) {
      const bookingStatusCode = _.get(this.bookingDetails, 'bookingStatus.code');
      if (bookingStatusCode === this.bookingStatuses.cancelled) {
        this.isDisabledStatusSelect = true;
        return;
      }
      const isLiteBooking = this.bookingService.isLiteBooking(this.bookingDetails);

      const statuses: any = await this.commonService.getBookingStatus(this.bookingDetails.id).pipe(takeUntil(this.destroy$)).toPromise();
      // MO-1424
      const confirmStatusLimit = [
        this.bookingStatuses.serviceBooked,
        this.bookingStatuses.serviceConfirmed,
        this.bookingStatuses.contactAttempted,
      ] // status will limit changes to this list

      if (confirmStatusLimit.includes(bookingStatusCode) && isLiteBooking) {

        let added = [];
        this.statuses = _.get(statuses, "results", []).filter((item) => {
          if (item.bookingStatus.code != bookingStatusCode && confirmStatusLimit.includes(item.bookingStatus.code) && !added.includes(item.bookingStatus.code)) {
            added.push(item.bookingStatus.code);
            return item;
          } else {
            return false;
          }
        }).map(status => {
          return { name: status.bookingStatus.description, types: 'status', code: status.bookingStatus.code };
        });
        this.isAwait = false;

      } else {
        if (isLiteBooking) {
          this.isAwait = true;
          return;
        }
        let added = [];
        const statusesAfterCheckIn = _.get(statuses, "results", []).filter((item) => {
          if (item.bookingStatus.code == this.bookingStatuses.pickedup) return;
          if (STATUS_AFTER_CHECKED_IN.includes(item.bookingStatus.code) && !added.includes(item.bookingStatus.code) && item.bookingStatus.code != bookingStatusCode) {
            added.push(item.bookingStatus.code);
            return item;
          }
        }).map(status => {
          return { name: status.bookingStatus.description, types: 'status', code: status.bookingStatus.code };
        });
        this.statuses = statusesAfterCheckIn;
        this.isAwait = this.commonService.isStatusBeforeCheckedIn(this.bookingDetails.bookingStatus.code) || this.bookingDetails.bookingStatus.code === this.bookingStatuses.readyForPickup || this.bookingDetails.bookingStatus.code === this.bookingStatuses.pickedup ? true : false;

      }
      this.handleDocumentsStatus(_.get(statuses, "results", []));
    }
  }

  handleStatusPayment() {
    this.tags = [
      {
        types: 'type',
        code: this.bookingDetails.serviceType.code,
        class: 'tag-service'
      },
      {
        types: 'status',
        code: this.bookingDetails.bookingStatus.code
      },
    ];

    if(this.isHeavyWorkflow) {
      this.tags.push({
        types: 'type',
        code: this.bookingService.getPaymentCode()
      })
    }
  }

  goBack() {
    this.commonService.resetStore();
    this.router.navigateByUrl('/reload', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/pages/booking']));
  }

  openDlg(hasBackdrop: boolean = true) {
    this.dialogService.open(EditBookingDlgComponent, { hasBackdrop, context: { type: 'edit-booking' } });
  }

  async updateOdometer($e) {
    if ($e.which !== 8 && ($e.keyCode < 96 || $e.keyCode > 105)) return;
    clearTimeout(this.odoInputTimeout);
    this.odoInputTimeout = setTimeout(() => {
      if (!this.userHasPermissionToUpdateBooking) {
        this.permissionsService.showPermissionError();
        return;
      }
      if (this.bookingDetails.serviceType.code === 'express') {
        this.vehicleService.updateAnVehicle({ id: this.bookingDetails.vehicle.id, odometer: this.odometer })
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            const bookingDetails = Object.assign({}, this.bookingDetails);
            bookingDetails.vehicle.odometer = this.odometer;
            this.bookingStore.updateStore(bookingDetails);
          }, () => {
            this.odometer = _.cloneWith(this.configQuery.getValue().clonnedBookingDetailsSelected.vehicle.odometer);
          });
      } else {
        const vehicle = Object.assign({}, this.bookingDetails.vehicle);
        vehicle.odometer = this.odometer;
        this.configService.setInputVehicle(vehicle);
      }
    }, 500);
  }

  async updateStatus(item) {
    this.isDisabledStatusSelect = true;
    this.userHasPermissionToUpdateBooking = await this.permissionsService.userHasPermissionsToUpdateBooking(true);

    if (!this.userHasPermissionToUpdateBooking) return;

    this.showSelected = false;
    const status = {
      description: item.name,
      code: item.code
    };
    this.cdr.detectChanges();
    this.bookingDetails = _.cloneDeepWith(this.bookingDetails);
    this.bookingDetails.bookingStatus = Object.assign({}, status);
    this.bookingStore.updateStore(this.bookingDetails);
    this.showSelected = true;

    this.bookingDetailsService.updateBookingStatus({ bookingStatus: status.code })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.configService.refreshBookings.next(true);
        this.isDisabledStatusSelect = false
      }, () => {
        try {
          this.bookingDetails.bookingStatus = Object.assign({}, this.configQuery.getValue().clonnedBookingDetailsSelected.bookingStatus);
          this.bookingStore.updateStore(this.bookingDetails);
          this.showSelected = false;
          this.cdr.detectChanges();
          this.showSelected = true;
          this.isDisabledStatusSelect = false;
        } catch (e) { }
      });
  }

  cancelBooking() {
    this.dialogService.open(BookingCancelDialogComponent, { hasBackdrop: true, closeOnBackdropClick: false });
  }

  pairingDateTime(date: string, time: string) {
    return `${date} ${time}`;
  }

  handleBays() {
    if (this.bays.length > 0) {
      this.baySelected = this.bays.filter((bay: any) => bay.id === _.get(this.bookingDetails, 'bay.id'))[0];
      this.bayBooking = this.handleBayBooking(this.baySelected);
    }
  }

  handleBayBooking(baySelected) {
    return baySelected && baySelected.serviceTypeDescription ? baySelected.bayNo + ' - ' + baySelected.serviceTypeDescription : '--';
  }

  handleLoadAppoiment() {
    this.model.dateBooking = this.bookingDetails.bookedAt;
    this.handleBays();
    this.jobIds = this.bookingDetails.jobs.length > 0 ? this.bookingDetails.jobs.map(job => job.id) : null;
    const dateBooking = moment(this.bookingDetails.bookedAt).format('YYYY-MM-DD');
    this.handleGenerateTimeSlots(dateBooking);
  }

  private handleGenerateTimeSlots(_date: string) {
    let serviceTypeId = environment.serviceType[0].id;
    let index = _.findIndex(environment.serviceType, (o) => {
      return o.code == (this.bookingDetails.serviceType != null && this.bookingDetails.serviceType.code);
    });
    if (index > -1) serviceTypeId = environment.serviceType[index].id;
    if (this.baySelected) {
      this.bookingDetailsService.getTimeSlots(_date, serviceTypeId, this.baySelected.id, this.jobIds[0])
        .pipe(takeUntil(this.destroy$))
        .subscribe(timeSlots => {
          this.timeSlotsAvaiable = [];
          const timeSlotsAvaiable = timeSlots.filter(slot => slot.isAvailable);
          timeSlotsAvaiable.forEach(item => this.timeSlotsAvaiable.push({ ...item, formatSlot: moment(item.slot, 'hh:mm A').format('hh:mm A') }));
          const isTimeCheckedAvaiable = timeSlotsAvaiable.some(item => item.slot === moment(this.bookingDetails.bookedAt).format('HH:mm:ss'));
          this.hasTimeAvaiable = timeSlotsAvaiable.length > 0 ? true : false;
          this.model.timeBooking = isTimeCheckedAvaiable
            ? moment(this.bookingDetails.bookedAt).format('HH:mm:ss')
            : (this.hasTimeAvaiable ? timeSlotsAvaiable[0].slot : '');
        });
    }
  }

  onChangeBay($bayId) {
    this.baySelected = this.bays.filter((bay: any) => bay.id === $bayId)[0];
    const dateBooking = moment(this.model.dateBooking).format('YYYY-MM-DD');
    this.handleGenerateTimeSlots(dateBooking);
  }

  changeDate($e: MatDatepickerInputEvent<Date>) {
    this.handleGenerateTimeSlots(moment($e.value).format('YYYY-MM-DD'));
  }

  editAppointment() {
    this.isEditAppointment = !this.isEditAppointment;
  }


  editAppointmentRef() {
    this.appointmentRef = this.bookingDetails.appointmentRef;
    this.isEditAppointmentRef = !this.isEditAppointmentRef;
    if(this.isEditAppointmentRef) {
      setTimeout(() => {
        document.getElementById('appointmentRefInput').focus();
      }, 100);
    } else if (!this.appointmentRef) {
      this.appointmentRef = '-';
    }
  }

  async onSaveAppointment() {
    this.loadingAppointment = true;
    const dateBooking = moment(this.model.dateBooking).format('YYYY-MM-DD');
    const payload = {
      bookedAt: this.pairingDateTime(dateBooking, this.model.timeBooking),
      bayId: this.baySelected.id
    };
    await this.bookingDetailsService.updateBookingAppointment(payload).toPromise().catch(err => {
      this.loadingAppointment = false
      throw err;
    });
    this.bookingDetailsService.getBookingDetails(this.bookingDetails.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isEditAppointment = false;
        this.loadingAppointment = false;
        this.toastrService.success('Updated successfully', 'Success', { destroyByClick: true, hasIcon: false });
        this.handleGenerateTimeSlots(moment(this.bookingDetails.bookedAt).format('YYYY-MM-DD'));
      }, () => {
        this.isEditAppointment = false;
        this.loadingAppointment = false;
      });
  }

  onSaveAppointmentRef() {
    const oldValue = this.bookingDetails.appointmentRef;
    if (!this.appointmentRef) {
      if (!oldValue) { // appointmentRef exist is empty, do not update with empty value
        this.isEditAppointmentRef = false;
        return
      }
      this.appointmentRef = oldValue;
      this.toastrService.warning('Appointment Ref cannot be entered empty', 'Warning', { destroyByClick: true, });
      return;
    }
    if (this.appointmentRef && this.appointmentRef !== oldValue) {
      this.loadingAppointmentRef = true;
      this.bookingListService.updateAppointmentRef(this.bookingDetails.id, this.appointmentRef).subscribe(res => {
        this.toastrService.success('Appointment Ref updated successfully', 'Success', { destroyByClick: true, });
        // update the data
        this.bookingDetails = Object.assign({}, {
          ...this.bookingDetails,
          appointmentRef: this.appointmentRef,
        });
        this.configService.refreshBookings.next(true);
      }, () => {
        // rollback the value
        this.appointmentRef = oldValue;
      }, () => {
        this.isEditAppointmentRef = false;
        this.loadingAppointmentRef = false;
      });
    } else {
      this.isEditAppointmentRef = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.configService.setInputVehicle(null);
  }

  async selectedBeaconChange(beaconId) {
    if (this.bookingDetails.beacon.id === beaconId) return;
    const oldBeaconId = this.bookingDetails.beacon.id;
    this.loadingBeacon = true;
    const payloadBookingDetails = { beaconId }
    await Promise.all([
      this.bookingDetailsService.updateBookingDetails(payloadBookingDetails).toPromise().catch(err => { throw err }),
      this.serviceCentersService.updateBeacon(beaconId, { isActive: false }).toPromise().catch(err => { throw err }),
      this.serviceCentersService.updateBeacon(oldBeaconId, { isActive: true }).toPromise().catch(err => { throw err }),
    ]).catch(err => {
      this.loadingBeacon = false;
      throw err;
    });

    const beacons = await this.serviceCentersService.getBeaconsInCenter().toPromise().catch(err => { throw err });
    this.getSelectBeacons(beacons);

    this.bookingDetailsService.getBookingDetails(this.bookingDetails.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.toastrService.success('Updated successfully', 'Success', { destroyByClick: true, hasIcon: false });
        this.loadingBeacon = false;
      }, () => this.loadingBeacon = false);
  }

  // downloadSignature() {
  //   const a = document.createElement("a"); //Create <a>
  //   a.href = this.bookingDetails.repairOrder.customerSignature; //Image Base64 Goes here
  //   a.download = "signature.png"; //File name Here
  //   a.click();
  // }

  getRecommendations() {
    if(!this.isHeavyWorkflow) return; 
    if (this.getServiceRecommendations$) this.getServiceRecommendations$.unsubscribe();
    this.getServiceRecommendations$ = this.vehicleService.getServiceRecommendations(this.bookingDetails.customer.id).subscribe((res: any) => {
      this.serviceRecommendations = (_.get(res, "results") || []).map(item => {
        return {
          bookingId: item.bookingId,
          scheduledAt: momentTimezone.utc(item.scheduledAt).tz(getTimezone()).format('DD/MM/YYYY'),
          content: item.content
        }
      })
    });
  }

  openServiceRecommendations() {
    const data = {
      customerId: this.bookingDetails.customer.id,
      bookingId: this.bookingDetails.id,
      appointmentTime: this.bookingDetails.bookedAtUtc,
      vehicleId: this.bookingDetails.vehicle.id,
      isUneditableMode: this.isUneditableMode,
    }
    this.dialogService.open(ServiceRecommendationsComponent, {
      context: {
        info: data
      }
    }).onClose.subscribe(() => {
      this.getRecommendations();
    })
  }

  handleDocumentsStatus(statuses) {
    const idx = statuses.findIndex(item => item.bookingStatus.code === this.bookingStatuses.invoiced);
    if (idx === -1) return;

    for (let i = idx; i < statuses.length; i++) {
      if (this.bookingDetails.bookingStatus.code === statuses[i].bookingStatus.code) {
        this.isInvoiceDownloadable = true;
        return;
      }
    }
  }

  downloadReceiptPDF() {
    this.configService.setLoading(true);
    this.bookingDetailsService.downloadReceipt(this.bookingDetails.id).subscribe(() => {
      this.configService.setLoading(false);
    });
  }

  public getTotalBookingTime() {
    return this.bookingDetailsService.getTotalBookingTime();
  }

  getContactDetails(arr, type) {
    const contact = arr.find(item => _.get(item, "contact.type") === type)
    return _.get(contact, 'contact.value') || '-';
  }

  handleGotoCustomerDetail() {
    if (this.customerDetailPath && this.isLiteBooking) {
      this.sessionService.setVirtualViewCustomer({
        bookingId: this.bookingDetails.id,
      })
      this.router.navigate([this.customerDetailPath], {});
    }
  }

  handleGotoVehicleDetails() {
    if (this.isLiteBooking && this.vehicleDetailPath) {
      this.sessionService.setVirtualViewCustomer({
        bookingId: this.bookingDetails.id,
      })
      this.router.navigate([this.vehicleDetailPath], {});
    }
  }
}
