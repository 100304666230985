import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class ShareDisableIconService {

    render() {
        return `<svg width="28" height="28" viewBox="0 8 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2795_23765)">
        <path d="M20.1995 8.94263L2.8547 14.7031C2.78427 14.7265 2.72488 14.7749 2.68621 14.8384C2.59782 14.9848 2.6434 15.1768 2.79117 15.2665L8.84577 18.9402C8.93692 18.9954 9.05017 19.001 9.14547 18.9526L11.8855 17.613L10.5472 20.3516C10.5003 20.4483 10.5044 20.5602 10.5611 20.6499L14.2388 26.7142C14.2775 26.7777 14.3369 26.8261 14.406 26.8482C14.5689 26.9034 14.7457 26.815 14.8023 26.6507L20.5959 9.339C20.618 9.27547 20.6166 9.20504 20.5973 9.14151C20.5407 8.97716 20.3639 8.88877 20.1995 8.94263ZM14.2167 23.9687L12.0719 20.4345L14.7498 14.9586C14.7706 14.9158 14.7719 14.8647 14.7498 14.8205C14.7112 14.7431 14.6186 14.7114 14.5399 14.7486L9.06536 17.4279L5.54502 15.2914L18.5671 10.9673L14.2167 23.9687Z" fill="#DCDCDC"/>
        </g>
        <defs>
        <clipPath id="clip0_2795_23765">
        <rect width="20" height="20" fill="white" transform="translate(0.445312 14.916) rotate(-45)"/>
        </clipPath>
        </defs>
        </svg>         
        `;
    }
}














