import { SelectionModel } from '@angular/cdk/collections';
import { Component,EventEmitter, OnInit, Output } from '@angular/core';
import { WarrantyClaimService } from '../../../@core/akita-stores/stores/warranty-claim/warranty-claim.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StepperDataService } from '../../../@core/utils/stepper-data.service';

interface syndryType {
  id: any;
  position:number,
  code: number;
  otherExpenses: string;
  amount: number;
  otherFeeAmount:number;
  expensesCategories:any;
}

@Component({
  selector: 'sundry-info-claim',
  templateUrl: './sundry-info-claim.component.html',
  styleUrls: ['./sundry-info-claim.component.scss']
})

export class SundryInfoClaimComponent implements OnInit {
  selection = new SelectionModel<syndryType>(true, []);
  @Output() isSundryAvailable = new EventEmitter<boolean>();
  displayedColumns: string[] = ["checkSelect","tableRowNumber","code", "otherExpenses", "sundryAmountDetails"];
  dataSource: syndryType[] = [];
  sundryId: number;
  jobOrder: any;
  claimInfoObj: any;
  claimsOperationObj: any;
  claimSparePartObj: any;
  isClaimEditable: string;
  editSpareCodeId: any= {};
  constructor(private warrantyClaimService: WarrantyClaimService, private activateRoute: ActivatedRoute, private shareStepperData: StepperDataService,
    private router : Router
  ) { }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
        this.sundryId =+params['id'];
        this.isClaimEditable=params['isClaimEditable'];
        if(this.isClaimEditable == 'false'){
          this.getClaimDetailsByFromList(this.sundryId)
        }else{
          this.getClaimDetails(this.sundryId)
        }
    });
        this.shareStepperData.claimStepperFormValueShare.subscribe((res)=>{
      this.jobOrder = res.jobOrder;
      this.claimInfoObj=res.claim;
      this.claimsOperationObj=res.claimsOperation;
      this.claimSparePartObj=res.claimsSparePart;
  });
  }

    getClaimDetails(id){
      this.warrantyClaimService.getClaimDetailsById(id).pipe().subscribe((x:any)=>{
      const sparePartDetails=  x;
      this.dataSource =sparePartDetails.jobOrdersExpensesDetails;
   })
  }

    getClaimDetailsByFromList(id){
    this.warrantyClaimService.getClaimDetailsByIdFromList(id).pipe().subscribe((res : any)=>{
      const sparePartDetails = res;
      this.dataSource = sparePartDetails.claimExpenseDetails
    for(let i=0; i< this.dataSource.length; i++){
      this.editSpareCodeId = sparePartDetails.claimExpenseDetails[i].id
    }
      this.dataSource = [...this.dataSource]
    })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.forEach(row => this.selection.select(row));
  }

  setFormValid(){
      this.isSundryAvailable.emit(false); 
  }

  onSubmit(){
    const sundryClaim=[];
    let sundryClaimObj : any = {};
  for(let i=0; i<this.selection.selected.length; i++){
    if(this.isClaimEditable == 'false'){
      sundryClaimObj.expensesId = this.selection.selected[i].id
    };

    sundryClaimObj.otherItemCodeId= this.selection.selected[i].expensesCategories.id,
    sundryClaimObj.otherFeeAmount= this.selection.selected[i].otherFeeAmount,
   
    sundryClaim.push(sundryClaimObj)
  }
    this.shareStepperData.saveStepValueObject({claim:this.claimInfoObj, claimsOperation:this.claimsOperationObj,
      claimsSparePart: this.claimSparePartObj, claimsExpenses: sundryClaim
    })
}

  backToListOrder(){
    this.router.navigate(['/pages/warranty'])
  }

}
