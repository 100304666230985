import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class ArrowLeftRedIconService {

    render() {
        return `<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4765 8.14126C15.4765 8.00865 15.4239 7.88147 15.3301 7.7877C15.2363 7.69394 15.1091 7.64126 14.9765 7.64126H3.18354L6.33054 4.49526C6.37703 4.44877 6.41391 4.39358 6.43906 4.33284C6.46422 4.2721 6.47717 4.207 6.47717 4.14126C6.47717 4.07551 6.46422 4.01041 6.43906 3.94967C6.41391 3.88893 6.37703 3.83374 6.33054 3.78726C6.28405 3.74077 6.22886 3.70389 6.16812 3.67873C6.10738 3.65357 6.04228 3.64062 5.97654 3.64062C5.9108 3.64062 5.8457 3.65357 5.78496 3.67873C5.72422 3.70389 5.66903 3.74077 5.62254 3.78726L1.62254 7.78726C1.57598 7.8337 1.53903 7.88888 1.51383 7.94962C1.48862 8.01037 1.47565 8.07549 1.47565 8.14126C1.47565 8.20702 1.48862 8.27214 1.51383 8.33289C1.53903 8.39363 1.57598 8.44881 1.62254 8.49526L5.62254 12.4953C5.66903 12.5417 5.72422 12.5786 5.78496 12.6038C5.8457 12.6289 5.9108 12.6419 5.97654 12.6419C6.04228 12.6419 6.10738 12.6289 6.16812 12.6038C6.22886 12.5786 6.28405 12.5417 6.33054 12.4953C6.37703 12.4488 6.41391 12.3936 6.43906 12.3328C6.46422 12.2721 6.47717 12.207 6.47717 12.1413C6.47717 12.0755 6.46422 12.0104 6.43906 11.9497C6.41391 11.8889 6.37703 11.8337 6.33054 11.7873L3.18354 8.64126H14.9765C15.1091 8.64126 15.2363 8.58858 15.3301 8.49481C15.4239 8.40104 15.4765 8.27386 15.4765 8.14126Z" fill="url(#paint0_linear_517_10806)"/>
            <defs>
            <linearGradient id="paint0_linear_517_10806" x1="1.47565" y1="8.14126" x2="15.4765" y2="8.14126" gradientUnits="userSpaceOnUse">
            <stop stop-color="#DE2C40"/>
            <stop offset="0.479167" stop-color="#DE142A"/>
            <stop offset="1" stop-color="#AB1021"/>
            </linearGradient>
            </defs>
            </svg>`;
    }
}