import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { WarrantyClaimService } from '../../../@core/akita-stores/stores/warranty-claim/warranty-claim.service';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { StepperDataService } from '../../../@core/utils/stepper-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';

interface jobOperationCodeInfo {
  labourCode: any;
  partsCodeValue: number;
  name: string;
  projectCategoryDetails: any;
  projectSubCategoryDetails: any;
  codeId: number;
  isClaimCreated: boolean;
  operationId: number;
  projectCategory: any;
  projectSubCategory: any;
  id: any;
}

@Component({
  selector: 'job-operation-code-info',
  templateUrl: './job-operation-code-info.component.html',
  styleUrls: ['./job-operation-code-info.component.scss'],
})
export class JobOperationCodeInfoComponent implements OnInit, AfterViewInit {
  jobOperationCodeForm: FormGroup;
  displayedColumns: string[] = [
    'tableRowNumber',
    'operationCodeRow',
    'operationCodeName',
    'projectCategoryDetails',
    'projectSubCategoryDetails',
    'action',
  ];
  dataSource: jobOperationCodeInfo[] = [];
  claimNumber: any;
  noDataFound: boolean = false;
  pageSizeOptionValue = [
    { id: 10 },
    { id: 25 },
    { id: 50 },
    { id: 75 },
    { id: 100 },
  ];
  pageSort = [{ id: 10 }, { id: 25 }, { id: 50 }, { id: 75 }, { id: 100 }];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('discardDialog', { static: false })
  discardDialog: TemplateRef<any>;
  orderNumbers: string[] = [];
  codeFilter: string[] = [];
  projectCategoryValue: any[] = [];
  projectCategoryFilter: any[] = [];
  projectSubCategoryValue: any[] = [];
  projectSubCategoryFilter: string[] = [];
  operationCodeId: any;
  opeartionDetails: any;
  editPosition: number = 0;
  isEditJobOperation: boolean = false;
  jobOrder;
  jobOrdersVehicleDetail;
  vehicleSeriesById;
  form;
  seriesIDInfo;
  catogoryId;
  subCategoryId;
  codeId: number;
  labourCode: any;
  isProductCatagorySelected: boolean = true;
  isSubCategorySelected: boolean = true;
  bothCategorySelected: boolean;
  isClaimCreated: boolean = false;
  isJobOrderEditable: boolean;
  @Output() isOperationCodeAvailable = new EventEmitter<boolean>();
  Subject = new Subject();
  selectedRow: number = null;
  projectCategory: any;
  selectedValueDescription: any;
  selectedValue: any;
  selectedOpCode: any;

  constructor(
    private warrantyClaimService: WarrantyClaimService,
    private snackBar: MatSnackBar,
    private shareDataService: StepperDataService,
    private dialogService: NbDialogService,
    @Optional() private dialogRef: NbDialogRef<any>,
    private shareStepperData: StepperDataService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private configService: ConfigService
  ) {
    this.jobOperationCodeForm = new FormGroup({
      jobOperationCode: new FormControl('', Validators.required),
      jobOperationName: new FormControl(''),
      projectCategory: new FormControl(''),
      projectSubCategory: new FormControl(''),
    });

    this.jobOperationCodeForm.valueChanges.subscribe((res: any) => {
      this.selectedValue = res.projectCategory;
      this.selectedValueDescription =
        this.projectCategoryFilter.find(
          (value: any) => value.id === res.projectCategory
        ).description || '';
    });
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
      this.isJobOrderEditable = params['isJobOrderEditable'];
    });
    this.shareDataService.stepperFormValueShare
      .pipe(take(1))
      .subscribe((res) => {
        this.jobOrder = res.jobOrder;
        this.jobOrdersVehicleDetail = res.jobOrdersVehicleDetail;
        this.vehicleSeriesById = res.vehicleSeriesId;
        if (this.jobOrder.jobOrderId) {
          this.configService.setLoading(true);
          this.getJobOrderById(this.jobOrder.jobOrderId);
          this.configService.setLoading(false);
        } else {
          this.getOperationCode(this.vehicleSeriesById);
        }
        this.getProjectCategory(this.vehicleSeriesById);
      });
  }
  getJobOrderById(id) {
    this.warrantyClaimService
      .getClaimDetailsById(id)
      .pipe()
      .subscribe((res: any) => {
        this.configService.setLoading(true);
        this.dataSource = res.jobOrdersOperationDetails;
        this.configService.setLoading(false);
      });
  }
  selectRow(rowNumber: number) {
    this.selectedRow = rowNumber;
  }

  onClearProjectCategory(event: any) {
    this.jobOperationCodeForm.patchValue({
      projectCategory: '',
    });
    this.jobOperationCodeForm.patchValue({ projectSubCategory: '' });
    this.jobOperationCodeForm.patchValue({ jobOperationCode: '' });
    this.getOperationCode(this.vehicleSeriesById);
  }

  onClearPojectSubCategory(event: any) {
    this.jobOperationCodeForm.patchValue({
      projectSubCategory: '',
    });
    this.getOperationCode(this.vehicleSeriesById, this.projectCategory);
  }

  onClearCode(event: any) {
    this.jobOperationCodeForm.patchValue({
      jobOperationCode: '',
    });
  }
  getProjectCategory(id) {
    if (id != undefined) {
      this.warrantyClaimService
        .getProjectCateGoryDetails(id)
        .subscribe((res) => {
          this.projectCategoryValue = res;
          this.projectCategoryFilter = this.projectCategoryValue.slice();
        });
    }
  }

  onCategorySelect(event) {
    this.catogoryId = event.value;
    this.getOperationCode(this.vehicleSeriesById, this.catogoryId);
    this.getProjectSubCategory(this.vehicleSeriesById, this.catogoryId)
      .then()
      .catch();
    this.jobOperationCodeForm.get('jobOperationCode').patchValue('');
    this.jobOperationCodeForm.get('jobOperationName').patchValue('');
    this.jobOperationCodeForm.get('projectSubCategory').patchValue('');
  }

  getProjectSubCategory(seriesId, categoryId) {
    return new Promise((resolve, reject) => {
      this.warrantyClaimService
        .getProjectSubCategoryDetails(seriesId, categoryId)
        .subscribe(
          (res) => {
            this.projectSubCategoryValue = res;
            this.projectSubCategoryFilter =
              this.projectSubCategoryValue.slice();
            resolve(true);
          },
          (error) => {
            reject(false);
          }
        );
    });
  }

  onSubCategorySelect(event) {
    this.subCategoryId = event.value;
    this.getOperationCode(
      this.vehicleSeriesById,
      this.catogoryId,
      this.subCategoryId
    );
    this.jobOperationCodeForm.get('jobOperationCode').patchValue('');
    this.jobOperationCodeForm.get('jobOperationName').patchValue('');
  }

  getOperationCode(id, categoryId?, subCategoryId?) {
    let params = {
      vehicleSeriesId: id,
      projectCategoryId: categoryId ? categoryId : '',
      projectSubCategoryId: subCategoryId ? subCategoryId : '',
    };
    this.warrantyClaimService
      .getOperationCodes(params)
      .pipe()
      .subscribe((result: any) => {
        this.orderNumbers = result;
        this.codeFilter = this.orderNumbers.slice();
      });
  }

  getOperationCodeById(id) {
    this.warrantyClaimService
      .getOperationCodesById(id)
      .pipe()
      .subscribe(async (x: any) => {
        this.selectedOpCode = x.results;
        this.catogoryId = x.results[0].projectCategoryId;

        await this.getProjectSubCategory(
          this.vehicleSeriesById,
          x.results[0].projectCategoryId
        )
          .then()
          .catch();
        this.subCategoryId = x.results[0].projectSubCategoryId;
      });
  }

  ngAfterViewInit() {}

  onSearch(e) {
    let { value } = e.target;
    value = value.toLowerCase();
    this.codeFilter = this.orderNumbers.filter((element: any) => {
      let { code, description } = element;
      code = code.toLowerCase();
      description = description.toLowerCase();
      if (code.includes(value)) {
        return element;
      }
      if (description.includes(value)) {
        return element;
      }
    });
  }

  get selectedCode(): string {
    const selectedcodeName = this.orderNumbers.find(
      (x: any) => x.code == this.opeartionDetails
    );
    return selectedcodeName;
  }

  async editOperationPart(index: number, ele: any) {
    this.isEditJobOperation = true;
    this.editPosition = index;
    const element = this.dataSource[index];
    if (element.projectCategoryDetails) {
      this.getOperationCode(
        this.vehicleSeriesById,
        element.projectCategoryDetails.id,
        element.projectSubCategoryDetails.id
      );
      let result = await this.warrantyClaimService
        .getProjectCateGoryDetails(this.vehicleSeriesById)
        .toPromise();
      this.getProjectSubCategory(
        this.vehicleSeriesById,
        element.projectCategoryDetails.id
      ).then(() => {
        this.jobOperationCodeForm
          .get('jobOperationCode')
          .patchValue(element.partsCodeValue);
        this.jobOperationCodeForm
          .get('jobOperationName')
          .patchValue(element.name);
        this.jobOperationCodeForm.patchValue({
          projectCategory: element.projectCategoryDetails.id,
        });
        this.jobOperationCodeForm
          .get('projectSubCategory')
          .patchValue(element.projectSubCategoryDetails.id);
        this.disabledRow = element;
      });
    } else if (!element.projectCategoryDetails && element.partsCodeValue) {
      this.jobOperationCodeForm
        .get('jobOperationCode')
        .patchValue(element.partsCodeValue);
      this.jobOperationCodeForm
        .get('jobOperationName')
        .patchValue(element.name);
    } else if (element.labourCode) {
      let result = await this.warrantyClaimService
        .getProjectCateGoryDetails(this.vehicleSeriesById)
        .toPromise();
      this.jobOperationCodeForm
        .get('jobOperationCode')
        .patchValue(element.labourCode.code);
      this.jobOperationCodeForm
        .get('jobOperationName')
        .patchValue(element.labourCode.description);
      this.jobOperationCodeForm.patchValue({
        projectCategory: element.projectCategory.id,
      });
      this.jobOperationCodeForm
        .get('projectSubCategory')
        .patchValue(element.projectSubCategory);
      this.disabledRow = element;
    }
  }

  onSelect(event) {
    // Retrieve the current value of jobOperationName
    const jobOperationNameValue =
      this.jobOperationCodeForm.get('jobOperationName').value;

    // Update the form controls with the new values from the event
    this.jobOperationCodeForm.controls['jobOperationCode'].patchValue(
      event.value.code
    );
    this.jobOperationCodeForm
      .get('jobOperationName')
      .patchValue(event.value.description);
    this.codeId = event.value.id;

    // Conditionally call getOperationCodeById if jobOperationNameValue is not null
    if (!jobOperationNameValue) {
      this.getOperationCodeById(event.value.id);
    }
  }

  addJobOperationCode() {
    // Retrieve values from form controls
    const projectCategory = this.projectCategoryFilter.find(
      (item) => item.id === this.catogoryId
    );
    this.jobOperationCodeForm
      .get('projectCategory')
      .setValue(projectCategory.description);
    const projectSubCategory = this.projectSubCategoryValue.filter(
      (item) => item.id === this.subCategoryId
    );
    this.jobOperationCodeForm
      .get('projectSubCategory')
      .setValue(projectSubCategory[0].description);
    const jobOperationCode =
      this.jobOperationCodeForm.get('jobOperationCode').value;
    const jobOperationName =
      this.jobOperationCodeForm.get('jobOperationName').value;
    const projectCategoryValue = projectCategory.description;
    const projectSubCategoryValue = projectSubCategory[0].description;

    // Find project category details
    const projectCategoryDetails = this.projectCategoryFilter.find(
      (item: any) => {
        return (
          item.id === projectCategoryValue ||
          item.description === projectCategoryValue
        );
      }
    );

    // Find project subcategory details
    const projectSubCategoryDetails = this.projectSubCategoryValue.find(
      (item: any) => {
        return (
          item.id === projectSubCategoryValue ||
          item.description === projectSubCategoryValue
        );
      }
    );

    // Create the record object
    const record = {
      partsCodeValue: jobOperationCode,
      name: jobOperationName,
      projectCategoryDetails: projectCategoryDetails,
      projectSubCategoryDetails: projectSubCategoryDetails,
      codeId: this.codeId,
      isClaimCreated: false,
      operationId: null,
      projectCategory: null,
      projectSubCategory: null,
      id: null,
      labourCode: null,
    };

    // Handle record update or insertion
    if (this.isEditJobOperation) {
      this.dataSource[this.editPosition] = record;
      this.editPosition = 0;
      this.isEditJobOperation = false;
      this.showSuccess(
        'Operation code information updated successfully ',
        '\u2705'
      );
    } else {
      this.dataSource.push(record);
    }

    // Refresh data source and reset form
    this.dataSource = [...this.dataSource];
    this.isEditJobOperation = false;
    this.jobOperationCodeForm.get('jobOperationCode').clearValidators();
    this.jobOperationCodeForm.get('projectCategory').clearValidators();
    this.jobOperationCodeForm.get('projectSubCategory').clearValidators();
    this.jobOperationCodeForm.reset();

    // Call function to get operation code
    this.getOperationCode(this.vehicleSeriesById);
  }

  deleteOperationPart(i) {
    this.dialogRef = this.dialogService.open(this.discardDialog);
    this.dialogRef.onClose.subscribe((result) => {
      if (result === 'done') {
        this.dataSource.splice(i, 1);
        this.dataSource = [...this.dataSource];
      }
    });
  }

  isFormValid(): boolean {
    const record = {
      partsCodeValue: this.jobOperationCodeForm.get('jobOperationCode').value,
    };
    return record.partsCodeValue ? false : true;
  }

  isCodeValid(): boolean {
    const record = {
      projectCategoryDetails:
        this.jobOperationCodeForm.get('projectCategory').value,
      projectSubCategoryDetails:
        this.jobOperationCodeForm.get('projectSubCategory').value,
    };
    return record.projectCategoryDetails && record.projectSubCategoryDetails
      ? false
      : true;
  }

  isSubCategoryValid(): boolean {
    const record = {
      projectCategoryDetails:
        this.jobOperationCodeForm.get('projectCategory').value,
    };
    return record.projectCategoryDetails ? false : true;
  }

  disabledRow;
  isRowDisabled(row: jobOperationCodeInfo): boolean {
    return this.disabledRow === row;
  }

  closeButton(): void {
    this.dialogRef.close();
  }

  delete(): void {
    this.dialogRef.close('done');
    this.showSuccess(
      'Operation code information deleted successfully ',
      '\u2705'
    );
  }

  setFormValid() {
    if (this.dataSource.length > 0) {
      this.isOperationCodeAvailable.emit(false);
    } else {
      this.isOperationCodeAvailable.emit(true);
      this.jobOperationCodeForm = new FormGroup({
        jobOperationCode: new FormControl('', Validators.required),
        jobOperationName: new FormControl(''),
        projectCategory: new FormControl(''),
        projectSubCategory: new FormControl(''),
      });
    }
  }

  onSubmit() {
    const jobOrdersOperation = [];
    let jobOrdersOperationObj = {};
    for (let i = 0; i < this.dataSource.length; i++) {
      if (this.dataSource[i].isClaimCreated !== true) {
        jobOrdersOperationObj = {
          operationId: this.dataSource[i].id || 0,
          jobOrderId: this.jobOrder.jobOrderId || null,
          ProjectCategoryId: this.dataSource[i].projectCategoryDetails
            ? this.dataSource[i].projectCategoryDetails.id
            : this.dataSource[i].projectCategory &&
              this.dataSource[i].projectCategory.id
            ? this.dataSource[i].projectCategory.id
            : null,
          ProjectSubCategoryId: this.dataSource[i].projectSubCategoryDetails
            ? this.dataSource[i].projectSubCategoryDetails.id
            : this.dataSource[i].projectSubCategory &&
              this.dataSource[i].projectSubCategory.id
            ? this.dataSource[i].projectSubCategory.id
            : null,
          OperationCodeId:
            this.dataSource[i].codeId || this.dataSource[i].labourCode.id,
        };
        jobOrdersOperation.push(jobOrdersOperationObj);
      }
    }
    sessionStorage.setItem(
      'jobOrdersOperation',
      JSON.stringify(jobOrdersOperation)
    );
    this.shareStepperData.stepValue({
      jobOrder: this.jobOrder,
      jobOrdersVehicleDetail: this.jobOrdersVehicleDetail,
      jobOrdersOperation: jobOrdersOperation,
    });
  }

  showSuccess(message: string, content) {
    this.snackBar.open(content, message, {
      duration: 1500,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: ['custom-snackbar'],
    });
  }
  backToListOrder() {
    this.shareStepperData.setActiveTab('jobOrderListTab');
    this.router.navigate(['/pages/warranty/warranty-job-order']);
  }
}
