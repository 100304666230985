import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileDragNDropDirective } from '../../directives';
import { Subject } from 'rxjs';
import { MediaService } from '../../../@core/utils/media.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { StepperDataService } from '../../../@core/utils/stepper-data.service';
import { WarrantyClaimService } from '../../../@core/akita-stores/stores/warranty-claim/warranty-claim.service';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';
import {S3UploadService} from '../../../@core/akita-stores/stores/warranty-claim/s3-upload.service';

interface PresignedUrlResponse {
  result: string; // Presigned URL
}

@Component({
  selector: 'warranty-attchment',
  templateUrl: './warranty-attchment.component.html',
  styleUrls: ['./warranty-attchment.component.scss'],
})
export class WarrantyAttchmentComponent implements OnInit {
  stepSixForm: FormGroup;
  attchmentUploaded: string[] = [];
  @ViewChild('fileInput', { static: true })
  fileInput: ElementRef<HTMLInputElement>;
  @Output() filesChanged: EventEmitter<FileList>;
  @Input() dragDropEnabled = true;
  @ViewChild('discardDialogBox', { static: false })  discardDialogBox: TemplateRef<any>;
  @ViewChild('removeFileDialog', { static: false })  removeFileDialog: TemplateRef<any>;
  dialogTitleShow : Boolean = true;
  uploadedMedia: any= [];
  fileToUpload: File[] = [];
  fileShowHide: boolean = false;
  fileUpload: any;
  jobOrder: any;
  claimInfoObj: any;
  claimsOperationObj: any;
  claimSparePartObj: any;
  claimsExpensesObj: any;
  attachmentObj;
  jobOrderID;
  isClaimEditable: string;
  @Output() isAttachmentAvailable = new EventEmitter<boolean>();
  attchmentID: any;
  fileName: any;
  fileType: any;
  id: any;
  isDownload: boolean;
  selectedFile: File | null = null;
  uploadURL: string;
  uploadResponse: any;
  claimResponse: any;
  claimDetails: any;
  editAttachment: any;
  saveResponse: any;
  saveAndSubmit: boolean = false;

  constructor(private mediaService: MediaService, private router: Router, private toastrService: NbToastrService,
     private shareStepperData: StepperDataService, private warrantyClaimService: WarrantyClaimService, private snackBar: MatSnackBar,
     private configService: ConfigService,private dialogService: NbDialogService,@Optional() private dialogRef: NbDialogRef<any>,
     @Optional() private dialogReference: NbDialogRef<any>,private dialogServiceSaveClaim: NbDialogService,
     private activateRoute: ActivatedRoute, private s3Upload:S3UploadService
  ) {
    this.stepSixForm = new FormGroup({
      attchement: new FormControl(''),
    });
  }

  ngOnInit() {
      this.activateRoute.queryParams.subscribe((params) => {
        this.jobOrderID =+params['id'];
        this.isClaimEditable=params['isClaimEditable'];
      });

    this.shareStepperData.claimStepperFormValueShare.subscribe((res)=>{
      this.jobOrder = res.jobOrder;
      this.claimInfoObj=res.claim;
      this.claimsOperationObj=res.claimsOperation;
      this.claimSparePartObj=res.claimsSparePart;
      this.claimsExpensesObj=res.claimsExpenses;

      if(this.isClaimEditable == 'false'){
         this.getClaimDetailsByFromList(this.jobOrderID)
      }
  });
  }
  
   getClaimDetailsByFromList(id){
    this.warrantyClaimService.getClaimDetailsByIdFromList(id).pipe().subscribe((res : any)=>{
      const attchementDetails = res.claimAttachmentDetails;
       this.uploadedMedia = [];
       this.editAttachment =  [];
      for (let file of attchementDetails) {
        this.attchmentID = file.id
        this.fileShowHide = true;
         this.uploadedMedia.push({
           id: file.id,
           name: file.name,
           FileProgress : 100
         })
         this.editAttachment.push({
            ClaimAttachmentId : file.id,
            name: file.name,
            S3URL: file.s3URL,
            AttachmentId: null,
            URL: null
          })
    }  
   });
}

   fileBrowseHandler(event: any) {
    if (event.target.files) {
      this.fileToUpload = Array.from(event.target.files); 
      this.fileValidationCheck(this.fileToUpload);
    }
  }
  onFileDropped(event: Event) {
    this.fileUpload = event;
    this.fileValidationCheck(this.fileUpload)
  }

  fileValidationCheck(files: File[]) {
    for (let file of files) {
      const fileSize = file.size;
      const ext = file.name.split('.').pop().toLowerCase();
      // Check file extension and size
      if (
        (ext === "pdf" || ext === "docx" || ext === "doc" || ext === "jpeg" || 
        ext === "jpg" || ext === "png" || ext === "xlsx" || ext === "xls") &&
        fileSize <= 52428800 // Adjusted condition to include 50MB limit
      ) {
        // Check if file already exists in uploadedMedia
        const existingItem = this.uploadedMedia.find(x => x.name === file.name);
        if (!existingItem) {
          this.processFiles([file]);
        } else {
          this.toastrService.danger("The file name already exists.", 'Canceled');
        }
      } else {
        if (fileSize > 52428800) {
          this.toastrService.danger('File size exceeds 50MB.', 'Canceled');
        } else {
          this.toastrService.danger('Please select a supported file format.', 'Canceled');
        }
      }
    }
  }

  async processFiles(files: File[]) {
    for (let file of files) {
      this.uploadedMedia.push(file); 
      await this.startProgress(file);
    }
  }

async startProgress(file: File) {
  let filteredFile = this.uploadedMedia.find(f => f.name === file.name);

  if (filteredFile && file.size <= 52428800) {
    let fileSize = file.size;
    let fileSizeInWords = this.formatBytes(fileSize);

     for (let f = 0; f < fileSize + fileSize * 0.0001; f += fileSize * 0.01) {
        filteredFile.FileProgessSize = fileSizeInWords;
        let percentUploaded = Math.round((f / fileSize) * 100);
        filteredFile.FileProgress = percentUploaded;

        await this.fakeWaiter(Math.floor(Math.random() * 35) + 1); // Simulate progress
      }
  } else {
    this.toastrService.danger('File size should be below 50MB', 'Canceled');
  }
  }
  fakeWaiter(ms: number) {
    this.fileShowHide = true;
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  async saveClaimForm() {
  try {
    this.configService.setLoading(true);
    this.attachmentObj = [];
   const stepperData = {
      claim: this.claimInfoObj,
      claimsOperation: this.claimsOperationObj,
      claimsSparePart: this.claimSparePartObj,
      claimsExpenses: this.claimsExpensesObj,
      claimsAttachments: this.isClaimEditable === 'false' ? this.editAttachment : this.attachmentObj,
    };

    let stepperResponse: any;

    if (this.isClaimEditable === 'false') {
      stepperResponse = await this.warrantyClaimService.updateCreateClaim(stepperData).toPromise();
      this.saveResponse = stepperResponse;
      if(!this.saveAndSubmit){
         this.router.navigate(["/pages/warranty"]);
         this.showSuccess('Changes in the Create Claim saved successfully', "\u2705");
      }
      else {
        let claimIDObj = {
            claimId : this.saveResponse.Claim.ClaimId
          }
   this.warrantyClaimService.submitClaimDetailsByID(claimIDObj).subscribe((res : any)=>{
    this.showSuccess('New claim submitted successfully', "\u2705");
    this.router.navigate(["/pages/warranty"]);
          })
      }
     
    } else {
      stepperResponse = await this.warrantyClaimService.submitClaimForm(stepperData).toPromise();
      this.saveResponse = stepperResponse;
     
       if(!this.saveAndSubmit){
         this.router.navigate(["/pages/warranty"]);
          this.showSuccess('New claim saved successfully', "\u2705");
      }else{
        let claimIDObj = {
            claimId : this.saveResponse.Claim.entityObject.Id
          }
   this.warrantyClaimService.submitClaimDetailsByID(claimIDObj).subscribe((res : any)=>{
     this.showSuccess('New claim submitted successfully', "\u2705");
    this.router.navigate(["/pages/warranty"]);
          })
      }
    }
    this.claimResponse = stepperResponse;
    this.id = this.claimResponse.Claim.entityObject.Id;

    if (this.uploadedMedia.length > 0) {
       const newFiles = this.uploadedMedia.filter(file => !this.claimDetails.some(existingFile => existingFile.name === file.name));

      const uploadPromises = newFiles.map(async (file) => {
        this.fileName = file.name;
        this.fileType = file.type;
        this.isDownload = false;
        try {
          const s3Response: any = await this.warrantyClaimService.postS3Bucket({
            fileName: this.fileName,
            fileType: this.fileType,
            claimId: this.id,
            isDownload: this.isDownload
          }).toPromise();

          if (!s3Response || !s3Response.result) {
            throw new Error('Invalid S3 URL response');
          }

          const originalUrl = s3Response.result;
          const url = new URL(originalUrl);
          const params = new URLSearchParams(url.search);
          this.uploadURL = `${url.origin}${url.pathname}`;

          await this.s3Upload.uploadFile(originalUrl, file).toPromise();
          return {
            name: this.fileName,
            S3URL: this.uploadURL
          };
        } catch (uploadError) {
          throw uploadError; 
        }
      });

      const uploadedFiles = await Promise.all(uploadPromises);
      this.claimDetails.push(...uploadedFiles);
      await this.warrantyClaimService.updateAttchmentDetails({ claimId: this.id }, { claimsAttachments: this.claimDetails }).toPromise();
    }

    this.isAttachmentAvailable.emit(false);
    this.configService.setLoading(false);
    setTimeout(() => {
       this.router.navigate(["/pages/warranty"]);
    }, 500);
  } catch (error) {
    this.configService.setLoading(false);
    this.dialogReference.close("claimSaved");
    console.error('Error saving claim form:', error);
  }
}

  reDirectTo() {
    this.router.navigateByUrl('/pages/warranty').then(() => {
    }, err =>  this.toastrService.danger('Claim not saved', 'Canceled'))
  }

  removeImage(index: number, element) {
    this.dialogTitleShow=element
    this.dialogRef = this.dialogService.open(this.removeFileDialog);
     this.dialogRef.onClose.subscribe(result => {
     if(result === 'deleteFile' ){
       this.dialogRef.close()
       this.uploadedMedia.splice(index, 1);
       this.showSuccess('File Deleted Successfully', "\u2705");

       if(this.isClaimEditable == 'false'){
       this.dialogRef.close()
       this.uploadedMedia.splice(index, 1);
       this.showSuccess('File Deleted Successfully', "\u2705");
       let attchementIdObj ={
        claimAttachmentId : this.attchmentID
       }
       this.warrantyClaimService.deleteAttchmentOnEdit(attchementIdObj)
       }
     }
   })
  }

  onSubmit() {
    this.dialogReference = this.dialogServiceSaveClaim.open(this.discardDialogBox);
    this.dialogReference.onClose.subscribe(res => {
     if(res === 'claimSaved'){
       this.dialogReference.close();
     }
   })
  }
  
  async onSaveAndSubmit() {
    this.saveAndSubmit = true;
  }

  closeDialog(){
    this.dialogReference.close();
  }

  closeButton() {
    this.dialogRef.close();
  }

  delete(): void {
    this.dialogRef.close("deleteFile");
    let attchementIdObj ={
        claimAttachmentId : this.attchmentID
       }
    this.warrantyClaimService.deleteAttchmentOnEdit(attchementIdObj).toPromise()
  }

    showSuccess(message: string,content) {
    this.snackBar.open(content,message,{
      duration: 1500,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: ['custom-snackbar']
    });
  }

  backToClaimList(){
    this.router.navigate(['/pages/warranty'])
  }
}
