import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../../../utils/common.service';
import { API_PATH } from '../../../../constants';
import { catchError, delay, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

export interface claimInfo {
  page: number;
  pageSize: number;
  nextUrl: string;
  prevUrl: string;
  count: number;
  results: results[];
}

type results = {
  id: number;
  description: string;
  code: string;
};

export interface warrantyAmountInfo {
  page: number;
  pageSize: number;
  nextUrl: string;
  prevUrl: string;
  count: number;
  results: amount[];
}

type amount = {
  id: number;
  name: string;
  code: string;
  currency: string;
  amount: number;
};

export interface LabourAmountInfo {
  page: number;
  pageSize: number;
  nextUrl: string;
  prevUrl: string;
  count: number;
  results: unitPrice[];
}

type unitPrice = {
  sysConfigName: string;
  sysConfigValue: number;
  currency: number;
  isActive: boolean;
};

export interface operationCodeId {
  page: number;
  pageSize: number;
  nextUrl: string;
  prevUrl: string;
  count: number;
  results: troubleCodeInfo[];
}

type troubleCodeInfo = {
  id: string;
  description: string;
  code: string;
  seriesCode: string;
  seriesName: string;
  labourHour: number;
  troubleCode: string;
};

export interface bookingList {
  page: number;
  pageSize: number;
  nextUrl: string;
  prevUrl: string;
  count: number;
  results: bookingListInfo[];
}

type bookingListInfo = {
  bookingId: number;
  bookedAt: Date;
  vehicle: {
    vin: string;
    rego: string;
    make: string;
    model: string;
    odometer: number;
    series: string;
  };
};

export interface bookingDetailsById {
  page: number;
  pageSize: number;
  nextUrl: string;
  prevUrl: string;
  count: number;
  results: bookingDetailsId[];
}

type bookingDetailsId = {
  bookingId: number;
  bookedAt: Date;
  vehicle: {
    vin: string;
    rego: string;
    make: string;
    model: string;
    odometer: number;
    series: string;
    engine: string;
  };
};

@Injectable({ providedIn: 'root' })
export class WarrantyClaimService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getClaimTypes() {
    return this.http.get<claimInfo>(API_PATH.warrantyClaimInfo.claimTypes).pipe(
      catchError((err) => {
        return of({}).pipe();
      })
    );
  }

  getProblemClassification() {
    return this.http
      .get<claimInfo>(API_PATH.warrantyClaimInfo.problemClassification)
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getRescueStatus() {
    return this.http
      .get<claimInfo>(API_PATH.warrantyClaimInfo.resuceValue)
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getSundryCodeInfo() {
    return this.http
      .get<claimInfo>(API_PATH.warrantyClaimInfo.sundryCodeInfo)
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getPartsAmountInfo(id: number) {
    return this.http
      .get<warrantyAmountInfo>(API_PATH.warrantyClaimInfo.partsAmountInfo + id)
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getOperationFaultCodeInfo(code: string) {
    return this.http
      .get<claimInfo>(API_PATH.warrantyClaimInfo.operationFaultCodeInfo + code)
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getOperationProcessingCodeInfo(code: string) {
    return this.http
      .get<claimInfo>(
        API_PATH.warrantyClaimInfo.operationProcessingCodeInfo + code
      )
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getOperationLabourUnitPriceInfo(code: string) {
    return this.http
      .get<LabourAmountInfo>(
        API_PATH.warrantyClaimInfo.operationLabourUnitPriceInfo + code
      )
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getClaimStatus() {
    return this.http
      .get<claimInfo>(API_PATH.warrantyClaimInfo.claimStatus)
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getBookingList(filter) {
    let url = API_PATH.warrantyClaimInfo.bookingList;
    if (filter) {
      const queryString = Object.entries(filter)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.get(url).pipe(map((res: any) => res));
  }

  getBookingDetailsById(id) {
    return this.http
      .get<bookingDetailsById>(
        API_PATH.warrantyClaimInfo.bookingDetailsByIdFromList + id
      )
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getClaimDetailsByIdFromList(id) {
    return this.http
      .get<bookingDetailsById>(
        API_PATH.warrantyClaimInfo.claimDetailsByIdFromList + id
      )
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getVehicleDetails(filter) {
    let url = API_PATH.warrantyClaimInfo.vehicleDetailsInfo;
    if (filter) {
      const queryString = Object.entries(filter)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.get<any>(url).pipe(
      catchError((err) => {
        return of({}).pipe();
      })
    );
  }

  getVehicleSeries(id: any) {
    let url = API_PATH.warrantyClaimInfo.vehicleSeriesById;
    if (id) {
      const queryString = Object.entries(id)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.post(url, id).pipe(map((res: any) => res));
  }

  getProjectCateGoryDetails(id) {
    return this.http
      .get<any>(API_PATH.warrantyClaimInfo.projectCategoryDetailsById + id)
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getProjectSubCategoryDetails(id, categoryId) {
    return this.http
      .get<any>(
        API_PATH.warrantyClaimInfo.projectSubCategoryDetailsById +
          `${id}` +
          '/' +
          `${categoryId}`
      )
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getOperationCodes(param) {
    let url = API_PATH.warrantyClaimInfo.operationCodeInfo;
    if (param) {
      const queryString = Object.entries(param)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.get<any>(url).pipe(
      catchError((err) => {
        return of({}).pipe();
      })
    );
  }

  getOperationCodesById(id) {
    let url = API_PATH.warrantyClaimInfo.operationCodeInfo + '/' + id;
    // if (id) {
    //   const queryString = Object.entries(id)
    //     .map(
    //       ([key, value]: any) =>
    //         `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    //     )
    //     .join('&');
    //   url += '?' + queryString;
    // }
    return this.http.get<any>(url).pipe(
      catchError((err) => {
        return of({}).pipe();
      })
    );
  }

  getSparePartsInfo() {
    return this.http
      .get<any>(API_PATH.warrantyClaimInfo.sparePartsDetailsInfo)
      .pipe(
        catchError((err) => {
          return of({}).pipe();
        })
      );
  }

  getSpareCodeInfo(param) {
    let url = API_PATH.warrantyClaimInfo.partsCodeInfo;
    if (param) {
      const queryString = Object.entries(param)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.get<any>(url).pipe(
      catchError((err) => {
        return of({}).pipe();
      })
    );
  }

  getAllSpareCodeNameDetails(param?) {
    let url = API_PATH.warrantyClaimInfo.allPartsCodeList;
    if (param) {
      const queryString = Object.entries(param)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.get<any>(url).pipe(
      catchError((err) => {
        return of({}).pipe();
      })
    );
  }

  getClaimDetailsById(id) {
    return this.http.get(API_PATH.warrantyClaimInfo.claimDetailsById + id).pipe(
      catchError((err) => {
        return of({}).pipe();
      })
    );
  }

  getJobOrderList(filter: any) {
    let url = API_PATH.warrantyClaimInfo.jobOrderList;
    if (filter) {
      const queryString = Object.entries(filter)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.get(url).pipe(map((res: any) => res));
  }

  createJobOrder(JobOrdereForm) {
    const url = API_PATH.warrantyClaimInfo.saveJobOrderForm;
    return this.http.post(url, JobOrdereForm);
  }

  postS3Bucket(attachmentDetails) {
    const url = API_PATH.warrantyClaimInfo.getS3PreSignature;
    return this.http.post(url, attachmentDetails);
  }

  submitClaimForm(createClaim) {
    const url = API_PATH.warrantyClaimInfo.saveClaimForm;
    return this.http.post(url, createClaim);
  }

  updateJobOrder(JobOrdereEditForm) {
    const url = API_PATH.warrantyClaimInfo.updateJobOrderForm;
    return this.http.put(url, JobOrdereEditForm);
  }

  deleteJobOrder(params: any) {
    let url = API_PATH.warrantyClaimInfo.deleteJobOrder;
    if (params) {
      const queryString = Object.entries(params)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.delete(url).pipe(map((res: any) => res));
  }

  deleteClaimDetailsList(params: any) {
    let url = API_PATH.warrantyClaimInfo.deleteClaimDetailsById;
    if (params) {
      const queryString = Object.entries(params)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.delete(url).pipe(map((res: any) => res));
  }

  getAllClaimList(filter: any) {
    let url = API_PATH.warrantyClaimInfo.ClaimList;
    if (filter) {
      const queryString = Object.entries(filter)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.get(url).pipe(map((res: any) => res));
  }

  getAllReportList(filter: any) {
    let url = API_PATH.warrantyClaimInfo.reportList;
    if (filter) {
      const queryString = Object.entries(filter)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.get(url).pipe(map((res: any) => res));
  }

  getAllSettlemetList(filter: any) {
    let url = API_PATH.warrantyClaimInfo.getsettlementList;
    if (filter) {
      const queryString = Object.entries(filter)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.get(url).pipe(map((res: any) => res));
  }

  submitClaimDetailsByID(id: any) {
    let url = API_PATH.warrantyClaimInfo.submitClaimDetails;
    if (id) {
      const queryString = Object.entries(id)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.post(url, id);
  }

  deleteAttchmentOnEdit(params) {
    let url = API_PATH.warrantyClaimInfo.deleteAttchment;
    if (params) {
      const queryString = Object.entries(params)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.delete(url).pipe(map((res: any) => res));
  }

  updateCreateClaim(CreateClaimEditForm) {
    const url = API_PATH.warrantyClaimInfo.updateCreateClaimForm;
    return this.http.put(url, CreateClaimEditForm);
  }

  updateAttchmentDetails(id: any, s3AttachmentDetails) {
    let url = API_PATH.warrantyClaimInfo.saveClaimAttachmentDetails;
    if (id) {
      const queryString = Object.entries(id)
        .map(
          ([key, value]: any) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
      url += '?' + queryString;
    }
    return this.http.post(url, s3AttachmentDetails);
  }
}
