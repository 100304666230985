import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class ShareActiveIconService {

    render() {
        return `<svg width="16" height="16" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.2772 0.668219L0.932338 6.42866C0.861904 6.45213 0.802518 6.50047 0.763848 6.564C0.67546 6.71039 0.721035 6.90236 0.868809 6.99213L6.92341 10.6658C7.01456 10.721 7.12781 10.7265 7.2231 10.6782L9.96314 9.33857L8.62489 12.0772C8.57793 12.1739 8.58207 12.2858 8.6387 12.3755L12.3165 18.4398C12.3552 18.5033 12.4145 18.5517 12.4836 18.5738C12.6466 18.629 12.8233 18.5406 12.88 18.3763L18.6735 1.06459C18.6956 1.00106 18.6943 0.930624 18.6749 0.867095C18.6183 0.702747 18.4415 0.614358 18.2772 0.668219ZM12.2944 15.6942L10.1496 12.1601L12.8275 6.68415C12.8482 6.64134 12.8496 6.59024 12.8275 6.54605C12.7888 6.46871 12.6963 6.43694 12.6176 6.47423L7.143 9.1535L3.62266 7.01699L16.6447 2.69287L12.2944 15.6942Z" fill="url(#paint0_linear_517_12088)"/>
<defs>
<linearGradient id="paint0_linear_517_12088" x1="6.58614" y1="12.7224" x2="18.5811" y2="0.727416" gradientUnits="userSpaceOnUse">
<stop stop-color="#DE2C40"/>
<stop offset="0.479167" stop-color="#DE142A"/>
<stop offset="1" stop-color="#AB1021"/>
</linearGradient>
</defs>
</svg>`;
    }
}
