import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';

@Component({
  selector: 'table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements OnInit {
  @Input() totalItems: number;
  @Input() pageSize: number;
  @Input() currentPage: number;
  @Input() pageRange: number; // Number of pages to display around the current page
  @Input() showFirstLast: boolean = true;
  @Output() pageChanged = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  get totalPages() {
    return Math.ceil(this.totalItems / this.pageSize);
  }

  get pages(): number[] {
    const pages = [];
    const startPage = Math.max(1,this.currentPage - this.pageRange);
    const endPage = Math.min(this.totalPages,this.currentPage + this.pageRange);
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  }


  onPageChange(page) {
    this.currentPage = page;
    this.pageChanged.emit(this.currentPage);
  }

  firstPage() {
    if (this.currentPage !== 1) {
      this.onPageChange(1);
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.onPageChange(this.currentPage - 1);
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.onPageChange(this.currentPage + 1);
    }
  }

  lastPage() {
    if (this.currentPage !== this.totalPages) {
      this.onPageChange(this.totalPages);
    }
  }

}
