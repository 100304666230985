import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EditDisableIconService {
  render() {
    return `<svg width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2795_23749)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.89844 4.09115C3.68847 4.09115 3.48711 4.17455 3.33864 4.32302C3.19018 4.47149 3.10677 4.67285 3.10677 4.88281V15.9661C3.10677 16.1761 3.19018 16.3775 3.33864 16.5259C3.48711 16.6744 3.68847 16.7578 3.89844 16.7578H14.9818C15.1917 16.7578 15.3931 16.6744 15.5416 16.5259C15.69 16.3775 15.7734 16.1761 15.7734 15.9661V11.7386C15.7734 11.3014 16.1279 10.947 16.5651 10.947C17.0023 10.947 17.3568 11.3014 17.3568 11.7386V15.9661C17.3568 16.596 17.1065 17.2001 16.6611 17.6455C16.2158 18.0909 15.6117 18.3411 14.9818 18.3411H3.89844C3.26855 18.3411 2.66446 18.0909 2.21906 17.6455C1.77366 17.2001 1.52344 16.596 1.52344 15.9661V4.88281C1.52344 4.25292 1.77366 3.64883 2.21906 3.20343C2.66446 2.75803 3.26855 2.50781 3.89844 2.50781H8.12594C8.56316 2.50781 8.9176 2.86225 8.9176 3.29948C8.9176 3.7367 8.56316 4.09115 8.12594 4.09115H3.89844Z" fill="#DCDCDC"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.422 1.1557C14.7311 0.846537 15.2324 0.846537 15.5416 1.1557L18.7082 4.32237C19.0174 4.63153 19.0174 5.13279 18.7082 5.44195L10.7916 13.3586C10.6431 13.5071 10.4417 13.5905 10.2318 13.5905H7.0651C6.62788 13.5905 6.27344 13.2361 6.27344 12.7988V9.63216C6.27344 9.4222 6.35684 9.22083 6.50531 9.07237L14.422 1.1557ZM7.85677 9.96008V12.0072H9.90385L17.0289 4.88216L14.9818 2.83508L7.85677 9.96008Z" fill="#DCDCDC"/>
    </g>
    <defs>
    <clipPath id="clip0_2795_23749">
    <rect width="19" height="19" fill="white" transform="translate(0.730469 0.132812)"/>
    </clipPath>
    </defs>
    </svg>
        `;
  }
}