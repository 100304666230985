import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class ArrowRightIconService {
  
  render() {
    return `<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 8.14126C1.5 8.00865 1.55268 7.88147 1.64645 7.7877C1.74021 7.69394 1.86739 7.64126 2 7.64126H13.793L10.646 4.49526C10.5521 4.40137 10.4994 4.27403 10.4994 4.14126C10.4994 4.00848 10.5521 3.88114 10.646 3.78726C10.7399 3.69337 10.8672 3.64063 11 3.64062C11.1328 3.64062 11.2601 3.69337 11.354 3.78726L15.354 7.78726C15.4006 7.8337 15.4375 7.88888 15.4627 7.94962C15.4879 8.01037 15.5009 8.07549 15.5009 8.14126C15.5009 8.20702 15.4879 8.27214 15.4627 8.33289C15.4375 8.39363 15.4006 8.44881 15.354 8.49526L11.354 12.4953C11.2601 12.5891 11.1328 12.6419 11 12.6419C10.8672 12.6419 10.7399 12.5891 10.646 12.4953C10.5521 12.4014 10.4994 12.274 10.4994 12.1413C10.4994 12.0085 10.5521 11.8811 10.646 11.7873L13.793 8.64126H2C1.86739 8.64126 1.74021 8.58858 1.64645 8.49481C1.55268 8.40104 1.5 8.27386 1.5 8.14126Z" fill="white"/>
        </svg>    
        `;
  }
}
