const SESSION_ROOT_KEY = 'session';
export enum SESSION_KEY {
    virtualCustomerView = 'virtualCustomerView',
}

export function getSession() {
    const session = sessionStorage.getItem(SESSION_ROOT_KEY);
    return session ? JSON.parse(session) : {};
}

export function saveSession(session) {
    sessionStorage.setItem(SESSION_ROOT_KEY, JSON.stringify(session));
}

export function clearSession() {
    sessionStorage.removeItem(SESSION_ROOT_KEY);
}

export function setSessionItem(key: SESSION_KEY, value) {
    const session = getSession();
    session[key] = value;
    saveSession(session);
}

export function getSessionItem(key: SESSION_KEY) {
    const session = getSession();
    return session[key];
}

