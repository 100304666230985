import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WarrantyClaimService } from '../../../@core/akita-stores/stores/warranty-claim/warranty-claim.service';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { StepperDataService } from '../../../@core/utils/stepper-data.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from '@angular/router';

interface jobOrdersparePartsInfo {
  partsCodeValue: number;
  partsCodename: string;
  quantity: number;
  sparePartsType: any;
  faultParts: boolean;
  codeId:number;
  inUse:boolean;
  sparePart:any;
  sparePartType:any;
  partQuantity:any;
  id:any;
}

@Component({
  selector: 'job-order-spareparts-code',
  templateUrl: './job-order-spareparts-code.component.html',
  styleUrls: ['./job-order-spareparts-code.component.scss'],
})
export class JobOrderSparepartsCodeComponent implements OnInit, OnDestroy {
  jobSparePartsInformationForm: FormGroup;
  @Output() isJobSparePartsAvailable = new EventEmitter<boolean>();
  editPosition: number = 0;
  dataSource: jobOrdersparePartsInfo[] = [];
  totalValue: boolean = false;
  isEditSpare: boolean = false;
  partsCode: string[] = [];
  partsCodeFilter: string[] = [];
  orderNumbers: string[] = [];
  codeFilter: string[] = [];
  partsAmount: number = 0;
  isEditJobSpare: boolean = false;
  partsCodeId;
  onDestroy$: Subject<boolean> = new Subject();
  isSparePartsChange: boolean = false;
  @ViewChild('discardDialog', { static: false })
  discardDialog: TemplateRef<any>;
  jobOrderSpare: any;
  vehicleDetails: any;
  jobOrder;
  jobOrdersVehicleDetail;
  jobOrdersOperation;
  jobOperationDetails: any;
  codeId: number;
  isSparePartsSelected: boolean = true;
  selectedIndex: any = 0;
  disabledRow;
  selectedRow: number = null;
  inUse: boolean = false;
  isJobOrderEditable: boolean;
  allDisabled: boolean = true;
  jobSpareVehicleId: any;

  constructor(
    private dialogService: NbDialogService,
    private snackBar: MatSnackBar,
    @Optional() private dialogRef: NbDialogRef<any>,
    private warrantyClaimService: WarrantyClaimService,
    private shareDataService: StepperDataService,
    private shareStepperData: StepperDataService,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {
    this.jobSparePartsInformationForm = new FormGroup({
      jobCodeInfo: new FormControl(''),
      jobPartsName: new FormControl(''),
      jobPartsQuantity: new FormControl(''),
      jobSparePartsType: new FormControl(''),
    });
  }

  displayedColumns: string[] = [
    'position',
    'partsCodeValue',
    'partsCodename',
    'quantity',
    'sparePartsType',
    'faultParts',
    'action',
  ];

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
      this.isJobOrderEditable = params['isJobOrderEditable'];
    });
    this.shareDataService.stepperFormValueShare
      .pipe(take(1))
      .subscribe((res) => {
        this.vehicleDetails = res.vehicleDetailsFormValue;
        this.jobOrder = res.jobOrder;
        this.jobOrdersVehicleDetail = res.jobOrdersVehicleDetail;
        this.jobOrdersOperation = res.jobOrdersOperation;
        this.jobOperationDetails = res.jobOrdersOperation;
        this.jobSpareVehicleId = res.vehicleSeriesId;
        if (this.jobOrder.jobOrderId) {
          this.getJobOrderById(this.jobOrder.jobOrderId);
        } else {
          this.getSpareCodeNameDetails(this.jobSpareVehicleId);
        }
      });
    this.warrantyClaimService
      .getSparePartsInfo()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((x: any) => {
        this.partsCode = x.results;
        this.partsCodeFilter = this.partsCode.slice();
      });
  }

  getJobOrderById(id) {
    this.warrantyClaimService
      .getClaimDetailsById(id)
      .pipe()
      .subscribe((res: any) => {
        this.dataSource = res.jobOrdersSparePartDetails;
      });
  }

  selectRow(rowNumber: number) {
    this.selectedRow = rowNumber;
  }

  onSelectCodeValue(event) {
    this.jobSparePartsInformationForm.get('jobPartsQuantity').patchValue(1);
    this.partsCodeId = event.value;
    this.isSparePartsChange = true;
    if (this.isSparePartsChange == true) {
      this.jobSparePartsInformationForm.get('jobCodeInfo').patchValue('');
      this.jobSparePartsInformationForm.get('jobPartsName').patchValue('');
      this.getSpareCodeNameDetails(this.jobSpareVehicleId, this.partsCodeId);
    }

    this.isSparePartsSelected = false;
  }

  getSpareCodeNameDetails(id, partTypeId?) {
    let param = {
      vehicleSeriesId: id,
      partTypeId: partTypeId ? partTypeId : '',
    };
    this.warrantyClaimService
      .getSpareCodeInfo(param)
      .pipe(take(1))
      .subscribe((x: any) => {
        this.orderNumbers = x;
        this.codeFilter = this.orderNumbers.slice();
      });
  }

  onSelect(event) {
    this.shareDataService.stepperFormValueShare
      .pipe(take(1))
      .subscribe((res) => {
        this.vehicleDetails = res.vehicleDetailsFormValue;
        this.jobOrder = res.jobOrder;
        this.jobOrdersVehicleDetail = res.jobOrdersVehicleDetail;
        this.jobOrdersOperation = res.jobOrdersOperation;
        this.jobOperationDetails = res.jobOrdersOperation;
        this.jobSpareVehicleId = res.vehicleSeriesId;
      });
    this.jobSparePartsInformationForm.controls['jobCodeInfo'].patchValue(
      event.value.code
    );
    this.jobSparePartsInformationForm
      .get('jobPartsName')
      .patchValue(event.value.description);
    this.codeId = event.value.id;
  }

  onClearSparePartsType(event: any) {
    this.jobSparePartsInformationForm.patchValue({
      jobSparePartsType: '',
    });
    this.jobSparePartsInformationForm.patchValue({
      jobCodeInfo: '',
    });
     this.getSpareCodeNameDetails(this.jobSpareVehicleId);
  }

  onClearCodeInfo(event: any) {
    this.jobSparePartsInformationForm.patchValue({
      jobCodeInfo: '',
    });
     this.codeFilter = this.orderNumbers.slice();
  }

  onSearch(e) {
    let { value } = e.target;
    value = value.toLowerCase();
    this.codeFilter = this.orderNumbers.filter((element: any) => {
      let { code, description } = element;
      // Ensure both code and description are strings
      if (typeof code !== 'string') code = '';
      if (typeof description !== 'string') description = '';
      if (code.includes(value)) {
        return element;
      }
      if (description.includes(value)) {
        return element;
      }
    });
  }

  addSparePartsInfo() {
    if (
      this.jobSparePartsInformationForm.get('jobCodeInfo').value &&
      this.jobSparePartsInformationForm.get('jobPartsName').value
    ) {
    }
    const record = {
      partsCodeValue:
        this.jobSparePartsInformationForm.get('jobCodeInfo').value,
      partsCodename:
        this.jobSparePartsInformationForm.get('jobPartsName').value,
      quantity: this.jobSparePartsInformationForm.get('jobPartsQuantity').value,
      sparePartsType: this.partsCodeFilter.find((record: any) => {
        return (
          record.id ==
          this.jobSparePartsInformationForm.get('jobSparePartsType').value
        );
      }),
      faultParts: false,
      codeId: this.codeId,
      sparePart: null,
      sparePartType: null,
      inUse: false,
      partQuantity: null,
      id: null,
    };

    if (this.isEditJobSpare === true) {
      this.dataSource[this.editPosition] = record;
      this.editPosition = 0;
      this.isEditJobSpare = false;
      this.showSuccess('Spare Parts Updated Successfully ', '\u2705');
    } else {
      this.dataSource.push(record);
    }
    this.codeFilter = this.orderNumbers.slice();
    this.dataSource = [...this.dataSource];
    this.isEditJobSpare = false;
    this.jobSparePartsInformationForm
      .get('jobSparePartsType')
      .clearValidators();
    this.jobSparePartsInformationForm.get('jobPartsQuantity').clearValidators();
    this.jobSparePartsInformationForm.get('jobCodeInfo').clearValidators();
    this.jobSparePartsInformationForm.reset();
    this.getSpareCodeNameDetails(this.jobSpareVehicleId);
  }

  isFormValid(): boolean {
    const record = {
      partsCodeValue:
        this.jobSparePartsInformationForm.get('jobCodeInfo').value,
      quantity: this.jobSparePartsInformationForm.get('jobPartsQuantity').value,
    };
    return record.partsCodeValue && record.quantity ? false : true;
  }

  isCodeValid(): boolean {
    const record = {
      quantity: this.jobSparePartsInformationForm.get('jobPartsQuantity').value,
      sparePartsType:
        this.jobSparePartsInformationForm.get('jobSparePartsType').value,
    };
    return record.quantity && record.sparePartsType ? false : true;
  }

  isQuantityValid(): boolean {
    const record = {
      quantity: this.jobSparePartsInformationForm.get('jobPartsQuantity').value,
    };
    return record.quantity ? false : true;
  }

  editSparePart(index, elements) {
    this.isEditJobSpare = true;
    this.editPosition = index;
    const element = this.dataSource[index];
    if (element.partsCodeValue) {
      this.jobSparePartsInformationForm
        .get('jobCodeInfo')
        .patchValue(element.partsCodeValue);
      this.jobSparePartsInformationForm
        .get('jobPartsName')
        .patchValue(element.partsCodename);
      this.jobSparePartsInformationForm
        .get('jobPartsQuantity')
        .patchValue(element.quantity);
      this.jobSparePartsInformationForm
        .get('jobSparePartsType')
        .patchValue(element.sparePartsType.id);
      this.disabledRow = elements;
    } else {
      this.jobSparePartsInformationForm
        .get('jobCodeInfo')
        .patchValue(element.sparePart.code);
      this.jobSparePartsInformationForm
        .get('jobPartsName')
        .patchValue(element.sparePart.description);
      this.jobSparePartsInformationForm
        .get('jobPartsQuantity')
        .patchValue(element.partQuantity);
      this.jobSparePartsInformationForm
        .get('jobSparePartsType')
        .patchValue(element.sparePartType.id);
      this.disabledRow = elements;
    }
    this.checkDisabledState();
  }

  deleteSparePart(i) {
    this.dialogRef = this.dialogService.open(this.discardDialog);
    this.dialogRef.onClose.subscribe((result) => {
      if (result === 'done') {
        this.dataSource.splice(i, 1);
        this.dataSource = [...this.dataSource];
        this.showSuccess('Spare Parts Deleted Successfully ', '\u2705');
      }
    });
  }

  onCheckboxChange(index) {
    this.selectedIndex = index;
  }

  numberOnly(event): boolean {
    if (
      !(
        (event.keyCode > 95 && event.keyCode < 106) ||
        (event.keyCode > 47 && event.keyCode < 58) ||
        event.keyCode == 8 ||
        event.keyCode == 46 ||
        event.keyCode !== 110
      )
    ) {
      return false;
    }
  }

  closeButton(): void {
    this.dialogRef.close();
  }

  delete(): void {
    this.dialogRef.close('done');
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  onSubmit() {
    this.shareDataService.stepperFormValueShare.subscribe((res) => {});
    const jobOrdersSparePart = [];
    let jobOrdersSparepart = {};
    for (let i = 0; i < this.dataSource.length; i++) {
      if (this.dataSource[i].inUse !== true) {
        if (i == this.selectedIndex) {
          jobOrdersSparepart = {
            sparePartId: this.dataSource[i].id ? this.dataSource[i].id : 0,
            jobOrderId: this.jobOrder.jobOrderId
              ? this.jobOrder.jobOrderId
              : null,
            partCodeId: this.dataSource[i].codeId
              ? this.dataSource[i].codeId
              : this.dataSource[i].sparePartType.id || null,
            partQuantity: this.dataSource[i].quantity
              ? this.dataSource[i].quantity
              : this.dataSource[i].partQuantity || null,
            isMainPart: true,
          };
        } else {
          jobOrdersSparepart = {
            sparePartId: this.dataSource[i].id ? this.dataSource[i].id : 0,
            jobOrderId: this.jobOrder.jobOrderId
              ? this.jobOrder.jobOrderId
              : null,
            partCodeId: this.dataSource[i].codeId
              ? this.dataSource[i].codeId
              : this.dataSource[i].sparePartType.id || null,
            partQuantity: this.dataSource[i].quantity
              ? this.dataSource[i].quantity
              : this.dataSource[i].partQuantity || null,
            isMainPart: false,
          };
        }
        jobOrdersSparePart.push(jobOrdersSparepart);
      }
    }
    if (this.selectedIndex != undefined) {
      this.shareStepperData.stepValue({
        jobOrder: this.jobOrder,
        jobOrdersVehicleDetail: this.jobOrdersVehicleDetail,
        jobOrdersOperation: this.jobOrdersOperation,
        jobOrdersSparePart: jobOrdersSparePart,
      });
    }
  }

  setFormValid(): void {
    if (this.dataSource.length >= 0) {
      this.isJobSparePartsAvailable.emit(false);
    } else {
      this.isJobSparePartsAvailable.emit(true);
      this.jobSparePartsInformationForm = new FormGroup({
        jobCodeInfo: new FormControl(''),
        jobPartsName: new FormControl(''),
        jobPartsQuantity: new FormControl(''),
        jobSparePartsType: new FormControl(''),
      });
    }
  }

  isRowDisabled(row: jobOrdersparePartsInfo): boolean {
    return this.disabledRow === row;
  }

  checkDisabledState(): void {
    this.allDisabled = this.dataSource.every((row) => this.isRowDisabled(row));
  }

  showSuccess(message: string, content) {
    this.snackBar.open(content, message, {
      duration: 1500,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: ['custom-snackbar'],
    });
  }

  backToListOrder() {
    this.shareStepperData.setActiveTab('jobOrderListTab');
    this.router.navigate(['/pages/warranty/warranty-job-order']);
  }
}
