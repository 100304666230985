import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeleteIconService {
  render() {
    return `<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.70703 2.01562H3.58203C3.65078 2.01562 3.70703 1.95938 3.70703 1.89062V2.01562H8.45703V1.89062C8.45703 1.95938 8.51328 2.01562 8.58203 2.01562H8.45703V3.14062H9.58203V1.89062C9.58203 1.33906 9.13359 0.890625 8.58203 0.890625H3.58203C3.03047 0.890625 2.58203 1.33906 2.58203 1.89062V3.14062H3.70703V2.01562ZM11.582 3.14062H0.582031C0.305469 3.14062 0.0820312 3.36406 0.0820312 3.64062V4.14062C0.0820312 4.20937 0.138281 4.26562 0.207031 4.26562H1.15078L1.53672 12.4375C1.56172 12.9703 2.00234 13.3906 2.53516 13.3906H9.62891C10.1633 13.3906 10.6023 12.9719 10.6273 12.4375L11.0133 4.26562H11.957C12.0258 4.26562 12.082 4.20937 12.082 4.14062V3.64062C12.082 3.36406 11.8586 3.14062 11.582 3.14062ZM9.50859 12.2656H2.65547L2.27734 4.26562H9.88672L9.50859 12.2656Z" fill="#777777"/>
</svg>`;
  }
}
