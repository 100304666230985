import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CompletedIconService {
   render() {
    return `<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.3108 3.11035H13.2186C13.0655 3.11035 12.9202 3.18066 12.8264 3.30098L6.38424 11.4619L3.29517 7.54785C3.24844 7.48852 3.18888 7.44055 3.12096 7.40753C3.05303 7.37452 2.97851 7.35732 2.90299 7.35723H1.8108C1.70611 7.35723 1.6483 7.47754 1.71236 7.55879L5.99205 12.9807C6.19205 13.2338 6.57642 13.2338 6.77799 12.9807L14.4092 3.31035C14.4733 3.23066 14.4155 3.11035 14.3108 3.11035Z" fill="url(#paint0_linear_493_39770)"/>
<defs>c<linearGradient id="paint0_linear_493_39770" x1="1.68555" y1="8.14043" x2="14.4361" y2="8.14043" gradientUnits="userSpaceOnUse">
<stop stop-color="#DE2C40"/><stop offset="0.479167" stop-color="#DE142A"/>
<stop offset="1" stop-color="#AB1021"/></linearGradient></defs>
</svg>`;
  }
}