import {
  Component,
  Input,
  Output,
  OnInit,
  Renderer2,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { NbSidebarService, NbStepperComponent } from "@nebular/theme";
import { UserQuery } from "../../../@core/akita-stores/stores/user/user.query";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "ngx-manage-claim-layout",
  styleUrls: ["./manage-claim.layout.scss"],
  template: `
    <nb-card>
      <nb-card-body>
      <span class="headingTitle" >
        <nb-icon (click)="backToDashboard()" style="cursor: pointer;"
         class="mr-1" icon="arrow-left" pack="mo-icons"></nb-icon>
          {{ isClaimEditable==true ? 'Create' : 'Edit' }}  Claim
          <hr />  
        </span>

        <nb-stepper
          orientation="horizontal"
          [linear]="true"
          #stepper
        >
          <nb-step
            [stepControl]="isClaimInfoAvailable"
            label="Claim Information"
          >
            <ng-template #labelTwo>Second step</ng-template>
            <claim-info #stepTwo (isClaimInfoAvailable)= setisClaimInfoAvailable($event)></claim-info>
          </nb-step>

          <nb-step
            [stepControl]="isOperationCodeInfoAvailable"
            label="Operation Code Information"
          >
            <ng-template #labelTwo>Second step</ng-template>
            <operation-code-info #stepThree (isOperationCodeInfoAvailable)="setIsOperationCodeInfoAvailable($event)"> </operation-code-info>
          </nb-step>

          <nb-step
            [stepControl]="isSparePartAvailable"
            label="Spare Parts Information"
          >
            <ng-template #labelFour>Fourth step</ng-template>
            <spareparts-code #stepFour (isSparePartsAvailable)="setIsSparePartsAvailable($event)"> </spareparts-code>
          </nb-step>

          <nb-step
            [stepControl]="isSundryInfoAvailable"
            label="Sundry Information"
          >
            <ng-template #labelFive>Fifth step</ng-template>
            <sundry-info-claim #stepFive  (isSundryAvailable)="setIsSundryAvailable($event)"></sundry-info-claim>
          </nb-step>

          <nb-step [stepControl]="isAttachmentAvailable" label="Attachment">
            <ng-template #labelSix>Sixth step</ng-template>
            <warranty-attchment #stepSix  (isAttachmentAvailable)="setIsAttachmentAvailable($event)"></warranty-attchment>
          </nb-step>

      
        </nb-stepper>
      </nb-card-body>
    </nb-card>
  `,
})
export class ManageClaimLayoutComponent implements OnInit, OnDestroy {
 @ViewChild('stepper', { static: false }) stepper: NbStepperComponent;
  isLogin$ = this.userQuery.isLogin$;
  public isSparePartAvailable: boolean = true;
  public isOperationCodeInfoAvailable: boolean = true;
  public isSundryInfoAvailable: boolean = true;
  stepIndexValue : any;
  public isClaimInfoAvailable : boolean = true;
  public isAttachmentAvailable : boolean = true;
  public isClaimEditable :boolean = true;

  constructor(
    private sidebarService: NbSidebarService,
    private renderer: Renderer2,
    private userQuery: UserQuery,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activateRoute:ActivatedRoute,
  ) {}

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
        this.isClaimEditable=params['isClaimEditable']? params['isClaimEditable']: true;
        this.cdr.detectChanges();
    });

    let activeStep;
       if (localStorage.getItem("ClaimFormCurrentStep")) {
        activeStep = parseInt(localStorage.getItem("ClaimFormCurrentStep"));
    }
  }

  ngOnDestroy() {}

  backToDashboard(){
    if((this.isClaimInfoAvailable == false || this.isClaimInfoAvailable == true) && parseInt(localStorage.getItem("ClaimFormCurrentStep")) == 0){
       let navigationPath 
       if(this.isClaimEditable == true){
        navigationPath = '/pages/warranty/warranty-claim'
       }else{
        navigationPath = '/pages/warranty'
       }
      this.router.navigate([navigationPath])
    }

    if(parseInt(localStorage.getItem("ClaimFormCurrentStep")) > 0){
         this.stepper.previous();
          this.stepIndexValue =this.stepper.selectedIndex;
          localStorage.setItem("ClaimFormCurrentStep", this.stepIndexValue);
        }
  }

  finish() {
    this.router.navigate(["/"]);
  }

  toggle() {
    this.sidebarService.toggle(true, "sidebar-bookings");
  }

  setisClaimInfoAvailable(value){
    this.isClaimInfoAvailable = value;
     this.cdr.detectChanges();
     if(this.isClaimInfoAvailable == false){
      this.stepper.next();
      this.saveCurrentStep();
     }
  }

  setIsOperationCodeInfoAvailable(value){
    this.isOperationCodeInfoAvailable = value;
    this.cdr.detectChanges();
    if(this.isOperationCodeInfoAvailable == false){
      this.stepper.next();
    this.saveCurrentStep();
    }
  }

  setIsSparePartsAvailable(value): void {
    this.isSparePartAvailable = value;
    this.cdr.detectChanges();
    if(this.isSparePartAvailable == false){
      this.stepper.next()
      this.saveCurrentStep();
    }
  }

  setIsSundryAvailable(value){
    this.isSundryInfoAvailable = value;
    this.cdr.detectChanges();
    if(this.isSundryInfoAvailable == false){
      this.stepper.next()
      this.saveCurrentStep();
    }
  }

  setIsAttachmentAvailable(value){
    this.isAttachmentAvailable = value;
    this.cdr.detectChanges();
    if(this.isAttachmentAvailable == false){
     this.stepper.next();
    this.saveCurrentStep();
    }
  }

   saveCurrentStep() {
     this.stepIndexValue =this.stepper.selectedIndex
    localStorage.setItem("ClaimFormCurrentStep", this.stepIndexValue);
  }
}
