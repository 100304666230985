import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StepperDataService {
  public dataChange = new Subject<any>();
   stepperFormValueShare = this.dataChange.asObservable();
   claimStepperFormValueShare=this.dataChange.asObservable();
  private activeTabSubject = new BehaviorSubject<string>('bookingListTab');
  activeTab$ = this.activeTabSubject.asObservable();

  constructor() {}
  stepValue(stepperFormValueShare) {        
    this.dataChange.next(stepperFormValueShare);
  }

    saveStepValueObject(claimStepperFormValueShare) {        
    this.dataChange.next(claimStepperFormValueShare);
    }
  setActiveTab(tab: string) {
    this.activeTabSubject.next(tab);
  }
}