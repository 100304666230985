import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material';
import {
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import * as moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { Moment } from 'moment';


// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

/** @title Datepicker emulating a Year and month picker */
@Component({
  selector: 'custom-year-picker',
  templateUrl: 'custom-year-picker.component.html',
  styleUrls: ['custom-year-picker.component.scss'],
  providers: [

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CustomYearPickerComponent {
  @ViewChild('dp', { static: true }) dp: MatDatepicker<Moment>;
  date = new FormControl(moment());

  @Input() maxDate = new Date(new Date().getFullYear() + 50, 11, 31);
  @Input() minDate = new Date(new Date().getFullYear() - 50, 0, 1);

  @Output() yearChange = new EventEmitter<number>();

  chosenYearHandler(normalizedYear: Moment, dp: any) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    this.yearChange.emit(ctrlValue.year());
    dp.close();
  }

  constructor() {
  }

}
